
import { FC } from 'react'

import { useRecoilState } from 'recoil'
import { filtersStateAtom } from '@state/filters'

import Grid from '@mui/material/Grid'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'

import { NumericFormat } from 'react-number-format';

const isMobileDevice = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)

const options = [
  { value: 1990, name: "1990" },
  { value: 1995, name: "1995" },
  { value: 2000, name: "2000" },
  { value: 2001, name: "2001" },
  { value: 2002, name: "2002" },
  { value: 2003, name: "2003" },
  { value: 2004, name: "2004" },
  { value: 2005, name: "2005" },
  { value: 2006, name: "2006" },
  { value: 2007, name: "2007" },
  { value: 2008, name: "2008" },
  { value: 2009, name: "2009" },
  { value: 2010, name: "2010" },
  { value: 2011, name: "2011" },
  { value: 2012, name: "2012" },
  { value: 2013, name: "2013" },
  { value: 2014, name: "2014" },
  { value: 2015, name: "2015" },
  { value: 2016, name: "2016" },
  { value: 2017, name: "2017" },
  { value: 2018, name: "2018" },
  { value: 2019, name: "2019" },
  { value: 2020, name: "2020" },
  { value: 2021, name: "2021" },
  { value: 2022, name: "2022" },
  { value: 2023, name: "2023" },
  { value: 2024, name: "2024" },
];

const Year: FC = () => {

  const [filters, setFilters] = useRecoilState<any>(filtersStateAtom)

  const renderOption = (props:any, option:any) => (
    <li {...props} >
      <NumericFormat allowLeadingZeros thousandSeparator=""  decimalScale={0} displayType="text" value={option.name} suffix={' год'} />
    </li>
  )

  const optionsReverse = [...options].reverse()

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item xs={6}>
        <Autocomplete
          value={filters.year.min}
          onChange={(_, newValue) => {
            window?.navigator?.vibrate && window?.navigator?.vibrate(10);
            setFilters((prevState:any) => ({ ...prevState, year: { min: newValue, max: prevState.year.max } }))
          }}
          options={options}
          renderOption={renderOption}
          getOptionLabel={(option) => option.name.toString()}
          noOptionsText={'Нет донных'}
          isOptionEqualToValue={(option, value) => option.name === value.name}
          renderInput={(params) => (
            <TextField
              onClick={()=>{window?.navigator?.vibrate && window?.navigator?.vibrate(10);}}
              sx={{
                justifyContent: `center`,
                alignItems: `center`,
              }}
              {...params}
              label="Год от"
              /* placeholder="Год от" */
              variant="outlined"
              fullWidth
              inputProps={{
                ...params.inputProps,
                readOnly: true,
                inputMode: isMobileDevice() ? 'numeric' : 'text', // Установка числового режима для клавиатуры на мобильных устройствах
              }}
              InputLabelProps={{sx: {  marginTop: -0.25 } }}

            /> 
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          value={filters.year.max}
          onChange={(_, newValue) => {
            window?.navigator?.vibrate && window?.navigator?.vibrate(10);
            setFilters((prevState:any) => ({ ...prevState, year: { min: prevState.year.min, max:  newValue} }))
          }}
          options={optionsReverse}
          renderOption={renderOption}
          getOptionLabel={(option) => option.name.toString()}
          noOptionsText={'Нет донных'}
          isOptionEqualToValue={(option, value) => option.name === value.name}
          renderInput={(params) => (
            <TextField
              onClick={()=>{window?.navigator?.vibrate && window?.navigator?.vibrate(10);}}
              sx={{
                justifyContent: `center`,
                alignItems: `center`,
              }}
              {...params}
              label="Год до"
              /* placeholder="Год до" */
              variant="outlined"
              fullWidth
              inputProps={{
                ...params.inputProps,
                readOnly: true,
                inputMode: isMobileDevice() ? 'numeric' : 'text', // Установка числового режима для клавиатуры на мобильных устройствах
              }}
              InputLabelProps={{sx: {  marginTop: -0.25 } }}

            />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default Year;

import { FC } from 'react'

import { useRecoilState } from 'recoil'
import { calculatorStateAtom } from '@state/calculator'

import useMobileDetect from '@hooks/useMobileDetect'
import useBotDetect from '@hooks/useBotDetect'
import usePlatformDetect from '@hooks/usePlatformDetect'

import DesktopOrMobileOrBot from '@components/DesktopOrMobileOrBot'

import Calculator from './Calculators'

const Calculators: FC = () => {

  const [open, setOpen] = useRecoilState<any>(calculatorStateAtom)

  const mobile = useMobileDetect()
  const bot = useBotDetect()
  const platform = usePlatformDetect()

  if(!platform) return <></>;
  
  
  return (
    <>
      {( (platform === 'bot' && bot) && <Calculator/> )}
      {( (platform === 'mobile' && mobile || platform === 'desktop') && <DesktopOrMobileOrBot open={open || false} onClose={()=>{setOpen(false)}}> <Calculator/> </DesktopOrMobileOrBot> )}
    </>
  )
}

export default Calculators


import { atom, selector } from "recoil"

export const calculatorStateAtom = atom({
  key: "calculatorStateAtom",
  default: false,
})

export const calculatorStateSelector = selector({
  key: "calculatorStateSelector",
  get: ({ get }) => {
    const calculator = get(calculatorStateAtom)
    return calculator
  }
})


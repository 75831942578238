import { FC } from 'react'

import Box from '@mui/material/Box'

import { useRecoilValue } from 'recoil'
import { skinStateAtom } from '@state/skin'

import Header from '@components/Header'
import MainLots from '@components/MainLots'
import Menu from '@components/Menu'

import Intro from '@components/Intro'
import About from '@components/About'
import Products from '@components/Products'
import Advantages from '@components/Advantages'
import Faq from '@components/Faq'
import Reviews from '@components/Reviews'
import SchemeOfWork from '@components/SchemeOfWork'
import Calculators from '@components/Calculators'
import Offices from '@components/Offices'
import ModalProducts from '@components/ModalProducts'
import ChatBot from '@components/ChatBot'


import PopupCallBackCalculatorPrice from '@components/PopupCallBackCalculatorPrice'

import VerticalFixedButtons from '@components/Buttons/VerticalFixedButtons'

const Desktop: FC<any> = () => {

  const skin = useRecoilValue(skinStateAtom)
  
  return (
      <>

      <Box>

        { skin === 'default' &&

        <>

          <Header/>

          <Menu/>

          <Intro/>
{/* 
          <MainLots/>
 */}
          <Advantages/>

          <About/>

          <SchemeOfWork/>

          <Faq/>

          <Reviews/>

          <Offices/>

          <ModalProducts/>

        </>

        }

        {( (skin === 'catalog' || skin === 'catalogFixFilters') &&

          <Products/>

        )}

        <Calculators/>

        <PopupCallBackCalculatorPrice/>
        
        <VerticalFixedButtons/>

        <ChatBot/>

      </Box>

    </>
  )
}

export default Desktop

import { FC, useEffect, useState } from 'react'

import axios from 'axios'
import qs from 'qs'

import isEqual from 'lodash/isEqual'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'

import CircularProgress from '@mui/material/CircularProgress'

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { pageStateAtom } from '@state/page'
import { initDataStateAtom } from '@state/initData'
import { companyStateAtom } from '@state/company'
import { countriesStateAtom } from '@state/countries'
import { filtersStateAtom } from '@state/filters'
import { aiStateAtom } from '@state/ai'
import { brandsStateAtom } from '@state/brands'
import { productStateAtom } from '@state/product'

import api from '@services/api'

type ParseResult = {
  [key: string]: number;
} | null;

const parseString = (str: string, key: string): ParseResult => {
  const match = str.match(new RegExp(`${key}(\\d+)`));

  if (match) {
    const value = parseInt(match[1], 10);

    return { [key]: value };
  } else {
    return null;
  }
};

const mergeCarDataWithNoDuplicates = (

  existingData: any[],
  newData: any[]
): any[] => {

  const uniqueUrls = new Set(existingData.map((item:any) => item.id));

  const filteredNewData = newData.filter((item:any) => !uniqueUrls.has(item.id));

  const mergedData = [...existingData, ...filteredNewData];

  return mergedData;
}

const Start: FC<any> = () => {

  const setInitData = useSetRecoilState<boolean>(initDataStateAtom)

  const setProduct = useSetRecoilState<any>(productStateAtom)

  const [page, setPage] = useRecoilState<any>(pageStateAtom)
  const [company, setCompany] = useRecoilState<any>(companyStateAtom)

  const [loading, setLoading] = useState<boolean>(false)
  const [failedConnection, setFailedConnection] = useState<boolean>(false)

  
  const ai = useRecoilValue<any>(aiStateAtom)
  
  const setBrands = useSetRecoilState<any>(brandsStateAtom)

  const [countries, setCountries] = useRecoilState<any>(countriesStateAtom)

  const setFilters = useSetRecoilState<any>(filtersStateAtom)

  const start =  () => {

    setLoading(true)

    const query = qs.stringify({
      randomSort: false,
      domain: window.location.hostname,
    }, {
      encodeValuesOnly: true,
    })

    api({ collection: 'start', query }).then(async (data) => {

        setBrands([])

        setLoading(false)

        console.log(`start data`)
        console.log(data)

        if(data.length > 0){

          const company = data[0]

          setCompany(company)

          const fetchBrandsData = async ({page=0}) => {

            const query = qs.stringify({  
              ai,
              random: false,
              randomSort: false,
              filters: {
                [`active`]: {
                  $eq: true,
                }
              },
              populate: {
                image: `*`
              },
              pagination: {
                pageSize: 250,
                page: page,
              },
              sort: "id:ASC",
              locale: ['ru'],
            }, {
              encodeValuesOnly: true,
            })
     
            api({ collection: 'brands', query }).then(async (data) => {

              if(data?.data){
  
                setBrands((prevData: any) => mergeCarDataWithNoDuplicates(prevData, data?.data));
              }

              if(data?.meta?.pagination?.page < data?.meta?.pagination?.pageCount){
  
                await fetchBrandsData({page: page + 1})
              }
        
            }).catch((error:any) => {
              setFailedConnection(true);

              console.log(error)
            })

            return;
          };
  
          await fetchBrandsData({page:0})

          if(!isEqual(countries, company?.countries) ){
            
            setCountries(company?.countries)
            setFilters((prevState:any) => ({
              ...prevState,
              countries: company.countries,
            }))
          }

          if(!company.need_paid){

            setInitData(true)

            /* start auto open lot */

            try {

              const currentUrl = window.location.href;

              const urlParts = currentUrl.split('?');

              if (urlParts.length > 1) {

                const queryParams = urlParts[1].split('&');

                const paramsObj: { [key: string]: string } = {};

                queryParams.forEach((param) => {
                  const keyValue = param.split('=');
                  const key = decodeURIComponent(keyValue[0]);
                  const value = decodeURIComponent(keyValue[1]);
                  paramsObj[key] = value;
                })
                
                if(paramsObj && paramsObj.tgWebAppStartParam ){

                  const result = parseString(paramsObj.tgWebAppStartParam.split('#').toString(), 'L')
                                    
                  if (result) {

                    const { L } = result;

                    if(L){

                      const product = L

                      const query = qs.stringify({
                        ai,
                        randomSort: false,
                        populate: {
                          photo: `*`,
                          country:  `*`,
                          city:  `*`,
                          currency:  `*`,
                          transmission:  `*`,
                          drive:  `*`,
                          fuel:  `*`,
                          body:  `*`,
                          color:  `*`,
                          photos:  `photos`,
                        },
                        locale: ['ru'],
                      }, {
                        encodeValuesOnly: true,
                      })

                      api({ collection: 'lots', id: product, query }).then((data) => {

                        setProduct(data?.data);
                  
                      }).catch((error:any) => {
                        setFailedConnection(true);

                        console.log(error)
                      })

                    }
                  }
                }
              }
            } catch (error) {}

            if(page==='start') setPage('brands');
          }
        }
    }).catch((error:any) => {

      console.log(error)
    })
  }

  useEffect(() => {

    start()
  }, [])

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center" 
      height="calc(100vh - 0px)"
    >

      {  loading &&
      <>
        <CircularProgress color="inherit" />

        <Typography variant="subtitle2" gutterBottom  align="center" sx={{ mt: 2.5 , ml: 5, mr: 5,   color: 'text.primary'}}>

          Загружаем данные...

        </Typography>
      </>
      }

      {  !loading && failedConnection && Object.keys(company).length === 0 &&
      <>
        <Typography variant="subtitle2" gutterBottom align="center" sx={{ mt: 1,   color: 'text.primary'}}>

          <Box>Сообщите о проблеме разработчику </Box>

          <Link onClick={()=>{window?.Telegram?.WebApp?.openLink("https://genikineg.ru")}} sx={{ width: `100%`, textAlign: `center`, color: 'text.primary'}}>Genikineg.ru / +79502913351</Link>

        </Typography>
      </>
      }

      {  !loading && !failedConnection && Object.keys(company).length > 0 && company.need_paid &&
      <>
        <Typography variant="subtitle2" gutterBottom align="center" sx={{ mt: 1,  color: 'text.primary' }}>

          Отключен за не оплату!

        </Typography>

        <Link onClick={()=>{window?.Telegram?.WebApp?.openLink("https://genikineg.ru")}} sx={{ width: `100%`, textAlign: `center`,   color: 'text.primary'}}>Genikineg.ru / +79502913351</Link>
      </>
      }


    </Box>
  )
}

export default Start

import { atom, selector } from "recoil"
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

export const brandsSearchStateAtom = atom({
  key: "brandsSearchStateAtom",
  default: '',
  effects_UNSTABLE: [persistAtom],
})

export const brandsSearchStateSelector = selector({
  key: "brandsSearchStateSelector",
  get: ({ get }) => {
    const brandsSearch = get(brandsSearchStateAtom)
    return brandsSearch
  }
})


import { atom, selector } from "recoil"
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

type filterStateItemI = {
  key: string | number | null
  name: string | number |  null
}

interface minMax {
  min: filterStateItemI | null
  max: filterStateItemI | null
}

type filterStateI = {
  open: boolean
  advanced: boolean
  mileage: minMax | null[]
  drive: string | null
  year: object | null[]
  brand: object | null
  model: object | null
  body: filterStateItemI | null
  color: any[]
  countries: any[]
  engine_capacity:  minMax | null[]
  fuel: filterStateItemI | null
  transmission: filterStateItemI | null
  price: minMax | null[]
}

export const filtersStateAtom = atom<filterStateI>({
  key: "filtersStateAtom",
  default: {
    open: false,
    advanced: false,
    drive: null,
    body: null,
    brand: null,
    model: null,
    color: [],
    countries: [],
    year: {
      min: null,
      max: null,
    },
    mileage: {
      min: null,
      max: null,
    },
    engine_capacity: {
      min: null,
      max: null,
    },
    fuel: null,
    transmission: null,
    price: {
      min: null,
      max: null,
    },
  },
  effects_UNSTABLE: [persistAtom],
})

export const filtersStateSelector = selector({
  key: "filtersStateSelector",
  get: ({ get }) => {
    const filters = get(filtersStateAtom)
    return filters
  }
})

export type { filterStateI, filterStateItemI }

import { FC } from 'react'

import { useRecoilState, useRecoilValue, useSetRecoilState, useResetRecoilState } from 'recoil'
import { companyStateAtom } from '@state/company'
import { countriesStateAtom } from '@state/countries'
import { filtersStateAtom } from '@state/filters'

import { aiStateAtom } from '@state/ai'
import { effectsStateAtom } from '@state/effects'

import { brandsStateAtom } from '@state/brands'
import { modelsStateAtom } from '@state/models'
import { pageStateAtom } from '@state/page'

import { productsStateAtom } from '@state/products'
import { productStateAtom } from '@state/product'

import { brandsScrollStateAtom } from '@state/brandsScroll'
import { brandsSearchStateAtom } from '@state/brandsSearch'

import { modelsScrollStateAtom } from '@state/modelsScroll'
import { modelsSearchStateAtom } from '@state/modelsSearch'

import { trainingStateAtom } from '@state/training'

import Box from '@mui/material/Box'
import Container from '@mui/material/Container'

import MenuList from '@mui/material/MenuList'
import MenuItem from '@mui/material/MenuItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import Typography from '@mui/material/Typography'

import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Switch, switchClasses } from '@mui/base/Switch';

import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import SortOutlinedIcon from '@mui/icons-material/SortOutlined';

import { styled } from '@mui/material/styles'

import DarkMode from '@components/DarkMode'

import useMobileDetect from '@hooks/useMobileDetect'

const Settings: FC<any> = () => {

  const label = { slotProps: { input: { 'aria-label': 'switch' } } };

  const company = useRecoilValue<any>(companyStateAtom)

  const [ai, setAi] = useRecoilState<boolean>(aiStateAtom)
  const [effects, setEffects] = useRecoilState<boolean|null>(effectsStateAtom)
  
  const mobile = useMobileDetect()

  const setCountries = useSetRecoilState<any>(countriesStateAtom)
  
  const resetFilters = useResetRecoilState(filtersStateAtom)
  
  const setFilters = useSetRecoilState<any>(filtersStateAtom)

  const setBrands = useSetRecoilState<any>(brandsStateAtom)
  const setModels = useSetRecoilState<any>(modelsStateAtom)

  const setProducts = useSetRecoilState<any>(productsStateAtom)
  const setProduct = useSetRecoilState<any>(productStateAtom)

  const setBrandsScroll = useSetRecoilState<any>(brandsScrollStateAtom)
  const setBrandsSearch = useSetRecoilState<any>(brandsSearchStateAtom)
  
  const setModelsScroll = useSetRecoilState<any>(modelsScrollStateAtom)
  const setModelsSearch = useSetRecoilState<any>(modelsSearchStateAtom)

  const setPages = useSetRecoilState<any>(pageStateAtom)

  const training = useResetRecoilState(trainingStateAtom)
  
  return (
      <>

        <Container sx={{pt: 0}}>

          <Typography variant="h6" gutterBottom align="center"> Настройки </Typography>

          <MenuList>

            { mobile &&
                
                <Box sx={{ml: 2, mr: 2, mb: 2}}>

                <FormControl component="fieldset">
  
                  <FormGroup>
                    
                  <FormControlLabel
                    control={
                    <Switch
                      checked={ effects === null ? true : effects}
                      slots={{
                        root: Root,
                      }}
                      {...label}
                      onChange={(event: any)=>{
                        window?.navigator?.vibrate && window?.navigator?.vibrate(10);
                        setEffects(event.target.checked)
                      }}                          />
                    }
                    label="Effects (Эффекты интерфейса)"
                  />
  
                    <FormHelperText>
                      <Typography variant="caption" color="text.secondary">
  
                        Можете отключить для ускорения интерфейса на слабых мобильных устройствах
                        
                      </Typography>
                    </FormHelperText>
  
                  </FormGroup>
                </FormControl>
  
              </Box>
            }

            <Box sx={{ml: 2, mr: 2, mb: 2}}>

              <FormControl component="fieldset">

                {/* <FormLabel component="legend">Assign responsibility</FormLabel> */}
                <FormGroup>
                  
                <FormControlLabel
                  control={
                  <Switch
                    checked={ai}
                    slots={{
                      root: Root,
                    }}
                    {...label}
                    onChange={(event: any)=>{
                      window?.navigator?.vibrate && window?.navigator?.vibrate(10);
                      setAi(event.target.checked)
                    }}                          />
                  }
                  label="Ai (Искусственный интеллект)"
                />

                  <FormHelperText>
                    <Typography variant="caption" color="text.secondary">

                      Ai (Рекомендации и фильтрация, Автоматизированный поиск, Мониторинг рынка, Аналитика)
                      
                    </Typography>
                  </FormHelperText>

                </FormGroup>
              </FormControl>

            </Box>

            <Box sx={{ml: 2, mr: 2, mb: 2}}>

              <DarkMode/>

            </Box>

            <MenuItem onClick={()=>{
              window?.navigator?.vibrate && window?.navigator?.vibrate(10);
              resetFilters()
              setCountries(company?.countries)
              setFilters((prevState:any) => ({ ...prevState, countries: company?.countries }))
            }}>
              <ListItemIcon>
              <Typography variant="body2" color="text.secondary">

                <SortOutlinedIcon fontSize="medium" />
                </Typography>

              </ListItemIcon>
              <ListItemText>

                Сбросить фильтры

              </ListItemText>
              <Typography variant="body2" color="text.secondary">
                
              </Typography>
            </MenuItem>

            <MenuItem onClick={()=>{

              window?.navigator?.vibrate && window?.navigator?.vibrate(10);

              resetFilters()
              
              setPages('start')
              setBrands([])
              setModels([])
              
              setProducts([])
              setProduct(null)

              setBrandsScroll(0)
              setBrandsSearch('')

              setModelsScroll(0)
              setModelsSearch('')

              setCountries(company?.countries)
              setFilters((prevState:any) => ({ ...prevState, countries: company?.countries }))
              setAi(true)

              training()

              const clearAllCookies = () => {
                // Получаем все куки
                const cookies = document.cookie.split(';');
            
                // Проходим по каждому куки и удаляем его
                cookies.forEach(cookie => {
                    const cookieParts = cookie.split('=');
                    const cookieName = cookieParts[0].trim();
                    // Устанавливаем куки с истекшим сроком действия
                    document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
                });
              };
              
              // Вызываем функцию для удаления всех куки
              clearAllCookies();

              setTimeout(() => {
                window.location.reload()
              }, 250)
            }}>
              <ListItemIcon>
              <Typography variant="body2" color="text.secondary">
                <DeleteOutlineOutlinedIcon fontSize="medium" />
                </Typography>

              </ListItemIcon>
              <ListItemText>

                Сбросить всё

              </ListItemText>
              <Typography variant="body2" color="text.secondary">
                
              </Typography>
            </MenuItem>

          </MenuList>

        </Container>
      </>
  )
}

export default Settings


const blue = {
  200: '#99CCF3',
  500: '#007FFF',
  700: '#0059B2',
};

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};

const Root = styled('span')(
  ({ theme }) => `
  box-sizing: border-box;
  font-size: 0;
  position: relative;
  display: inline-block;
  width: 38px;
  height: 24px;
  margin: 10px;
  cursor: pointer;

  &.${switchClasses.disabled} {
    opacity: 0.4;
    cursor: not-allowed;
  }

  & .${switchClasses.track} {
    box-sizing: border-box;
    background: ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
    border-radius: 24px;
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 120ms;
    box-shadow: inset 0px 1px 1px ${
      theme.palette.mode === 'dark' ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0.05)'
    };
  }

  &:hover .${switchClasses.track} {
    background: ${theme.palette.mode === 'dark' ? grey[800] : grey[100]};
    border-color: ${theme.palette.mode === 'dark' ? grey[600] : grey[300]};
  }

  &.${switchClasses.focusVisible} .${switchClasses.track} {
    box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[700] : blue[200]};
  }

  & .${switchClasses.thumb} {
    box-sizing: border-box;
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
    display: block;
    width: 16px;
    height: 16px;
    top: 4px;
    left: 4px;
    border-radius: 16px;
    background-color: #FFF;
    position: relative;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 120ms;
    box-shadow: 0px 1px 2px ${
      theme.palette.mode === 'dark' ? 'rgba(0, 0, 0, 0.25)' : 'rgba(0, 0, 0, 0.1)'
    };
  }

  &.${switchClasses.checked} {
    .${switchClasses.thumb} {
      left: 18px;
      background-color: #fff;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
    }

    .${switchClasses.track} {
      border: none;
      background: ${blue[500]};
    }
  }

  &:hover .${switchClasses.checked} .${switchClasses.track} {
    background: ${blue[700]};
  }

  & .${switchClasses.input} {
    cursor: inherit;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 1;
    margin: 0;
  }
  `,
);

import { atom, selector } from "recoil"
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

export const countriesStateAtom = atom({
  key: "countriesStateAtom",
  default: [],
  effects_UNSTABLE: [persistAtom],
})

export const countriesStateSelector = selector({
  key: "countriesStateSelector",
  get: ({ get }) => {
    const countries = get(countriesStateAtom)
    return countries
  }
})


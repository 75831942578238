import { FC, useEffect, useState, PropsWithChildren } from 'react'

import { useQRCode } from 'next-qrcode'

import { useRecoilValue, useRecoilState } from 'recoil'

import { companyStateAtom } from '@state/company'
import { pageStateAtom } from '@state/page'
import { filtersStateAtom } from '@state/filters'
import { shareStateAtom } from '@state/share'

import Grid from '@mui/material/Unstable_Grid2'
import Fade from '@mui/material/Fade'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Snackbar from '@mui/material/Snackbar';
import Container from '@mui/material/Container'
import ShareIcon from '@mui/icons-material/Share';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import QrCode2Icon from '@mui/icons-material/QrCode2';

import Stack from '@mui/material/Stack'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

import Modal from '@mui/material/Modal'
import Button from '@mui/material/Button'

import SwipeableDrawer from '@components/SwipeableDrawer'
import DesktopOrMobileOrBot from '@components/DesktopOrMobileOrBot'

import useMobileDetect from '@hooks/useMobileDetect'

const Share: FC<any> = () => {

  const mobile = useMobileDetect()

  const { SVG } = useQRCode()

  const [type, setType] = useState('')

  const [shareLot, setShareLot] = useState<boolean>(false)

  const company = useRecoilValue<any>(companyStateAtom)
  const page = useRecoilValue<any>(pageStateAtom)
  const filters = useRecoilValue<any>(filtersStateAtom)

  const [share, setShare] = useRecoilState<any>(shareStateAtom)
  
  useEffect(()=>{

    window?.navigator?.vibrate && window?.navigator?.vibrate(10);

  }, [share])

   const getData = () => {

    let data = `https://t.me/${company?.telegram_bot}/app`

/*     if(!data) {

      if(page === `brands`){

        return `https://t.me/${company?.telegram_bot}/app`
      }

      if(page === `models`){

        const params = `B${filters?.brand?.id}`

        return `https://t.me/${company?.telegram_bot}/app?startapp=${params}`
      }

      if(page === `products`){

        const params = `B${filters?.brand?.id}M${filters?.model?.id}`

        return `https://t.me/${company?.telegram_bot}/app?startapp=${params}`
      }
    }
 */
    return data
  }

  const handleClickClipBoardWriteText = () => {
    

    //https://t.me/aicarseller_bot/app?startapp=L41817
    //window.navigator.clipboard.writeText(`https://t.me/${company?.telegram_bot}/app?startapp=L${product?.id}`)

    /* const params = encodeURIComponent(JSON.stringify({
      p: product?.id
    })) */
    const params = `NOT`
    window.navigator.clipboard.writeText(`https://t.me/${company?.telegram_bot}/app?startapp=${params}`)
      .then(() => {
      })
      .catch((error) => {});
  }

  
  const onClose = () => {
    setShare(!share)
    setType('')
  }

  const sx = {
    opacity: 0.9,
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    p: 0,
    pb: 0.5,
    height: 40,
  }

  const component = () => {
    
    return <>
              <Fade in={ (share && type === 'qr') || false} timeout={0} unmountOnExit>

                <Box
                display={'flex'}
                justifyContent={'center'}
                justifyItems={'center'}
                alignItems={'center'}>
                  
                  <SVG
                    text={getData()}
                    options={{
                      margin: 2,
                      width: 250,
                      color: {},
                    }}
                  />
                  
                </Box>

              </Fade>
                
              <Fade in={ (share && type === '') || false} timeout={0} unmountOnExit>

                <Box>

                  <Grid container spacing={2}>

                    <Grid xs={12}>
                      
                      <Button
                      fullWidth
                      sx={{...sx}}
                      onClick={()=>{
                        window?.navigator?.vibrate && window?.navigator?.vibrate(10);
                        setType('qr')
                      }}
                      >
                        
                        <Grid xs={12} container spacing={0}>

                          <Grid xs={4} display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>

                            <QrCode2Icon fontSize='medium'/>

                          </Grid>

                          <Grid xs={8} display={'flex'} alignItems={'center'} justifyContent={'flex-start'} sx={{pl: 2}}>

                            QR код

                          </Grid>
                        </Grid>

                      </Button>

                    </Grid>

                    <Grid xs={12}>
                      
                      <Button
                      fullWidth
                      sx={{...sx}}
                      onClick={()=>{
                        window?.navigator?.vibrate && window?.navigator?.vibrate(10);
                        handleClickClipBoardWriteText()
                        setShare(!share)
                        setShareLot(!shareLot)
                      }}
                      >
                        
                        <Grid xs={12} container spacing={0}>

                          <Grid xs={4} display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>

                            <InsertLinkIcon fontSize='medium'/>

                          </Grid>

                          <Grid xs={8} display={'flex'} alignItems={'center'} justifyContent={'flex-start'} sx={{pl: 2}}>

                            Ссылка

                          </Grid>
                        </Grid>

                      </Button>

                    </Grid>
                    
                    <Grid xs={12}>
                      
                      <Button
                      fullWidth
                      sx={{...sx}}
                      onClick={()=>{
                        window?.navigator?.vibrate && window?.navigator?.vibrate(10);
                        const href = `https://t.me/share/url?url=https://t.me/${company?.telegram_bot}/app`
                        window.open(href, '_blank');
                        setShare(!share)
                      }}
                      >
                        
                        <Grid xs={12} container spacing={0}>
                      
                          <Grid xs={4} display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>

                            <ShareIcon fontSize='medium'/>

                          </Grid>

                          <Grid xs={8} display={'flex'} alignItems={'center'} justifyContent={'flex-start'} sx={{pl: 2}}>

                            Поделиться

                          </Grid>

                        </Grid>

                      </Button>

                    </Grid>

                  </Grid>

                </Box>

              </Fade>

            </>
  }

  return (
    <>
      
      {/* start  button */}

      <Box
        display={'flex'}
        justifyContent={'center'}
        justifyItems={'center'}
        alignItems={'center'}
        sx={{
          
          position: `relative`,
          top: 5,
          left: 0,

          height: '100%',
          width: '100%',
          cursor: 'pointer',
          
        }}
        onClick={()=>{setShare(true)}}
      >

        <ShareIcon
        fontSize='medium' 
        sx={{
          userSelect: 'none',
        }}/>

      </Box>

      {/* end button */}


      <DesktopOrMobileOrBot open={share || false} onClose={onClose}>

        {component()}

      </DesktopOrMobileOrBot>

      
{/* 
      { !mobile ?

        <Modal
        disableAutoFocus
        open={share || false}
        onClose={onClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        >
                      
          <Box
          sx={{
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 340, 
            bgcolor: 'background.paper',
            border: 0,
            borderRadius: 2.5,
            boxShadow: 24,
            pt: 4,
            px: 4,
            pb: 4,
          }}>
            
            <IconButton
            aria-label="close"
            size="large"
            sx={{position: 'absolute', padding:0, top: 10, right: 10,  transform: 'scale(1.25)'}}
            onClick={()=>{
              setType('')
              setShare(false)
            }}>
              
              <CloseIcon/>
            </IconButton>

            <Box>

              {component()}
                  
            </Box>

          </Box>

        </Modal> 

        :

        <SwipeableDrawer
          anchor={'bottom'}
          open={share||false}
          onClose={onClose}
          onOpen={()=>{}}
          maxWidth
          sx={{
            justifyContent: 'center','display': 'flex', 'alignitems': 'center',
            '& .MuiPaper-root': {
              minHeight: 195,
              "&" : {
                backdropFilter: "blur(2px)",
                borderTopLeftRadius: 15,
                borderTopRightRadius: 15,
              },
            },
          }}
        >
          <Container maxWidth="sm">
            
            {component()}
            
          </Container>
        
        </SwipeableDrawer>
      } */}


      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={shareLot||false}
        autoHideDuration={2500}
        onClose={()=>{setShareLot(!shareLot)}}
        message="Ссылка скопирована"
        sx={{
          '& .MuiPaper-root': {
            borderRadius: 2,
            zIndex: 10000,
          },
          '& .MuiSnackbarContent-message ': {
            display: 'block',
            textAlign: "center",
            width: '100%',
          },
        }}
      />
    </>
  )
}

export default Share

import { atom, selector } from "recoil"
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

export const shareStateAtom = atom({
  key: "shareStateAtom",
  default: false,
  effects_UNSTABLE: [persistAtom],

})

export const shareStateSelector = selector({
  key: "shareStateSelector",
  get: ({ get }) => {
    const share = get(shareStateAtom)
    return share
  }
})


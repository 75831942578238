import { FC, useEffect, useState } from 'react'

import qs from 'qs'

import CircularProgress from '@mui/material/CircularProgress'

import _ from 'lodash'

import Grid from '@mui/material/Unstable_Grid2'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

import api from '@services/api'

const Options: FC<any> = ({open=false, id=null}) => {

  const [loading, setLoading] = useState<boolean>(false)
  const [data, setData] = useState<any>(null)

  useEffect(() => {

    if(!open) return;

    const query = qs.stringify({
      id,
      locale: ['ru'],
    }, {
      encodeValuesOnly: true, // prettify URL
    })

    api({ collection: 'lot-options', query }).then((data) => {


      if(data&&data?.data){

        console.log(`data`)
        console.log(data?.data)

        setData(data?.data)
      }

      setLoading(false)

    }).catch((error:any) => {
      setLoading(false)
    })

  }, [open])
  
  return (
    <>
    {
      open &&
      <>
        { loading ?

          <Box

            height="100%"
            width="100%"
          >

            <CircularProgress color="inherit" />

            <Typography variant="subtitle1" gutterBottom  align="center" sx={{ mt: 2.5 , ml: 5, mr: 5}}>

              Загружаем...

            </Typography>
            
          </Box>
          :
          <>

            {data?.map((item:any, index:number) => (

              <Box key={`options-${index}`} p={2} pb={1}  mt={1} height="100%" width="100%">

                <Grid container xs={12} sx={{justifyContent: 'center'}}>

                  <Typography variant="subtitle1" gutterBottom  align="center" sx={{ mt: 2.5 , ml: 5, mr: 5, width: '100%'}}>

                    {item.category}

                  </Typography>
                
                </Grid>

                <Grid container xs={12} sx={{justifyContent: 'center'}}>

                  <Typography variant="subtitle2" gutterBottom  align="center" sx={{ mt: 3.5 }}>

                    {item?.options?.map((option:any, index:number) => (
                      <div key={`option-${index}`}>

                      <Grid container xs={12}>

                        <Grid xs={6}>

                          <Typography variant="subtitle2" gutterBottom  align="center" sx={{ mt: 1 , ml: 0, mr: 1}}>

                          {option.name}

                          </Typography>

                        </Grid>

                        <Grid xs={6}>
                            
                          <Typography variant="subtitle2" gutterBottom  align="center" sx={{ mt: 1 , ml: 1, mr: 0}}>

                            {_.isBoolean(option.value) ? (option.value?`Да`:`-`) : option.value}

                          </Typography>

                        </Grid>

                      </Grid>

                    </div>

                  ))}

                </Typography>

              </Grid>

            </Box>

            ))}

          </>
        }
      </>
    }
    </> 
  )
}

export default Options
import { atom, selector } from "recoil"

export const initDataStateAtom = atom({
  key: "initDataStateAtom",
  default: false,

})

export const initDataStateSelector = selector({
  key: "initDataStateSelector",
  get: ({ get }) => {
    const initData = get(initDataStateAtom)
    return initData
  }
})


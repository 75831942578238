import { FC, useMemo } from 'react'

import { useRecoilValue } from 'recoil'
import { companyStateAtom } from '@state/company'

import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import parseDynamicComponent from '@utils/parseDynamicComponent'

import { useBotDetect } from '@hooks/useBotDetect'
import { useMobileDetect } from '@hooks/useMobileDetect'

const About: FC<any> = () => {

  const company = useRecoilValue<any>(companyStateAtom)

  const bot = useBotDetect()
  const mobile =   useMobileDetect()

  const about:any = useMemo(() => parseDynamicComponent(company?.about?.About),[company?.about?.About])

  return (
      <>

        <Container id={'about'} sx={{pt: 0,  mt: { xs: bot ? 0 : 5, md: 5 }, ...(!mobile && !bot && { pl: 0, pr: 0, })  }}>
          
          { about?.header?.title &&
            
            <Typography variant="h6" gutterBottom align="center"> { about?.header?.title } </Typography>
          }
          
          { about?.header?.caption &&
          
          <Typography variant="caption" gutterBottom display="block" align="center"> { about?.header?.caption } </Typography>
          }

          { about?.header?.body &&
            
            <Typography variant="body2" dangerouslySetInnerHTML={{__html: about?.header?.body?.text}}/>
          }

          <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
          }}
          >

            {about?.data?.map((item:any) => (

              <Box key={`about-${item?.id}-${item?.name}`} boxShadow={0} borderRadius={2} width={'100%'}>

                <Typography
                  variant="body1"
                  dangerouslySetInnerHTML={{__html: item?.text}}
                />
    
              </Box>

            ))}

          </Box>

          { about?.footer?.title &&
            
            <Typography variant="h6" gutterBottom align="center"> { about?.footer?.title } </Typography>
          }
          
          { about?.footer?.caption &&
          
            <Typography variant="caption" gutterBottom display="block" align="center"> { about?.footer?.caption } </Typography>
          }

          { about?.footer?.body &&
            
            <Typography variant="body2" dangerouslySetInnerHTML={{__html: about?.footer?.body?.text}}/>
          }
          
        </Container>

      </>
  )
}

export default About

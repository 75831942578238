import { atom, selector } from "recoil"
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

export const brandsStateAtom = atom({
  key: "brandsStateAtom",
  default: [],
  effects_UNSTABLE: [persistAtom],
})

export const brandsStateSelector = selector({
  key: "brandsStateSelector",
  get: ({ get }) => {
    const brands = get(brandsStateAtom)
    return brands
  }
})


import { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'

import { productStateAtom } from '@state/product'
import { bookmarksStateAtom } from '@state/bookmarks'

function useCheckingProductBookmarks() {

  const bookmarks = useRecoilValue<any>(bookmarksStateAtom)

  const product = useRecoilValue<any>(productStateAtom)

  const [ allow, setAllow ] = useState<boolean>(true)

  useEffect(() => {

    for (const bookmark of bookmarks) {
    
      if (
        ( product?.id === bookmark?.id ) 
      ) setAllow(false);
    }
    return () => {
      setAllow(true)
    }

  }, [product, bookmarks])

  return allow
}

export default useCheckingProductBookmarks

export { useCheckingProductBookmarks }

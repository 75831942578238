
import { FC } from 'react'

import Grow from '@mui/material/Grow'
import Box from '@mui/material/Box'

import Modal from '@mui/material/Modal'

import { useRecoilState } from 'recoil'
import { modalProductsStateAtom } from '@state/modalProducts'

import Products from '@components/Products'

const ModalProducts: FC<any> = () => {

  const [ open, setOpen ] = useRecoilState<boolean>(modalProductsStateAtom)
  
  return (

    <Modal
    disableAutoFocus

    open={open || false}

    onClose={()=>{}}
    aria-labelledby="child-modal-title"
    aria-describedby="child-modal-description"
    sx={{
      zIndex: 900,
    }}
    >
      <Grow in={open} unmountOnExit>

        <Box
        sx={{
          position: 'absolute' as 'absolute',
          top: 0,
          left: 0,
          width: '100vw', 
          height: '100vh',
          bgcolor: 'background.paper',
          border: 0,
          borderRadius: 2.5,
          boxShadow: 24,
          px: 0,
        }}>

          <Products/>

        </Box>
      </Grow>
    </Modal>
  )
}

export default ModalProducts

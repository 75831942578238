import { FC } from 'react'

import Fade from '@mui/material/Fade'
import Button from '@mui/material/Button'

import FilterListIcon from '@mui/icons-material/FilterList';

import { useRecoilState, useRecoilValue } from 'recoil'

import { filtersStateAtom } from '@state/filters'
import { filtersShowStateAtom } from '@state/filtersShow'

import { companyStateAtom } from '@state/company'

import useCountsFilters from '@hooks/useCountsFilters'

import useTraining from '@hooks/useTraining'

const ButtonFilters: FC<any> = () => {

  const { training } = useTraining()

  const company = useRecoilValue<any>(companyStateAtom)
  
  const countsFilters = useCountsFilters()

  const [filters, setFilters] = useRecoilState<any>(filtersStateAtom)
  const [filtersShow, setFiltersShow] = useRecoilState<any>(filtersShowStateAtom)

  return (
    <Fade in={!filtersShow || false} unmountOnExit>
      <Button
      variant="contained"
      sx={{
        height: '100%',
        width: 120,
        p: 1,
        fontSize: 10,
        
        //backdropFilter: "blur(2px)", // лагает на моб.
        backdropFilter: 'unset',
        boxShadow: 'unset',


        "& .MuiButton-startIcon": { marginRight: "2px" },
        
        bgcolor: 'primary.company',
        color: 'primary.main',

        "&:hover": {
          bgcolor: 'primary.company',
          opacity: 0.9,
        },
        "&:active": {
          bgcolor: 'primary.company',
          opacity: 0.9,
        },

        ...( training && training.active && training.step === 5 && {
          ...training.trainingAnimation
        })
      }}
      startIcon={<FilterListIcon />}
      onClick={()=>{

        window?.navigator?.vibrate && window?.navigator?.vibrate(10);

        if( training &&  training.active && training.step === 5 ) training.next();
        
        //setFilters({...filters, open: true})
        setFiltersShow(true);
      }}>

        Фильтры{/*  {countsFilters > 0 ? <>( {countsFilters} )</> : <></>} */}

      </Button>
    </Fade>
  )
}

export default ButtonFilters

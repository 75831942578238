
/* 
parseDynamicComponentUnionByCity
*/
const parseDynamicComponentUnionByCity = ( items: any ): object => {

  if(!items) return {
    header: null,
    data: [],
    footer: null,
  };

  let data:any = []
  let header:any = null
  let footer:any = null
  
  for(const item of items){ 
    
    if( item.__component === 'header.header' ) {
      header = item;
    }else
    if( item.__component === 'footer.footer' ) {
      footer = item;
    }else{

      const cityName = item?.city?.name || 'unknown';

      if (!data[cityName]) {
        data[cityName] = [item];
      } else {
        data[cityName].push(item);
      }
    }
  }

  return {
    header: header,
    data: data,
    footer: footer,
  }
}

export default parseDynamicComponentUnionByCity

export { parseDynamicComponentUnionByCity }

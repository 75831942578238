import { FC } from 'react'

import Fade from '@mui/material/Fade'
import Box from '@mui/material/Box'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

const ButtonBack: FC<any> = ({show=false, callback=()=>{}}) => {
  
  return (
    <Fade in={show || false} timeout={0} unmountOnExit>
         
      <Box
        display={'flex'}
        justifyContent={'center'}
        justifyItems={'center'}
        alignItems={'center'}
        sx={{
          
          position: `relative`,
          top: 5,
          left: 0,

          height: '100%',
          width: '100%',
          cursor: 'pointer',
        }}
        onClick={()=>{window?.navigator?.vibrate && window?.navigator?.vibrate(10); callback()}}
      >

        <ArrowBackIcon fontSize='medium' sx={{
          userSelect: 'none',
          transform: `scale(1.25)`,
        }}/>

      </Box>

      {/* <ArrowBackIcon fontSize='large' sx={{
        position: `relative`,
        top: 15,
        left: 0,
        cursor: 'pointer',
        userSelect: 'none',
      }}
      onClick={()=>{window?.navigator?.vibrate && window?.navigator?.vibrate(10); callback()}}/> */}
    </Fade>
  )
}

export default ButtonBack

import { FC } from 'react'

import Grid from '@mui/material/Unstable_Grid2'
import Button from '@mui/material/Button'

interface I {
  disabled?: boolean
  onClick: any
  icon: any
  name: any
  href?: any,
  children?: React.ReactNode
}

const ButtonAdditionalFeatures: FC<I> = ({disabled=false, icon=<></>, onClick=()=>{}, name='', href}) => {
  
  return (
    <>

      <Button
      disabled={disabled}
      fullWidth
      sx={{
        height: 'unset',
        opacity: 0.9,
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        ':hover':{
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
        p: 0,
        pt: 1,
        pb: 1,
        boxShadow: 'none',
      }}
      {...(href ? { href: href,  target:"_blank" } : {})}
      onClick={onClick}
      >
        
        <Grid container spacing={0}>
      
          <Grid xs={12} display={'flex'} justifyContent={'center'}>

            {icon}

          </Grid>

          <Grid xs={12} sx={{ fontSize: 10, mt: 1, whiteSpace: 'nowrap'  }} display={'flex'} justifyContent={'center'}>

            {name}

          </Grid>

        </Grid>

      </Button>

    </> 

  )
}

export default ButtonAdditionalFeatures
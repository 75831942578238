import { atom, selector } from "recoil"
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

export const brandsScrollStateAtom = atom({
  key: "brandsScrollStateAtom",
  default: 0,
  effects_UNSTABLE: [persistAtom],
})

export const brandsScrollStateSelector = selector({
  key: "brandsScrollStateSelector",
  get: ({ get }) => {
    const brandsScroll = get(brandsScrollStateAtom)
    return brandsScroll
  }
})


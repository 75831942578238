import { FC, useMemo } from 'react'

import { useRecoilValue } from 'recoil'
import { companyStateAtom } from '@state/company'

import Container from '@mui/material/Container'
import Grid from '@mui/material/Unstable_Grid2'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import parseDynamicComponent from '@utils/parseDynamicComponent'
import { useBotDetect } from '@hooks/useBotDetect'
import { useMobileDetect } from '@hooks/useMobileDetect'

const SchemeOfWork: FC<any> = () => {

  const company = useRecoilValue<any>(companyStateAtom)

  const bot = useBotDetect()
  const mobile =   useMobileDetect()

  const scheme_of_work:any = useMemo(() => parseDynamicComponent(company?.scheme_of_work?.scheme_of_work),[company?.scheme_of_work])
  
  const margin = 15

  return (
      <>

        <Container id={'scheme_of_work'} sx={{pt: 0,  mt: { xs: bot ? 0 : 5, md: 5 }, pl: 0, pr: 0, }}>

          { scheme_of_work?.header?.title &&

            <Typography variant="h6" gutterBottom align="center"> { scheme_of_work?.header?.title } </Typography>
          }

          { scheme_of_work?.header?.caption &&

            <Typography variant="caption" gutterBottom display="block" align="center"> { scheme_of_work?.header?.caption } </Typography>
          }

          { scheme_of_work?.header?.body &&
            
            <Typography variant="body2" dangerouslySetInnerHTML={{__html: scheme_of_work?.header?.body?.text}}/>
          }

          <Container sx={{ overflowY: 'hidden', overflowX: 'auto', pt: 0,
            ...(mobile && {
              pl: 0, pr: 0,
            }),
          }}>

            <Box
            sx={{
              display: 'flex',
              flexWrap: bot  ? 'wrap' : mobile ? 'nowrap' : 'wrap',
              overflowX: 'auto', scrollPadding: '0 0 0 30px', scrollSnapType: 'x mandatory', '& > *': { scrollSnapAlign: 'start', }, 
              '& > *:first-of-type': {
                ...((mobile && !bot) && {

                  marginLeft: `${margin}px !important`,
                }),
              },
            }}
            >

              {scheme_of_work?.data?.map((item:any) => (

                <Grid key={`scheme_of_work-${item.id}-${item?.name}`} container spacing={1} margin={0} marginTop={ bot ? 0 : mobile ? 0 : 3 }
                xs={12}//, extra-small: 0px
                sm={6}//, small: 600px
                md={3}//, medium: 900px
                lg={3}//, large: 1200px
                xl={3}//, extra-large: 1536px

                minWidth={250}
                >

                  <Box boxShadow={2} borderRadius={2} margin={1} padding={1}>

                    <Grid container xs={12}>

                      <Grid xs={2} display="flex" direction="row" justifyContent="center" alignItems="center">
                        <Typography
                          variant="h5"
                          dangerouslySetInnerHTML={{
                          __html: item?.step}}
                          sx={{p: 1}}
                        />
                      </Grid>
                      <Grid xs={10} display="flex" direction="row" justifyContent="flex-start" alignItems="center">
                        <Typography
                          variant="subtitle2"
                          dangerouslySetInnerHTML={{
                          __html: item?.name}}
                          justifyContent="center"
                        />
                      </Grid>
                    </Grid>

                    <Grid xs={12}>
                      <Typography
                        variant="body2"
                        sx={{
                          pl: 1,
                          '& p':{
                            padding: 0,
                            margin: 0,
                          }}}
                        dangerouslySetInnerHTML={{
                          __html: item?.text}}
                      />
                    </Grid>

                  </Box>

                </Grid>

              ))}

            </Box>

          </Container>

          { scheme_of_work?.footer?.title &&
            
            <Typography variant="h6" gutterBottom align="center"> { scheme_of_work?.footer?.title } </Typography>
          
          }

          { scheme_of_work?.footer?.caption &&

            <Typography variant="caption" gutterBottom display="block" align="center"> { scheme_of_work?.footer?.caption } </Typography>
          
          }

          { scheme_of_work?.footer?.body &&
            
            <Typography variant="body2" dangerouslySetInnerHTML={{__html: scheme_of_work?.footer?.body?.text}}/>
          
          }

        </Container>
      </>
  )
}

export default SchemeOfWork

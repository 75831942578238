
import { FC, useRef, useEffect, useState } from 'react'

import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha
} from 'react-google-recaptcha-v3';

import qs from 'qs'

import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'

import CircularProgress from '@mui/material/CircularProgress'

import { useRecoilValue, useSetRecoilState, useRecoilState, useRecoilCallback } from 'recoil'

import { skinStateAtom } from '@state/skin'

import { filtersStateAtom } from '@state/filters'
import { filtersShowStateAtom } from '@state/filtersShow'

import { productsStateAtom } from '@state/products'
import { productStateAtom } from '@state/product'
import { productsScrollStateAtom } from '@state/productsScroll'

import { aiStateAtom } from '@state/ai'

import { companyStateAtom } from '@state/company'

import { openSubscriptionsStateAtom } from '@state/openSubscriptions'
import { subscriptionsStateAtom } from '@state/subscriptions'

import { modalProductsStateAtom } from '@state/modalProducts'

import { currenciesStateAtom } from '@state/currencies'

import { globalProgressBackdropStateAtom } from '@state/globalProgressBackdrop'

import { FixedSizeList as List } from 'react-window'
import { FixedSizeGrid as Grid } from 'react-window';

import InfiniteLoader from "react-window-infinite-loader"

import useMeasure from '@hooks/useMeasure'

import ProductsTopGroup from '@components/Buttons/ProductsTopGroup'

import Filters from '@components/Filters'
import filterObject from '@utils/filterObject'

import {fee} from '@utils/fee'

import convertCurrency from '@utils/convertCurrency'

import SubscriptionsAddForm from '@components/SubscriptionsAddForm'

import { useCountsFilters } from '@hooks/useCountsFilters'

import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'

import useMediaQuery from '@mui/material/useMediaQuery'

import useBotDetect from '@hooks/useBotDetect'
import useMobileDetect from '@hooks/useMobileDetect'
import usePlatformDetect from '@hooks/usePlatformDetect'

import RowsMobile from './RowsMobile'
import RowsDesktop from './RowsDesktop'

import useTraining from '@hooks/useTraining'

import api from '@services/api'

const Cells = ({ columnIndex, rowIndex, data, style }: any) => {

  const currentIndex = rowIndex * data.columnCount + columnIndex;
  return (
    <RowsDesktop data={data} index={currentIndex} style={style}/>
  );
};

const mergeCarDataWithNoDuplicates = (

  existingData: any[],
  newData: any[]
): any[] => {

  const uniqueUrls = new Set(existingData.map((item:any) => item.id));

  const filteredNewData = newData.filter((item:any) => !uniqueUrls.has(item.id));

  const mergedData = [...existingData, ...filteredNewData];

  return mergedData;
}

const Lots: FC<any> = () => {

  const { executeRecaptcha } = useGoogleReCaptcha();
  
  const { windowSize } = useMeasure()

  const { training } = useTraining()

  const bot = useBotDetect()
  const mobile = useMobileDetect()
  const platform = usePlatformDetect()

  const skin = useRecoilValue<any>(skinStateAtom)

  const company = useRecoilValue<any>(companyStateAtom)
  const currencies = useRecoilValue<any>(currenciesStateAtom)
  
  const setProduct = useSetRecoilState<any>(productStateAtom)

  const filters = useRecoilValue<any>(filtersStateAtom)
  const filtersShow = useRecoilValue<any>(filtersShowStateAtom)
  
  const countsFilters = useCountsFilters()

  const ai = useRecoilValue<boolean>(aiStateAtom)
  const modalProducts = useRecoilValue<boolean>(modalProductsStateAtom)

  useEffect(() => {

    if(modalProducts) {


      const html = window.document.querySelector("html");  
      if(html){html.style.overflow = "hidden";}

    }else{

      const html = window.document.querySelector("html");  
      if(html){html.style.overflow = "auto";}

    }

  }, [modalProducts])
  
  const [globalProgressBackdrop, setGlobalProgressBackdrop] = useRecoilState<boolean>(globalProgressBackdropStateAtom)

  const setScroll = useSetRecoilState<number>(productsScrollStateAtom)
  const [scroll, setComponentScroll] = useState<number>(0)

  const [loading, setLoading] = useState<boolean>(false)

  const [openSubscriptions, setOpenSubscriptions] = useRecoilState<boolean>(openSubscriptionsStateAtom)
  
  const [additionalLoad, setAdditionalLoad] = useState(false)

  const subscriptions = useRecoilValue<any>(subscriptionsStateAtom)

  useEffect(() => {}, [platform, filters, subscriptions])
  
  const onClick = (product: any) => setProduct(product);

  const page = useRef(0)
  const hasNextPage = useRef(true)
  
  const listRef = useRef<any>(null)
  const fixScroll = useRef<any>(false)

  const containerRef = useRef<any>(false)
  const columnCountRef = useRef<any>(1)
  
  const [data , setData] = useRecoilState<any>(productsStateAtom)

  const loadData = () => {
  
    if(!hasNextPage.current) return;

    setLoading(true)

    page.current = page.current + 1

    if(page.current > 1) setAdditionalLoad(true);

    let orCountries:any = []
    
    for(const country of filters.countries){

      orCountries.push({
        $or: [
          {
            color: {
              id: {
                $in: filters.color.map((item:any) => item.id),
              },
            },
          },
        ],
        country: {
          id: country.id
        },
        ...(filters?.price !== null && {
          price_original: {
            ...((filters?.price?.min !== null || filters?.price?.max !== null) && {
              $notNull: true
            }),
            ...(filters?.price?.min && {
              $gte: convertCurrency(
                currencies, 
                'RUB', 
                country?.currency?.key, 
                fee({
                  type: 'minus',
                  amount: filters?.price?.min.value,
                  country: country,
                  company: company,
                })
              )
            }),
            ...(filters?.price?.max && {
              $lte: convertCurrency(
                currencies, 
                'RUB', 
                country?.currency?.key, 
                fee({
                  type: 'minus',
                  amount: filters?.price?.max.value,
                  country: country,
                  company: company,
                })
              )
            }),
          }
        }),

      })
    }

    const query = qs.stringify({
      ai,
      randomSort: false,
      filters: {
      
        ...(filters?.brand !== null && {
          brand: {
            id: filters.brand.id,
          },
        }),
        ...(filters?.model !== null && {
          model: {
            id: filters.model.id,
          },
        }),

        ...(filters?.body !== null && {
          body: {
            value: {
              eqi: filters.body.value,
            }
          }
        }),
        ...(filters?.drive !== null && {
          drive: {
            value: {
              eqi: filters.drive.value,
            }
          }
        }),
        ...(filters?.fuel !== null && {
          fuel: {
            value: {
              eq: filters.fuel.value,
            }
          }
        }),


        ...(filters?.transmission !== null && {
          transmission: {
            value: {
              eq: filters.transmission.value,
            }
          }
        }),
        
        ...(filters?.year !== null && {
          year: {
            ...((filters?.year?.min !== null || filters?.year?.max !== null) && {
              $notNull: true
            }),
            ...(filters?.year?.min && {
              $gte: filters?.year?.min.value
            }),
            ...(filters?.year?.max && {
              $lte: filters?.year?.max.value
            }),
          }
        }),
        ...(filters?.mileage !== null && {
          mileage: {
            ...((filters?.mileage?.min !== null || filters?.mileage?.max !== null) && {
              $notNull: true
            }),
            ...(filters?.mileage?.min && {
              $gte: filters?.mileage?.min.value
            }),
            ...(filters?.mileage?.max && {
              $lte: filters?.mileage?.max.value
            }),
          }
        }),
        ...(filters?.engine_capacity !== null && {
          engine_capacity: {
            ...((filters?.engine_capacity?.min !== null || filters?.engine_capacity?.max !== null) && {
              $notNull: true
            }),
            ...(filters?.engine_capacity?.min && {
              $gte: filters?.engine_capacity?.min.value
            }),
            ...(filters?.engine_capacity?.max && {
              $lte: filters?.engine_capacity?.max.value
            }),
          }
        }),
        '$or':  orCountries,
      },
      populate: {
        photo: `*`,

        donor: {
          fields: ['name', 'grade']
        },

        country: {
          fields: ['code', 'value', 'name']
        },
        city: {
          fields: ['value', 'name']
        },

        brand: {
          fields: ['name']
        },
        model: {
          fields: ['name']
        },

        transmission: {
          fields: ['value', 'name']
        },
      },
      pagination: {
        pageSize: 25,
        page: page.current,
      },
      sort: "createdAt:DESC",
      locale: ['ru'],
      
    }, {
      encodeValuesOnly: true,
    })

    api({ collection: 'lots', query }).then((data) => {

      if(data){

        if(data?.meta?.pagination?.pageCount){

          if(data?.meta?.pagination?.pageCount <= page?.current){

            hasNextPage.current = false;
          }
        }

        setData((prevData: any) => mergeCarDataWithNoDuplicates(prevData, data?.data));
      }

      setGlobalProgressBackdrop(false)
      
      setLoading(false)
      setAdditionalLoad(false)

    }).catch((error:any) => {

      setLoading(false)
      setAdditionalLoad(false)
    })

  }

  const loadMoreData = () => { if (!loading) loadData(); }

  useEffect(() => {

    if(data.length === 0) loadData();

  }, [])

  const onChangeFilters = () => {

    page.current = 0

    setData([])
    hasNextPage.current = true

    loadData()
  }

  const isItemLoaded = (index: number) => { 
    return index < data.length
  }

  const handleScroll = (scroll:any) => {setScroll( scroll.scrollOffset )};

  const matchesUseMediaQuery = useMediaQuery('(max-width:340px)')

  const pLR = matchesUseMediaQuery ? 0 : '16px'

  const [boxWidth, setBoxWidth] = useState<number>(300);

  useEffect(() => {
    const handleResize = () => {
      
      if (containerRef.current) {

        const width = containerRef.current.clientWidth
        
        if( width > 1200 ) {
          columnCountRef.current = 3;
        } else if( width > 800 ) {
          columnCountRef.current = 2;
        } else {
          columnCountRef.current = 1;
        }

        setBoxWidth(containerRef.current.clientWidth);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [])

  const columnCount = columnCountRef.current;
  const rowCount = Math.ceil(data.length / columnCount);

  const rowHeight =  mobile ? ( filters.brand ? 410 : 430 ): ( filters.brand ? 460 : 480 )

  const paddingTop = mobile ? ( filters?.advanced ? 290 : 175 ): ((skin === 'catalogFixFilters' || modalProducts) ? ( filters?.advanced ? 310 : 195 ) : 0)

  const getAtomValue = useRecoilCallback(({ snapshot }) => async () => {
    
    const value = await snapshot.getPromise(productsScrollStateAtom);
    return value;
  })
  
  const fetchAtomValue = async () => {

    const value = await getAtomValue();

    if(value !== 0 && listRef.current) {

      if(!fixScroll.current){

        fixScroll.current = true;
        
        listRef.current.scrollTo(value);

        setComponentScroll(value);
      }
    }
  }
 
  fetchAtomValue()
  
  return  <Container id={'catalog'} maxWidth="lg" ref={containerRef}
            sx={{
              pl: pLR, pr: pLR,  pt: 0,  pb: 0, mt: { sm: 1, md: 5 },
            ...( (skin === 'catalogFixFilters' || modalProducts ) && {
              mt: '0px !important',
            }),
          }}>

          {((bot) ?
            
            <Typography variant="subtitle1" gutterBottom align="center" sx={{ mt:'15px' }} onClick={()=>{setAdditionalLoad(true)} }>

              {filters?.brand?.name} {filters?.model?.name} 

            </Typography>
            :
            <Box sx={{  }}/>
          )}

          <ProductsTopGroup listRef={listRef} sx={{ mt: bot ? 0 : '15px'}}/>

          <Filters onChange={onChangeFilters}/>

          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={additionalLoad||false}
            onClose={()=>{}}
            message="Идет загрузка объявлений"
            key={'loadingnewcontent'}
            sx={{borderRadius: '8px', bottom: '25px', }}
          >
            <Alert
            onClose={()=>{}}
            icon={<CircularProgress size={18} color={'inherit'}/>} 
            severity="info"
            variant="filled"
            action={
              <></>
              //<Button size="small" aria-label="close" color="primary" onClick={()=>{setAdditionalLoad(false)}} sx={{p: 0.5, fontSize: 10, backgroundColor: 'unset'}}>Скрыть</Button>
            }
            style={{
              backgroundColor: 'rgba(0,0,0,0.75)',
              color: '#fff',
              fontSize: 12,
            }}
            >
              Идет загрузка...

            </Alert>
          </Snackbar>

          { data?.length > 0 &&

            <Box sx={{
              width: matchesUseMediaQuery ? windowSize.width - 20 : `100%`,
              "&&": {
                overflowX: 'hidden',
              },
              "&& :nth-of-type(1)": {
                borderTopLeftRadius: 25,
                borderTopRightRadius: 25,
              },
            }}>
            
            {((platform === 'mobile' && mobile) || (platform === 'bot' && bot)) && (

              <InfiniteLoader
                isItemLoaded={isItemLoaded}
                itemCount={data.length + 1}
                loadMoreItems={loadMoreData}
              >
                {({ onItemsRendered, ref }) => (
                  <List
                    height={windowSize.height - ( bot ? 126 : 0 )}

                    width={ matchesUseMediaQuery ? (windowSize.width - ( matchesUseMediaQuery ? 0 : 30)) : 'unset'}
                    
                    itemCount={data?.length}
                    itemSize={rowHeight - 55}
                    itemData={{
                      company,
                      filters,
                      mobile,
                      data: data,
                      paddingTop: bot ? 0 : 15,
                      onClick: onClick,
                      training,
                    }}
                    onItemsRendered={onItemsRendered}
                    ref={(list) => {
                      ref(list);
                      if (list) listRef.current = list;
                    }}
                    onScroll={handleScroll}
                  >

                    {RowsMobile}
                    
                  </List>
                )}
            
              </InfiniteLoader>
            )}
            
            {((platform === 'desktop' && !mobile && !bot)) && (

              <Box sx={{
                '& ':{
                  display: 'flex',
                  direction: 'row',
                  justifyContent: 'center', 
                  overflowX: 'hidden',
                },
                "& > *": {
                  overflowX: 'hidden !important',
                },
              }}>

                <InfiniteLoader
                  isItemLoaded={isItemLoaded }
                  itemCount={(data.length + 1)}
                  loadMoreItems={loadMoreData}
                >
                  {({ onItemsRendered, ref }) => (
                    <Grid
                    className="List"
                      height={
                        (skin === 'catalogFixFilters' || modalProducts) ? windowSize.height : windowSize.height - 126
                      }
                      width={boxWidth - 60}
                      columnCount={columnCount} // Set the number of columns
                      rowCount={rowCount}
                      rowHeight={rowHeight} // Set the height of each row                    
                      columnWidth={((boxWidth) / columnCount ) - (20)} // Adjust as needed
                      itemData={{
                        company,
                        filters,
                        mobile,
                        data: data,
                        paddingTop,
                        columnCount,
                        onClick: onClick,
                      }}
                      onItemsRendered={gridProps => { 
                        onItemsRendered({ overscanStartIndex: gridProps.overscanRowStartIndex * columnCount, overscanStopIndex: gridProps.overscanRowStopIndex * columnCount, visibleStartIndex: gridProps.visibleRowStartIndex * columnCount, visibleStopIndex: gridProps.visibleRowStopIndex * columnCount });
                      }} 
                      ref={(list) => {
                        ref(list);
                        if (list) listRef.current = list;
                      }}
                      onScroll={handleScroll}
                    >
                      {Cells}
                    </Grid>
                  )}
                </InfiniteLoader>

              </Box>
   
            )}

            </Box>
          }

          { loading && page.current === 1 ?

            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              height="70vh"
              sx={{
                color: 'text.primary'
              }}
            >

              <CircularProgress sx={{
                color: 'text.primary'
              }}/>

              <Typography variant="subtitle2" gutterBottom  align="center" sx={{ mt: 2.5 , ml: 5, mr: 5}}>
                
                { Object.keys(filterObject(filters)).length === 0 ?
                
                  <>Идет загрузка... </>
                  :
                  <>Подбираем</>
                }

              </Typography>
              
            </Box>
          :
            <>

            </>
          }

          {  !loading && data.length === 0 &&

            <Typography variant="subtitle2" gutterBottom align="center" sx={{ mt: 1, pt: 'calc(50vh - 150px)' }}>

              В данный момент нету в продаже

              {/* <Box>

                Filters: {countsFilters}

              </Box> */}

              <Box sx={{mt: 3}}>

                {countsFilters > 0 ? 
                <>
                {/*
                Вы можете оформить подписку на получение новых предложений, соответствующих вашим установленным фильтрам.
                
                Вы можете подписаться на получение новых предложений, соответствующих вашим установленным фильтрам.
                */}
                
                
                  <Typography variant="body1" display="block" align="center" gutterBottom>
                    
                    Вы можете подписаться на новые предложения

                  </Typography>
                  <Typography variant="caption" display="block" align="center" gutterBottom>
                    
                    соответствующих вашим фильтрам.

                  </Typography>

                  <Typography variant="caption" display="block" align="center" gutterBottom sx={{mt: 3 }}>

                    (Мгновенные оповещения о свежих предложениях.)

                  </Typography>
                </> 
                : 
                <>
                  <Typography variant="body1" display="block" align="center" gutterBottom>
                    
                    Вы можете подписаться на получение новых предложений

                  </Typography>

                  <Typography variant="caption" display="block" align="center" gutterBottom sx={{mt: 3 }}>

                  (Мгновенные оповещения о свежих предложениях.)
                  
                  {/*
                  Срочные уведомления о свежих предложениях.
                  Быстрые уведомления о свежих предложениях.
                  Немедленные оповещения о свежих предложениях.
                  */}

                  </Typography>
                </>
                }

              </Box>

            </Typography>
          }

          <SubscriptionsAddForm/>


        </Container>
}

export default Lots

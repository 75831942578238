
import { FC, useEffect, useState } from 'react'

import axios from 'axios'
import qs from 'qs'

import { useRecoilState, useRecoilValue } from 'recoil'

import { skinStateAtom } from '@state/skin'

import { filtersStateAtom } from '@state/filters'
import { filtersShowStateAtom } from '@state/filtersShow'

import { filtersDataStateAtom } from '@state/filtersData'

import { modelsStateAtom } from '@state/models'
import { companyStateAtom } from '@state/company'
import { countriesStateAtom } from '@state/countries'
import { modalProductsStateAtom } from '@state/modalProducts'

import SwipeableDrawer from '@components/SwipeableDrawer'

import Zoom from '@mui/material/Grow'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Unstable_Grid2'
import Typography from '@mui/material/Typography';

import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import useBotDetect from '@hooks/useBotDetect'
import useMobileDetect from '@hooks/useMobileDetect'
import usePlatformDetect from '@hooks/usePlatformDetect'

import Brand from './Brand'
import Model from './Model'

import Countries from './Countries'
import Price from './Price'
import EngineCapacity from './EngineCapacity'
import Fuel from './Fuel'
import Transmission from './Transmission'
import Drive from './Drive'
import Body from './Body'

import Mileage from './Mileage'
import Color from './Color'
import Year from './Year'

import useThemeMode from '@hooks/useThemeMode'

import useTraining from '@hooks/useTraining'

import api from '@services/api'

interface I {
  onChange?: () => void
}

const Filters: FC<I> = ({ onChange=()=>{} }) => {

  const { training } = useTraining()

  const themeMode = useThemeMode()

  const [maxHeightFilters, setMaxHeightFilters] = useState<number|null>(null)

  const bot = useBotDetect()
  const mobile = useMobileDetect()
  const platform = usePlatformDetect()

  const [filtersData, setFiltersData] = useRecoilState<any>(filtersDataStateAtom)
  const [filters, setFilters] = useRecoilState<any>(filtersStateAtom)
  const [filtersShow, setFiltersShow] = useRecoilState<any>(filtersShowStateAtom)
  
  const [models, setModels] = useRecoilState<any>(modelsStateAtom)

  const skin = useRecoilValue<any>(skinStateAtom)
  const modalProducts = useRecoilValue<boolean>(modalProductsStateAtom)

  const company = useRecoilValue<any>(companyStateAtom)
  const countries = useRecoilValue<any[]>(countriesStateAtom)

  const [cloneFilters, setCloneFilters] = useState<any>(null)
  const [cloneModels, setCloneModels] = useState<any>(null)


  const lastSize = 12
  
  const onClose = () => {
    window?.navigator?.vibrate && window?.navigator?.vibrate(10);
    setModels(cloneModels)
    setFiltersShow(false)

    setFilters({...cloneFilters/* , open: false */});
  }

  useEffect(() => {

    console.log(`filters`)
    console.log(filters)

  }, [filters])

  useEffect(() => {

    if(filtersShow){

      setCloneFilters(filters)
      setCloneModels(models)
    }

    if(!filtersShow){

      setFiltersShow(false)

      /* setFilters((prevState:any) => ({ ...prevState, open: false })); */
    }

  }, [filtersShow])

  const apply = () => {

    setFilters((prevState:any) => ({
      ...prevState,
      /* open: false, */
      ...(filters.countries.length === 0 && {
        countries: company.countries,
      }),
    }));

    setFiltersShow(false)

    onChange()
  }

  useEffect(() => {

    if(filtersData.length === 0){

      const query = qs.stringify({
        randomSort: false,
        populate: `deep`,
        locale: ['ru'],
      }, {
        encodeValuesOnly: true, // prettify URL
      })

      api({ collection: 'filter', query }).then((data) => {

        if(data) setFiltersData(data?.data);
  
      }).catch((error:any) => {

        console.log(error)
      })
    }
  }, [])

  useEffect(() => {
    
    const onResize = () => {

      if (!window.visualViewport) {
        return setMaxHeightFilters(window.innerHeight)
      }

      setMaxHeightFilters(window.visualViewport.height)
    }

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    }
  }, [])

  const desktop = () => {
    
    return <>

          <Grid container spacing={2}>

            <Grid xs={4}>
              <Brand/>
            </Grid>

            <Grid xs={4}>
              <Model/>
            </Grid>

            <Grid xs={4}>
              <Countries/>
            </Grid>

            <Grid xs={4}>
              <Price/>
            </Grid>

            <Grid xs={4}>
              <Year/>
            </Grid>

            { !filters?.advanced  ?

              <Grid xs={4}>
                <Button
                variant="contained"
                color={'secondary'}
                fullWidth
                sx={{
                  cursor: 'pointer', 

                  bgcolor: 'primary.company',
                  color: 'primary.main',

                  backdropFilter: 'unset',
                  boxShadow: 'unset',
                  
                  "&:hover": {
                    bgcolor: 'primary.company',
                    opacity: 0.9,
                  },
                  "&:active": {
                    bgcolor: 'primary.company',
                    opacity: 0.9,
                  },
                }}
                onClick={()=>{
                  window?.navigator?.vibrate && window?.navigator?.vibrate(10);
                  apply()}
                } startIcon={<TuneOutlinedIcon />}>

                  Применить

                </Button>
              </Grid>
              :
              <Grid xs={4}>
                <Mileage/>
              </Grid>
            }

            <Zoom in={filters?.advanced  || false} unmountOnExit>

              <Grid container>

                <Grid xs={4}>
                  <EngineCapacity/>
                </Grid>

                <Grid xs={2}>
                  <Body/>
                </Grid>
          
                <Grid xs={2}>
                  <Transmission/>
                </Grid>
                
                <Grid xs={2}>
                  <Drive/>
                </Grid>

                <Grid xs={2}>
                  <Fuel/>
                </Grid>

                <Grid xs={6}>
                  <Color/>
                </Grid>

                <Grid xs={6}>

                  <Grid container >

                    <Grid xs={4} sx={{ pt: 0, pl: 0, pr: 1.5 }}>

                      <Button
                      variant="contained"
                      color={'secondary'}
                      fullWidth
                      sx={{
                        cursor: 'pointer',

                        backdropFilter: 'unset',
                        boxShadow: 'unset',
                    
                        backgroundColor: themeMode === 'dark' ? 'rgba(255, 255, 255, 0.35)': 'rgba(0, 0, 0, 0.35)', 
                        ':hover': {
                          backgroundColor: themeMode === 'dark' ? 'rgba(255, 255, 255, 0.25)': 'rgba(0, 0, 0, 0.25)'
                        }
                      }}
                      onClick={()=>{
                        window?.navigator?.vibrate && window?.navigator?.vibrate(10);
                        setFilters((prevState:any) => ({ ...prevState, advanced: !filters?.advanced }));

                      }}>
                        
                        <KeyboardArrowUpIcon/>

                      </Button>

                    </Grid>

                      <Grid xs={8} sx={{ pt: 0, pl: 0, pr: 0 }}>
                        
                        <Button
                        variant="contained"
                        color={'secondary'}
                        fullWidth
                        sx={{
                          cursor: 'pointer',

                          bgcolor: 'primary.company',
                          color: 'primary.main',

                          backdropFilter: 'unset',
                          boxShadow: 'unset',

                          "&:hover": {
                            bgcolor: 'primary.company',
                            opacity: 0.9,
                          },
                          "&:active": {
                            bgcolor: 'primary.company',
                            opacity: 0.9,
                          },
                        }}
                        onClick={()=>{
                          window?.navigator?.vibrate && window?.navigator?.vibrate(10);
                          apply()}
                        } endIcon={<TuneOutlinedIcon />}>
                          
                          Применить

                        </Button>

                    </Grid>

                  </Grid>

                </Grid>

              </Grid>

            </Zoom>

            { !filters?.advanced  &&
              <Grid xs={12} sx={{display: 'flex', justifyContent: 'center'}}>
                <Typography variant="subtitle1" gutterBottom sx={{width: 200, cursor: 'pointer', lineHeight: 1, mt: 0.5 }} textAlign={'center'} onClick={()=>{
                  window?.navigator?.vibrate && window?.navigator?.vibrate(10);
                  setFilters((prevState:any) => ({ ...prevState, advanced: !filters?.advanced }));

                  }}>
                  { !filters?.advanced ?
                    <>
                      Расширенные фильтры
                    </>
                    :
                    <>
                      Обычные фильтры
                    </>
                  }
                </Typography>
              </Grid>
            }

            </Grid>
    </>
  }

  return (
  <>
    {((platform === 'mobile' && mobile) || (platform === 'bot' && bot)) && (


    <>
      <SwipeableDrawer
        anchor={'top'}
        open={filtersShow||false}
        onClose={onClose}
        onOpen={()=>{}}
        maxWidth
        sx={{ 
          '&& .MuiPaper-root': {
            borderBottomLeftRadius: 15,
            borderBottomRightRadius: 15,
            maxHeight: maxHeightFilters ? maxHeightFilters : window.innerHeight
          },
        }}>

          <Container maxWidth="sm" sx={{ pt: 1,pb: 1, }}>

            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={1}>

                { countries.length > 1 &&
                  <Grid xs={12}>
                    <Countries/>
                  </Grid>
                }

                <Grid container spacing={2}>
                  <Grid xs={6}>
                    <Brand/>
                  </Grid>

                  <Grid xs={6}>
                    <Model/>
                  </Grid>
                </Grid>

                <Grid xs={12}>
                  <Price/>
                </Grid>

                <Grid xs={12}>
                  <Year/>
                </Grid>

                <Grid xs={12}>
                  <Mileage/>
                </Grid>

                <Zoom in={filters?.advanced  || false} unmountOnExit>

                  <Grid container>

                    <Grid xs={12}>
                      <EngineCapacity/>
                    </Grid>

                    <Grid container xs={12} spacing={2}>

                      <Grid xs={6}>
                        <Body/>
                      </Grid>
                
                      <Grid xs={6}>
                        <Transmission/>
                      </Grid>
                      
                    </Grid>

                    <Grid container xs={12} spacing={2}>

                      <Grid xs={6}>
                        <Drive/>
                      </Grid>

                      <Grid xs={6}>
                        <Fuel/>
                      </Grid>

                    </Grid>

                    <Grid xs={12}>
                      <Color/>
                    </Grid>

                  </Grid>
                </Zoom>
                
                <Grid xs={12}>
                  <Typography variant="subtitle1" gutterBottom sx={{lineHeight: 1, mt: 0.5 }} textAlign={'center'} onClick={()=>{
                    window?.navigator?.vibrate && window?.navigator?.vibrate(10);
                    setFilters((prevState:any) => ({ ...prevState, advanced: !filters?.advanced }));
                    }}>
                    { !filters?.advanced ?
                      <>
                        Расширенные фильтры
                      </>
                      :
                      <>
                        Обычные фильтры
                      </>
                    }
                  </Typography>
                </Grid>

                <Grid xs={lastSize} sm={lastSize} md={lastSize} lg={lastSize} xl={lastSize}>
                  <Button
                  variant="contained"
                  color={'secondary'}
                  fullWidth
                  sx={{
                    cursor: 'pointer', 
                    mb: 1.5,

                    bgcolor: 'primary.company',
                    color: 'primary.main',
                    
                    backdropFilter: 'unset',
                    boxShadow: 'unset',
                    
                    "&:hover": {
                      bgcolor: 'primary.company',
                      opacity: 0.9,
                    },
                    "&:active": {
                      bgcolor: 'primary.company',
                      opacity: 0.9,
                    },

                    ...( training && training.active && training.step === 6 && {
                      ...training.trainingAnimation
                    })
                  }}
                  onClick={()=>{
                    window?.navigator?.vibrate && window?.navigator?.vibrate(10);
                    apply()
                    if( training &&  training.active && training.step === 6 ) training.next();
                  }
                  }
                  endIcon={<FilterAltOutlinedIcon />}>

                    Применить

                  </Button>
                </Grid>
              </Grid>
            </Box>

          </Container>

        </SwipeableDrawer>

      </>

    )}

    {((platform === 'desktop' && !mobile)) && (

      <>
        
        {( ( skin === 'catalogFixFilters' || modalProducts ) &&

          <Container maxWidth="lg" sx={{
            position: 'fixed',
            zIndex: 2,
            bgcolor: 'background.default',

            p: '10px !important',
            pt: '20px !important',
            pl: '0 !important',
            pr: '55px !important',
          }}>

            {desktop()}

          </Container>
        )}

        {( ((skin === 'catalog' && !modalProducts )|| ( skin === 'default' && !modalProducts)) &&

          <>

          <Box
            sx={{
              flexGrow: 1,
            }}>

            {desktop()}

            </Box>
          </>

        )}
        
      </>  
     )}

    </>
  )
}

export default Filters;
import { atom, selector } from "recoil"

export const filtersDataStateAtom = atom<any>({
  key: "filtersDataStateAtom",
  default: []
})

export const filtersDataStateSelector = selector({
  key: "filtersDataStateSelector",
  get: ({ get }) => {
    const filtersData = get(filtersDataStateAtom)
    return filtersData
  }
})


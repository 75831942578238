
import { FC, MouseEvent, useEffect, useState } from 'react'

import axios from 'axios'

import { useRecoilState, useRecoilValue } from 'recoil'

import { callBackCalculatorPriceStateAtom } from '@state/callBackCalculatorPrice'
import { callBackAttachDataStateAtom } from '@state/callBackAttachData'
import { companyStateAtom } from '@state/company'

import MuiPhoneNumber from 'material-ui-phone-number'

import { useForm, Controller } from 'react-hook-form'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Unstable_Grid2'

import { LoadingButton } from '@mui/lab'

import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'

import SendIcon from '@mui/icons-material/Send'

import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"

import Typography from '@mui/material/Typography'

import DoneIcon from '@mui/icons-material/Done'

import useMobileDetect from '@hooks/useMobileDetect'

import DesktopOrMobileOrBot from '@components/DesktopOrMobileOrBot'

import api from '@services/api'

const Schema = yup.object().shape({
  name: yup.string().required("Необходимо ввести имя"),
  phone: yup.string().min(18).required("Необходимо ввести телефон"),
  callBackType: yup.string().required("Необходимо выбрать способ связи"),
})

const PopupCallBackCalculatorPrice: FC<any> = () => {

  const mobile = useMobileDetect()

  const company = useRecoilValue<any>(companyStateAtom)


  const callbackTypes = [
    'WhatsApp' ,
    'Telegram' ,
    'Перезвонить' ,
  ]
  
  const [ loading, setLoading ] = useState<boolean>(false)
  const [ send, setSend ] = useState<boolean>(false)

  const [ open, setOpen ] = useRecoilState<boolean>(callBackCalculatorPriceStateAtom)
  
  const callBackAttachData = useRecoilValue<any>(callBackAttachDataStateAtom)
  
  const { handleSubmit, control, getValues, formState: { errors } } = useForm({
    resolver: yupResolver(Schema),
    defaultValues: {
      name: '',
      phone: '',
      country: '',
      callBackType: 'WhatsApp',
    }
  })

  useEffect(() => {

    if(!send) return;

      setTimeout(()=>{

        setSend(false)
        setOpen(false)
      }, 2500)
  }, [send, open])
  
  const onSubmit = async (data:any) => {

    window?.navigator?.vibrate && window?.navigator?.vibrate(10);
    setLoading(true)

    console.log(`callBackAttachData`)
    console.log(callBackAttachData)

    api({
      method: 'POST',
      collection: 'call-backs', 
      data: {
        data: {
          name: getValues('name'),
          phone: getValues('phone'),
          country: getValues('country') || null,
          callBackType: getValues('callBackType'),
          lot: null,
        }
      }
    }).then((data) => {

      setSend(true)
      setLoading(false)
      console.log(data)

    }).catch((error:any) => {

      setLoading(false)
      console.log(error)
    })

  }
  
  const close = (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()
    setOpen(false)
  }

  const onClose = () => {
    setOpen(false)
  }

  const component = () => {
    
  if(send){
    return ( 
      <>

        <Grid container xs={12}>
                            
          <Grid xs={12} display="flex" justifyContent="center">

            <DoneIcon  sx={{mt: 3, fontSize: 100, color:'primary.company',}}/>

          </Grid>

          <Grid xs={12} display="flex" justifyContent="center">
          
            <Typography color="textSecondary" sx={{m: 1, mb: 0, fontSize: 17, textAlign: "center"}}>

              В ближайшее время 

            </Typography>
            
          </Grid>  

          <Grid xs={12} display="flex" justifyContent="center">

            <Typography color="textSecondary" sx={{mt: 0, mb: 5, fontSize: 17, textAlign: "center"}}>

              Менеджер свяжется с вами!

            </Typography>

          </Grid>

        </Grid>

      </>
    )
  }

    return <>
          
          <Typography sx={{...(!mobile && { m: 2.5, mt: 0, }), textAlign: "center" ,  typography: {  xs: 'h6', sm: 'h6', md: 'h6', lg: 'h6' }}}>

            Рассчитать стоимость
              
          </Typography>

          <Box
            component="form"
            sx={{
              width: '100%',
              padding: 2,
              '& > :not(style)': { mt: 1, mb: 1, width: '100%' },
            }}
            noValidate
            autoComplete="off"
          >

            <Controller
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { isTouched, isDirty, error },
              }) => (
                <TextField
                  label="Имя"
                  value={value}
                  onBlur={onBlur}
                  inputRef={ref}
                  onChange={onChange}
                />
              )}
              name="name"
              control={control}
              rules={{ required: true }}
            />

            {errors?.name && <Typography variant="overline" color="error">{errors.name.message}</Typography>}

            <Controller
              name="phone"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <MuiPhoneNumber
                variant="outlined"
                defaultCountry={'ru'}
                regions={'europe'}
                onChange={onChange}
                value={value}
                />
              )}
            />
            
            {errors?.phone && errors.phone.type === "required" && <Typography variant="overline" color="error">{errors.phone.message}</Typography>}
            {errors?.phone && errors.phone.type === "min" && <Typography variant="overline" color="error">телефон должен быть не менее 11 символов</Typography> }
            


            { ( company && company?.countries?.length > 0 ) && 

              <>
                
                <Controller
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { isTouched, isDirty, error },
                }) => (
                  <Autocomplete
                  freeSolo={false}
                  disableClearable
                  disablePortal
                  id="country"
                  options={company?.countries}
                  
                  getOptionLabel={(option:any) => option.name}
                  isOptionEqualToValue={(option, value) => option.id === value.id}

                  onChange={(event, value:any) => {
                    onChange(value)
                  }}
                  sx={{ width: 350, padding: 0, margin: 0, mt: '10px !important', mb: '20px !important' }}
                  renderInput={(params) => <TextField {...params} label="Авто из какой страны интересует?" />}
                />
                )}
                name="country"
                control={control}
                rules={{ required: true }}
                />
              
              </>

            }



            <Controller
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { isTouched, isDirty, error },
            }) => (
              <Autocomplete
              freeSolo={false}
              disableClearable
              disablePortal
              id="callbackTypes"
              defaultValue={callbackTypes[0]}
              options={callbackTypes}
              isOptionEqualToValue={(option, value) =>
                option === value
              }
              onChange={(event, value:any) => {
                onChange(value)
              }}
              sx={{ width: 300, padding: 0, margin: 0 }}
              renderInput={(params) => <TextField {...params} label="Как с вами связаться?"/>}
            />
            )}
            name="callBackType"
            control={control}
            rules={{ required: true }}
            />
            {errors?.callBackType && <Typography variant="overline" color="error">{errors.callBackType.message}</Typography>}

            <LoadingButton
            sx={{
              bgcolor: 'primary.company',
              "&:hover": {
                bgcolor: 'primary.company',
                opacity: 0.7,
              },
              "&:active": {
                bgcolor: 'primary.company',
                opacity: 0.7,
              },
            }}
              loading={loading} variant="outlined" onClick={handleSubmit(onSubmit)} endIcon={<SendIcon />}>Отправить</LoadingButton>
            
            <Grid sx={{textAlign: 'center'}}>  
              <Typography  variant="caption" color="textSecondary" >Нажимая на кнопку, вы даете согласие на обработку персональных данных и соглашаетесь c политикой конфиденциальности.</Typography>
            </Grid>

          </Box>
    </>
  }
  
  return (

    <DesktopOrMobileOrBot open={open || false} onClose={onClose}>

      <Box>

        {component()}
          
      </Box>

    </DesktopOrMobileOrBot>
  )

}

export default PopupCallBackCalculatorPrice

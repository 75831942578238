import { atom, selector } from "recoil"
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

export const currenciesStateAtom = atom({
  key: "currenciesStateAtom",
  default: null,
  effects_UNSTABLE: [persistAtom],

})

export const currenciesStateSelector = selector({
  key: "currenciesStateSelector",
  get: ({ get }) => {
    const currencies = get(currenciesStateAtom)
    return currencies
  }
})


import { useMemo, useEffect, useState } from 'react'

import useMediaQuery from '@mui/material/useMediaQuery'

function useMobileDetect() {

  const matchesUseMediaQuery = useMediaQuery('(max-width:420px)')
  
  const isMobileDevice = useMemo(() => () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent), [navigator.userAgent])
  
  const [mobile, setMobile] = useState<boolean|null>(false)

  const handle = () => {

    if(matchesUseMediaQuery){

      setMobile(true)
    }else
    if(isMobileDevice()){
      
      setMobile(true)
    }else{

      setMobile(false)
    }
  };

  useEffect(() => {

    handle();
  }, [])

  return mobile
}

export default useMobileDetect

export { useMobileDetect }



import { FC, useRef, useEffect, useState } from 'react'

import axios from 'axios'
import qs from 'qs'

import { useRecoilState, useRecoilValue } from 'recoil'

import { filtersStateAtom } from '@state/filters'
import { filtersDataStateAtom } from '@state/filtersData'

import { modelsStateAtom } from '@state/models'
import { aiStateAtom } from '@state/ai'

import Box from '@mui/material/Box'

import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'

import { makeStyles } from '@mui/styles'

import api from '@services/api'

const useStyles = makeStyles({
  customCursor: {
    cursor: 'pointer',
  },
})


const Model: FC = () => {

  const classes = useStyles()

  const [filters, setFilters] = useRecoilState<any>(filtersStateAtom)

  const filtersData = useRecoilValue<any>(filtersDataStateAtom)
  const [models, setModels] = useRecoilState<any>(modelsStateAtom)
  const ai = useRecoilValue<any>(aiStateAtom)
  
  useEffect(()=>{}, [filters, filtersData])

  const page = useRef(0)

  const [loading, setLoading] = useState<boolean>(false)

  useEffect(()=>{

    if(models.length > 0 ) return;

    if(!filters.brand) return;

    const query = qs.stringify({
      ai,
      randomSort: false,
      filters: {
        brand: {
          id: filters?.brand?.id,
        },
        active: {
          $eq: true,
        }
      },
      pagination: {
        pageSize: 100,
        page: page.current,
      },
      locale: ['ru'],
    }, {
      encodeValuesOnly: true,
    })

    api({ collection: 'models', query }).then((data) => {

      if(data) setModels(data?.data);
      
      setLoading(false)

    }).catch((error:any) => {

      setLoading(false)

      console.log(error)
    })

  }, [models])

  useEffect(()=>{

  }, [filters.brand])

  const checkedNotNullItem = (item: any, filters: any) => {

    let count = 0

    for(const country of filters?.countries){

      if(country.value === 'Korea'){
        count = count + item?.count_korea
      }else
      if(country.value === 'China'){
        count = count + item?.count_china
      }else
      if(country.value === 'Japan'){
        count = count + item?.count_japan
      }else
      if(country.value === 'UAE'){
        count = count + item?.count_uae
      }else
      if(country.value === 'USA'){
        count = count + item?.count_usa
      }
    }

    return count
  }

  const renderOption = (props:any, option:any) => {

    const count = checkedNotNullItem(option, filters)

    return <li {...props}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'nowrap', width: '100%', opacity: count > 0 ? 1 : 0.35}}>
        <Box>
          {option.name} 
        </Box>
        <Box>
          {count} шт
        </Box>
      </Box>
    </li>
  }
  
  return (
    <Autocomplete
      disabled={filters.brand ? false : true}
      value={filters.model}
      onChange={(event, newValue) => {
        window?.navigator?.vibrate && window?.navigator?.vibrate(10);
        setFilters((prevState:any) => ({ ...prevState, model: newValue }));
      }}
      options={models}
      getOptionLabel={(option) => option.name}
      noOptionsText={'Нету донных'}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderOption={renderOption}

      renderInput={(params) => (
        <TextField
          onClick={()=>{window?.navigator?.vibrate && window?.navigator?.vibrate(10);}}
          sx={{
            justifyContent: `center`,
            alignItems: `center`,
            '& .MuiInputBase-root': {
              cursor: 'pointer', 
            },
            '& .MuiInputBase-input': {
              cursor: 'pointer', 
            },
          }}
          {...params}
          label="Модель"
          /* placeholder={filters.model ? '' : 'Модель'} */
          variant="outlined"
          fullWidth
          inputProps={{
            ...params.inputProps,
            className:  classes.customCursor, // Применяем пользовательский класс CSS при не выбранной опции
          }}
          InputLabelProps={{sx: {  marginTop: -0.25 } }}

        />
      )}
    />
  )
}

export default Model;

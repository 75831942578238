import { FC, useMemo } from 'react'

import MuiPhoneNumber from 'material-ui-phone-number'

import { useRecoilValue } from 'recoil'
import { companyStateAtom } from '@state/company'

import Container from '@mui/material/Container'
import Grid from '@mui/material/Unstable_Grid2'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import parseDynamicComponentUnionByCity from '@utils/parseDynamicComponentUnionByCity'

import { useBotDetect } from '@hooks/useBotDetect'
import { useMobileDetect } from '@hooks/useMobileDetect'

import { replaceImageFormat } from '@utils/replaceImageFormat'

const  { REACT_APP_MINIO_URL_FROM, REACT_APP_MINIO_URL_TO } = process.env

const Offices: FC<any> = () => {

  const company = useRecoilValue<any>(companyStateAtom)

  const bot = useBotDetect()
  const mobile =   useMobileDetect()

  const offices:any = useMemo(() => parseDynamicComponentUnionByCity(company?.offices?.offices),[company?.offices?.offices])

  return (
      <>
              
        <Container id={'offices'} sx={{pt: 0, mt: { xs: bot ? 0 : 5, md: 5 }, }}>
          
          <Box sx={{mb: 3.5, position: 'relative'}}>
          
          { offices?.header?.title &&
            
            <Typography variant="h6" gutterBottom align="center"> { offices?.header?.title } </Typography>
          }

          { offices?.header?.caption &&

            <Typography variant="caption" gutterBottom display="block" align="center"> { offices?.header?.caption } </Typography>
          }

          { offices?.header?.body &&
            
            <Typography variant="body2" dangerouslySetInnerHTML={{__html: offices?.header?.body?.text}}/>
          }
          
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
            }}
          >
            {Object.entries(offices?.data).map(([city, offices]:any, index:number) => (

              <Box
              key={index}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
              }}
              >

                <Typography variant="h6" gutterBottom> { city } </Typography>

                <Box key={city} sx={{mb: 2}}>

                  {offices?.map((office:any) => (

                    <Box key={office.id}>
                      
                      { office?.address &&

                        <Typography variant="caption" gutterBottom> { office?.address } </Typography>
                      }
                        
                      { office?.description &&

                        <Typography variant="caption" gutterBottom> { office?.description } </Typography>
                      }

                      {office?.contacts?.map((contact:any) => (

                        <Grid container spacing={2} sx={{mt: 0.5, mb: 0.5}} key={contact.id}>

                          <Grid xs={6} sx={{pt: 0.5,pb:0,}}>
                          
                            <MuiPhoneNumber
                              disabled
                              variant="outlined"
                              defaultCountry={''}
                              
                              regions={'europe'}
                              onChange={()=>{}}
                              value={contact?.phone}

                              sx={{
                                mt: 0.5,
                                '& .MuiInputAdornment-root ': {
                                  display: 'none',
                                },
                                '& .MuiInputBase-root': {
                                  height: 2,
                                  fontSize: '0.9rem',
                                  background: 'unset',
                                },
                                '& .MuiInputBase-inputAdornedStart': {
                                  padding: 0,
                                }
                              }}
                              />

                          </Grid>

                          <Grid xs={6} sx={{pt:0,pb:0,}}>
                            
                            <Typography variant="caption"> {contact?.type} </Typography>

                          </Grid>

                          {contact?.description &&

                            <Grid xs={12} sx={{pt:0,pb:0,}}>

                              <Typography variant="caption"> {contact?.description} </Typography>
                              
                            </Grid>
                          }
                          
                        </Grid>
                                                    
                      ))}
                      
                      <Box sx={{mt: 1, position: 'relative'}}>

                        <Box
                        style={{
                          backgroundImage: `url(${ office?.map?.image ? replaceImageFormat(office?.map?.image?.formats?.small?.url?.replace(REACT_APP_MINIO_URL_FROM, REACT_APP_MINIO_URL_TO)) : ''})`,
                          backgroundPosition: `center`,
                          backgroundSize: `cover`,
                          backgroundRepeat: `no-repeat`,
                          height: `120px`,
                          minHeight: `120px`,
                          width: '100%',
                          borderRadius: 15,
                          marginBottom: 15,
                        }}/>

                          <Box
                          sx={{
                            mt: 1,
                            position: 'absolute',
                            padding: 1,
                            borderRadius: 1,
                            backgroundColor: 'rgba(65, 65, 65, 0.5)',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)', 
                            color: 'rgba(255, 255, 255, 1)',
                            whiteSpace: 'nowrap',

                            cursor: 'pointer',
                          }}
                          onClick={()=>{

                            window?.Telegram?.WebApp?.openLink(office?.map?.url)
                          }}>

                            Открыть карту

                          </Box>
                        
                      </Box>

                    </Box>
                  ))}
                </Box>
              </Box>
            ))}
          </Box>

          { offices?.footer?.title &&

            <Typography variant="h6" gutterBottom align="center"> { offices?.footer?.title } </Typography>
          }

          { offices?.footer?.caption &&

          <Typography variant="caption" gutterBottom display="block" align="center"> { offices?.footer?.caption } </Typography>
          }

          { offices?.footer?.body &&
            
            <Typography variant="body2" dangerouslySetInnerHTML={{__html: offices?.footer?.body?.text}}/>
          }

        </Container>
    
      </>
  )
}

export default Offices

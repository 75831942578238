
import { FC, memo, useMemo, ReactNode } from 'react'

import Grid from '@mui/material/Unstable_Grid2'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'

import ListItem from '@mui/material/ListItem'

import { NumericFormat } from 'react-number-format'

import { styled } from '@mui/material/styles'

import { milesToKilometers } from '@utils/milesToKilometers'
import { fee } from '@utils/fee'

import useCurrencyConversion from '@hooks/useCurrencyConversion'

import { replaceImageFormat } from '@utils/replaceImageFormat'

const  { REACT_APP_MINIO_URL_FROM, REACT_APP_MINIO_URL_TO } = process.env

type Props = {
  children: ReactNode
  sx?: any
}
/* const MyStack = ({children, sx} : Props) => {
  return <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          spacing={2}
          marginTop={1}
          marginBottom={1}
          borderTop={0}
          borderColor="primary.main"
          sx={{...sx,  boxShadow: 'unset'}}
        >
          {children}
        </Stack>
} */

const MyStack2 = ({children, sx} : Props) => {
  return <Stack
          direction="column"
          justifyContent="space-between"
          alignItems="flex-start"
          margin={0.5}
          marginLeft={1}
          marginRight={1}
          border={0}
          borderColor="primary.main"
          sx={{...sx, whiteSpace: `nowrap`, borderRadius: 2,  boxShadow: 'unset'}}
        >
          {children}
        </Stack>
}


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  marginTop: 0,
  marginBottom: 0,
  marginLeft: 0,
  paddingTop: 0,
  lineHeight: 1,
  color: theme.palette.text.secondary,
  boxShadow: 'unset',
}))

const Item2 = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  marginTop: -5,
  marginBottom: 0,
  marginLeft: 0,
  paddingTop: 0,
  lineHeight: 1,
  fontSize: '0.750rem',
  color: theme.palette.text.secondary,
  boxShadow: 'unset',
}))

//  const Row = memo((props: ListChildComponentProps) => {

const Mobile: FC<any> = ({index, style, data, memoizedData}) => {

  const convertedAmount = useCurrencyConversion(memoizedData?.currency_key, 'RUB', memoizedData?.price_original);

  const price:any = useMemo(() => fee({
    type: 'plus',
    amount: convertedAmount,
    country: memoizedData?.country,
    company: data?.company,
    brand: memoizedData?.brand,

  }), [convertedAmount, memoizedData?.country, data?.company, memoizedData?.brand ]);

    return (
      <ListItem
      style={{
        ...style,
        top: `${parseFloat(style.top) + data?.paddingTop}px`,
        userSelect: 'none',
      }}
      key={index}
      component="div"
      disablePadding
      onClick={()=>{

        if( data.training &&  data.training.active && data.training.step === 2 ) {

          if(index !== 0) return;
          
          data.training.next()
        }

        window?.navigator?.vibrate && window?.navigator?.vibrate(10);
        data.onClick(memoizedData);
      }}>
          <Stack
          direction="column"
          justifyContent="space-between"
          alignItems="flex-start"
          spacing={0}
          sx={{
            p: 0, pl:0, width: '100%', boxShadow: 'unset', // boxShadow: '0px 0px 6px 3px rgba(0,0,0,0.1) // лагает на моб.
            background: 'rgba(119, 126, 141, 0.15)',
        
            ...( data.training &&  data.training.active && data.training.step === 2 && index === 0 && {
              ...data.training.trainingAnimation,
            })
          }}
          padding={0}
          paddingBottom={0.25}
          margin={1}
          border={0}
          borderColor="primary.main"
          borderRadius={2.5}
          >
            <Item sx={{width: '100%', minHeight: 200, padding: 0 }}>
  
              <div
              style={{
                backgroundImage: `url(${memoizedData.photo?.[0]?.formats?.small ? replaceImageFormat(memoizedData.photo?.[0]?.formats?.small?.url.replace(REACT_APP_MINIO_URL_FROM, REACT_APP_MINIO_URL_TO)) :  replaceImageFormat(memoizedData.photo?.[0]?.url.replace(REACT_APP_MINIO_URL_FROM, REACT_APP_MINIO_URL_TO))})`,
                backgroundPosition: `center`,
                backgroundSize: `cover`,
                backgroundRepeat: `no-repeat`,
                height: `220px`,
                borderRadius: 15,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                marginBottom: 15,
                cursor: 'pointer',
              }}/>
  
            </Item>
            <Item sx={{width: '100%', padding: 0}}>
  
              { !data.filters.brand &&
  
                <Grid container spacing={0}>
                  <Grid xs={12}>
                    <MyStack2 sx={{mt: 0, mb: 0}}>
                      <Item>{memoizedData?.brand?.name} {memoizedData?.model?.name}</Item>
                    </MyStack2>
                  </Grid>
                </Grid>
              }
  
              <Grid container spacing={0}>
  
                <Grid xs={3}>
                  <MyStack2>
                    { memoizedData?.country ? 
                        <Item sx={{fontWeight: 600}}>{memoizedData?.country?.name}</Item>
                      :
                      <Item>
                        Нет данных
                      </Item>
                    }
                    <Item2>Страна</Item2>
                  </MyStack2>
                </Grid>
                <Grid xs={5}>
                  <MyStack2>
                    { memoizedData?.engine_capacity ? 
                      <Item sx={{fontWeight: 600}}>
                        { memoizedData?.engine_capacity < 10 ?
                          <>Нет данных</>
                          :
                          <NumericFormat allowLeadingZeros thousandSeparator=" "  decimalScale={0} displayType="text" value={memoizedData?.engine_capacity?.toFixed(0)} suffix={'см3'} />
                        }
                      </Item>
                      :
                      <Item>
                        Нет данных
                      </Item>
                    }
                    <Item2>Двигатель</Item2>
                  </MyStack2>
                </Grid>
                <Grid xs={4}>
                  <MyStack2>
                    { memoizedData?.mileage === 0 ?
                    <Item sx={{fontWeight: 600}}>
                      новый автомобиль
                    </Item>
                    :
                    <Item sx={{fontWeight: 600}}>
                      <NumericFormat allowLeadingZeros thousandSeparator=" "  decimalScale={0} displayType="text" value={milesToKilometers(memoizedData?.mileage?.toFixed(0))} suffix={'км'} />
                    </Item>
                    }
                    <Item2>Пробег</Item2>
                  </MyStack2>
                </Grid>
                <Grid xs={3}>
                  <MyStack2>
                    { memoizedData?.year ? 
                        <Item sx={{fontWeight: 600}}>{memoizedData?.year}</Item>
                      :
                      <Item>
                        Нет данных
                      </Item>
                    }
                    <Item2>Год</Item2>
                  </MyStack2>
                </Grid>
                <Grid xs={5}>
                  <MyStack2>
                  { memoizedData?.transmission ? 
                      <Item sx={{fontWeight: 600}}>{memoizedData?.transmission?.name}</Item>
                      :
                      <Item>
                        Нет данных
                      </Item>
                    }
                    <Item2>КПП</Item2>
                  </MyStack2>
                </Grid>
                <Grid xs={4}>
                  <MyStack2>
                    <Item sx={{fontWeight: 600}}>
                      <NumericFormat allowLeadingZeros thousandSeparator=" "  decimalScale={0} displayType="text" value={ price } suffix={'₽'} />
                    </Item>
                    <Item2>{memoizedData?.donor?.name === 'exportcar.jp' ? 'Стартовая цена' : 'Цена'}</Item2>
                  </MyStack2>
                </Grid>
              </Grid>
              
            </Item>
          </Stack>
      </ListItem>
    )
  };

/* 
export default Row;
*/

const Row = memo((props: any) => {

  const { data, index, style } = props;
  
  const memoizedData:any = useMemo(() => data?.data[index], [data]);

  if(memoizedData === undefined) return null
  
  return <Mobile  index={index} style={style} data={data} memoizedData={memoizedData} />

});

export default Row;

export { Row, Mobile }

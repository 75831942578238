
import { FC } from 'react'

import { useRecoilState } from 'recoil'
import { darkModeStateAtom } from '@state/darkMode'

import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'

import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  customCursor: {
    cursor: 'pointer',
  },
})

interface Option {
  id: number;
  value: string;
  name: string;
}

const options: Option[] = [
  { id: 0, value: 'light', name: 'Светлая' },
  { id: 1, value: 'dark', name: 'Темная' },
  { id: 2, value: 'system', name: 'Системная' },
]

const DarkMode: FC = () => {

  const classes = useStyles()

  const [darkMode, setDarkMode] = useRecoilState<any>(darkModeStateAtom)

  return (
    <Autocomplete
    disableClearable
    freeSolo={false}
    value={darkMode}
    onChange={(event, newValue) => {
      window?.navigator?.vibrate && window?.navigator?.vibrate(10);
      setDarkMode( newValue );
    }}
    options={options}
    getOptionLabel={(option) => option.name}
    noOptionsText={'Нету донных'}
    renderInput={(params) => (
      <TextField
        onClick={()=>{window?.navigator?.vibrate && window?.navigator?.vibrate(10);}}
        sx={{
          justifyContent: `center`,
          alignItems: `center`,
          userSelect: `none`,
        }}
        {...params}
        label="Тема"
        /* placeholder={filters.body ? '' : 'Кузов'} */
        variant="outlined"
        fullWidth
        inputProps={{
          ...params.inputProps,
          readOnly: true,
          className:  classes.customCursor,
        }}
      />
    )}
  />
  )
}

export default DarkMode;

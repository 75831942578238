import { FC, useMemo, useRef, useEffect, useState } from 'react'

import { useRecoilValue, useSetRecoilState } from 'recoil'
import { companyStateAtom } from '@state/company'
import { modalProductsStateAtom } from '@state/modalProducts'

import Container from '@mui/material/Container'
import Box from '@mui/material/Box'

import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import MoreVertIcon from '@mui/icons-material/MoreVert'

import parseDynamicComponent from '@utils/parseDynamicComponent'

const Menus: FC = () => {

  const company = useRecoilValue<any>(companyStateAtom)
  const setModalProducts = useSetRecoilState<boolean>(modalProductsStateAtom)

  const menu:any = useMemo(() => parseDynamicComponent(company?.menu?.menu),[company?.menu?.menu])
  const [items, setItems] = useState<any>(menu?.data || [])


  useEffect(() => {

    let items:any = []

    for(const item of menu?.data) {

      items.push({ url: item.url, name: item.name })

      setItems(items) 
    }
  }, [menu])


  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries:any) => {
      for (let entry of entries) {
        const { width } = entry.contentRect;
        const newDisplayedItems: any[] = [];
        const newHiddenItems: any[] = [];
        let totalWidth = 0;

        items.forEach((item:any) => {

          const itemWidth = measureTextWidth(item.name);
          if (totalWidth + itemWidth < width) {

            newDisplayedItems.push(item);
            totalWidth += itemWidth + 45;
          } else {

            newHiddenItems.push(item);
          }
        });
        setDisplayedItems(newDisplayedItems);
        setHiddenItems(newHiddenItems);
      }
    });
  
    if (buttonGroupRef.current) {
      resizeObserver.observe(buttonGroupRef.current);
    }
  
    return () => {
      resizeObserver.disconnect();
    };
  }, [items]);

  const scrollTo = ({ key }: { key: string }) => {

    const element = document.getElementById(key);
    if (element) {

      const yOffset = -75; 
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({top: y, behavior: 'smooth'})
    }
  }

  const containerRef = useRef<any>(false)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const buttonGroupRef = useRef<HTMLDivElement>(null);
  const [displayedItems, setDisplayedItems] = useState<any[]>(items.slice(0, 14));
  const [hiddenItems, setHiddenItems] = useState<any[]>(items.slice(14));

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const measureTextWidth = (text: string): number => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (context) {
      context.font = window.getComputedStyle(document.body).getPropertyValue('font');
      return context.measureText(text).width;
    }
    return 0;
  };

  const buttonItems = displayedItems.map((item, index) => (
    <Button key={index} color={'secondary'} onClick={()=>{
      handleClose()
      if(item.url==='catalog') {
        const random = Math.random().toString(36)

        window.history.pushState({ menu: random }, "menu", "?menu="+random);
    
        return setModalProducts(true)
      };

      scrollTo({ key: item.url })
    }} sx={{whiteSpace: 'nowrap', color: 'primary.main',  }}>

      {item.name}

    </Button>
  ));

  const menuItems = hiddenItems.map((item, index) => (
    <MenuItem key={index} onClick={()=>{
      handleClose()
      if(item.url==='catalog') {

        const random = Math.random().toString(36)

        window.history.pushState({ menu: random }, "menu", "?menu="+random);
    
        return setModalProducts(true)
      };

      scrollTo({ key: item.url })
    }}>

      {item.name}

    </MenuItem>
  ));

  const [w, setW] = useState<number>(1240)

  useEffect(() => {
    const handleResize = () => {

      if (containerRef.current) {

        setW(containerRef.current.clientWidth - 40)
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  const [fixedMenu, setFixexMenu] = useState<boolean>(false)
  const fixedMenuStartPoint = useRef<HTMLDivElement>(null);

  const marginTopForFixedMenu = 10

  useEffect(() => {

    const handleScroll = () => {

      if (buttonGroupRef.current && fixedMenuStartPoint.current) {

        const scrollY = window.scrollY;

        if( scrollY + marginTopForFixedMenu >= fixedMenuStartPoint.current.offsetTop) {

          setFixexMenu(true)
        } else {

          setFixexMenu(false)
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
            <Container ref={containerRef} maxWidth="lg" sx={{ pt:0,  mb: 3, }}>

              <Box ref={fixedMenuStartPoint}/>

              <Box sx={{ position: 'relative', display: fixedMenu ? 'flex' : 'none', height: 50 }}/>

              <ButtonGroup
                variant="text"
                aria-label="outlined primary button group"
                ref={buttonGroupRef}
                style={{ overflow: 'hidden', maxWidth: w }}
                
                sx={{

                  position: fixedMenu ? 'fixed' : 'relative',

                  /* borderTopLeftRadius: fixedMenu ? '0 !important' : 'unset',
                  borderTopRightRadius: fixedMenu ? '0 !important' : 'unset', */

                  top: fixedMenu ? marginTopForFixedMenu : 'unset',
                  marginLeft: fixedMenu ? -0.5 : 'unset',
                  boxShadow: fixedMenu ? '0px 0px 6px 3px rgba(0,0,0,0.15)' : '0px 0px 6px 3px rgba(0,0,0,0.1)',

                  zIndex: 2,
                  backdropFilter:  "blur(5px)",

                  pl: 1,
                  width: 'calc(100% - 0px)',
                  border: 'unset',
                  borderRadius: 3.5,
                  bgcolor: 'primary.company',
                  color: 'primary.main',

                  flexDirection: 'row',
                  justifyContent: 'space-evenly',
                  alignItems: 'center',
                  
                  "&:hover": {
                    bgcolor: 'primary.company',
                  },
                  "&:active": {
                    bgcolor: 'primary.company',
                  },
                  '& .MuiButtonGroup-root': {
                    borderColor: 'unset !important',
                  },
                  '& .MuiButtonBase-root': {
                    whiteSpace: 'nowrap',
                    pt: 2,
                    pb: 1.5,

                    borderLeft: 'unset !important',
                    borderRight: 'unset !important',

                    transition: 'transform 0.5s',
                    '&:hover': {
                      transform: 'scale(1.025)',
                    },
                  },
                }}
              >
                {buttonItems}
                {hiddenItems.length > 0 && (
                  <Button aria-controls="simple-menu" sx={{ color: 'primary.main', }} aria-haspopup="true" onClick={handleClick}>
                    <MoreVertIcon />
                  </Button>
                )}
              </ButtonGroup>

              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {menuItems}
              </Menu>

            </Container>
  )
}

export default Menus


const fee = ({ type = 'plus', amount = 0, country, company, brand=null }: { type: string, amount: number, country: any, company: any, brand?: any }): number => {

  if (!amount || !country || !company || !Object.keys(company).length) {
    return 0;
  }

  let calculatedAmount = amount;
  
  let procent = 0
  let fixed = 0

  let lot_price = Number(calculatedAmount)

  if (company?.fee) {

    const fee = company.fee;

    for(const fee_country of fee?.fee_country){

      if(fee_country?.country?.id === country?.id){
  
        if(fee_country.procent && fee_country.procent > 0) procent = procent + fee_country.procent;
        
        if(fee_country.fixed && fee_country.fixed > 0) fixed = fixed + fee_country.fixed;
      }
    }
  
    for(const fee_brand of fee?.fee_brand){
      
      if(brand && fee_brand?.brand?.id === brand?.id){
  
        if(fee_brand.procent && fee_brand.procent > 0) procent = procent + fee_brand.procent;
        
        if(fee_brand.fixed && fee_brand.fixed > 0) fixed = fixed + fee_brand.fixed;
      }
    }
  
    for(const fee_price of fee?.fee_price){
  
      if( lot_price > fee_price.min && lot_price < fee_price.max){
  
        if(fee_price.procent && fee_price.procent > 0) procent = procent + fee_price.procent;
        
        if(fee_price.fixed && fee_price.fixed > 0) fixed = fixed + fee_price.fixed;
      }
    }
  
    lot_price = lot_price * ( 1 + procent / 100 );
    lot_price = lot_price + fixed;
    
    return lot_price;
  }

  return 0
};
export { fee };
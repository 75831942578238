
import { FC } from 'react'

import { useRecoilState } from 'recoil'
import { filtersStateAtom } from '@state/filters'

import Grid from '@mui/material/Grid'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'

import { NumericFormat } from 'react-number-format';

const isMobileDevice = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)

const options = [
/*   { value: 50000, name: "50000" },
  { value: 75000, name: "75000" }, */
  { value: 100000, name: "100000" },
  { value: 150000, name: "150000" },
  { value: 200000, name: "200000" },
  { value: 250000, name: "250000" },
  { value: 300000, name: "300000" },
  { value: 350000, name: "350000" },
  { value: 400000, name: "400000" },
  { value: 450000, name: "450000" },
  { value: 500000, name: "500000" },
  { value: 550000, name: "550000" },
  { value: 600000, name: "600000" },
  { value: 650000, name: "650000" },
  { value: 700000, name: "700000" },
  { value: 750000, name: "750000" },
  { value: 800000, name: "800000" },
  { value: 850000, name: "850000" },
  { value: 900000, name: "900000" },
  { value: 950000, name: "950000" },
  { value: 1000000, name: "1000000" },
  { value: 1050000, name: "1050000" },
  { value: 1100000, name: "1100000" },
  { value: 1150000, name: "1150000" },
  { value: 1200000, name: "1200000" },
  { value: 1250000, name: "1250000" },
  { value: 1300000, name: "1300000" },
  { value: 1350000, name: "1350000" },
  { value: 1400000, name: "1400000" },
  { value: 1450000, name: "1450000" },
  { value: 1500000, name: "1500000" },
  { value: 1550000, name: "1550000" },
  { value: 1600000, name: "1600000" },
  { value: 1650000, name: "1650000" },
  { value: 1700000, name: "1700000" },
  { value: 1750000, name: "1750000" },
  { value: 1800000, name: "1800000" },
  { value: 1850000, name: "1850000" },
  { value: 1900000, name: "1900000" },
  { value: 1950000, name: "1950000" },
  { value: 2000000, name: "2000000" },
  { value: 2050000, name: "2050000" },
  { value: 2100000, name: "2100000" },
  { value: 2150000, name: "2150000" },
  { value: 2200000, name: "2200000" },
  { value: 2250000, name: "2250000" },
  { value: 2300000, name: "2300000" },
  { value: 2350000, name: "2350000" },
  { value: 2400000, name: "2400000" },
  { value: 2450000, name: "2450000" },
  { value: 2500000, name: "2500000" },
  { value: 2550000, name: "2550000" },
  { value: 2600000, name: "2600000" },
  { value: 2650000, name: "2650000" },
  { value: 2700000, name: "2700000" },
  { value: 2750000, name: "2750000" },
  { value: 2800000, name: "2800000" },
  { value: 2850000, name: "2850000" },
  { value: 2900000, name: "2900000" },
  { value: 2950000, name: "2950000" },
  { value: 3000000, name: "3000000" },
  { value: 3050000, name: "3050000" },
  { value: 3100000, name: "3100000" },
  { value: 3150000, name: "3150000" },
  { value: 3200000, name: "3200000" },
  { value: 3250000, name: "3250000" },
  { value: 3300000, name: "3300000" },
  { value: 3350000, name: "3350000" },
  { value: 3400000, name: "3400000" },
  { value: 3450000, name: "3450000" },
  { value: 3500000, name: "3500000" },
  { value: 3550000, name: "3550000" },
  { value: 3600000, name: "3600000" },
  { value: 3650000, name: "3650000" },
  { value: 3700000, name: "3700000" },
  { value: 3750000, name: "3750000" },
  { value: 3800000, name: "3800000" },
  { value: 3850000, name: "3850000" },
  { value: 3900000, name: "3900000" },
  { value: 3950000, name: "3950000" },
  { value: 4000000, name: "4000000" },
  { value: 4050000, name: "4050000" },
  { value: 4100000, name: "4100000" },
  { value: 4150000, name: "4150000" },
  { value: 4200000, name: "4200000" },
  { value: 4250000, name: "4250000" },
  { value: 4300000, name: "4300000" },
  { value: 4350000, name: "4350000" },
  { value: 4400000, name: "4400000" },
  { value: 4450000, name: "4450000" },
  { value: 4500000, name: "4500000" },
  { value: 4550000, name: "4550000" },
  { value: 4600000, name: "4600000" },
  { value: 4650000, name: "4650000" },
  { value: 4700000, name: "4700000" },
  { value: 4750000, name: "4750000" },
  { value: 4800000, name: "4800000" },
  { value: 4850000, name: "4850000" },
  { value: 4900000, name: "4900000" },
  { value: 4950000, name: "4950000" },
  { value: 5000000, name: "5000000" },
  { value: 6000000, name: "6000000" },
  { value: 7000000, name: "7000000" },
  { value: 8000000, name: "8000000" },
  { value: 9000000, name: "9000000" },
  { value: 10000000, name: "10000000" },
];

const Price: FC = () => {

  const [filters, setFilters] = useRecoilState<any>(filtersStateAtom)

  const renderOption = (props:any, option:any) => (
    <li {...props}>
      <NumericFormat allowLeadingZeros thousandSeparator=" "  decimalScale={0} displayType="text" value={option.name} suffix={' ₽'} />
    </li>
  )

  const optionsReverse = [...options].reverse()

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item xs={6}>
        <Autocomplete
          freeSolo
          value={filters.price.min}
          onChange={(_, newValue) => {
            window?.navigator?.vibrate && window?.navigator?.vibrate(10);
            setFilters((prevState:any) => ({ ...prevState, price: { min: newValue, max: prevState.price.max } }))
          }}
          options={options}
          renderOption={renderOption}
          getOptionLabel={(option) => option.name.toString()}
          noOptionsText={'Нет донных'}
          isOptionEqualToValue={(option, value) => option.name === value.name}
          renderInput={(params) => (
            <TextField
              onClick={()=>{window?.navigator?.vibrate && window?.navigator?.vibrate(10);}}
              sx={{
                justifyContent: `center`,
                alignItems: `center`,
              }}
              {...params}
              label="Цена от, ₽"
              /* placeholder="Цена от, ₽" */
              variant="outlined"
              fullWidth

              inputProps={{
                ...params.inputProps,
                inputMode: isMobileDevice() ? 'numeric' : 'text', // Установка числового режима для клавиатуры на мобильных устройствах
              }}
              InputLabelProps={{sx: {  marginTop: -0.25 } }}

            /> 
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          freeSolo
          value={filters.price.max}
          onChange={(_, newValue) => {
            window?.navigator?.vibrate && window?.navigator?.vibrate(10);
            setFilters((prevState:any) => ({ ...prevState, price: { min: prevState.price.min, max:  newValue} }))
          }}
          options={optionsReverse}
          renderOption={renderOption}
          getOptionLabel={(option) => option.name.toString()}
          noOptionsText={'Нет донных'}
          isOptionEqualToValue={(option, value) => option.name === value.name}
          renderInput={(params) => (
            <TextField
              onClick={()=>{window?.navigator?.vibrate && window?.navigator?.vibrate(10);}}
              sx={{
                justifyContent: `center`,
                alignItems: `center`,
              }}
              {...params}
              label="Цена до, ₽"
              /* placeholder="Цена до, ₽" */
              variant="outlined"
              fullWidth
              inputProps={{
                ...params.inputProps,
                inputMode: isMobileDevice() ? 'numeric' : 'text', // Установка числового режима для клавиатуры на мобильных устройствах
              }}
              InputLabelProps={{sx: {  marginTop: -0.25 } }}

            />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default Price;

import { FC, useMemo } from 'react'

import { useRecoilValue } from 'recoil'
import { companyStateAtom } from '@state/company'

import Container from '@mui/material/Container'
import Grid from '@mui/material/Unstable_Grid2'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import parseDynamicComponent from '@utils/parseDynamicComponent'
import { useThemeMode } from '@hooks/useThemeMode'
import { useBotDetect } from '@hooks/useBotDetect'
import { useMobileDetect } from '@hooks/useMobileDetect'

import { replaceImageFormat } from '@utils/replaceImageFormat'

const  { REACT_APP_MINIO_URL_FROM, REACT_APP_MINIO_URL_TO } = process.env

const Advantages: FC<any> = () => {

  const company = useRecoilValue<any>(companyStateAtom)
  const bot = useBotDetect()
  const mobile =   useMobileDetect()
  const themeMode = useThemeMode()

  const advantages:any = useMemo(() => parseDynamicComponent(company?.advantages?.advantages),[company?.advantages?.advantages])

  const margin = 15

  return (
      <>

        <Container id={'advantages'} maxWidth="lg" sx={{pt: 0,  mt: { xs: bot ? 0 : 5, md: 5 }, pl: 0, pr: 0, 

        }}>

        { advantages?.header?.title &&

          <Typography variant="h6" gutterBottom align="center"> { advantages?.header?.title } </Typography>
        }

        { advantages?.header?.caption &&

          <Typography variant="caption" gutterBottom display="block" align="center"> { advantages?.header?.caption } </Typography>
        }

        { advantages?.header?.body &&
          
          <Typography variant="body2" dangerouslySetInnerHTML={{__html: advantages?.header?.body?.text}}/>
        }
        
        <Container
        sx={{
          overflowY: 'hidden',
          overflowX: 'auto',
          pt: 0,
          ...(mobile && {
            pl: 0, pr: 0,
          }),
        }}>

          <Box
          sx={{
            display: 'flex',
            flexWrap: bot  ? 'wrap' : mobile ? 'nowrap' : 'wrap',
            overflowX: 'auto', scrollPadding: '0 0 0 30px', scrollSnapType: 'x mandatory', '& > *': { scrollSnapAlign: 'start', }, 
            '& > *:first-of-type': {
              ...((mobile && !bot) && {

                marginLeft: `${margin}px !important`,
              }),
            },
            touchAction: 'manipulation',
          }}
          >

            {advantages?.data?.map((item:any) => (
              
              <Grid key={`advantages-${item.id}-${item?.name}`} container spacing={1} margin={0} marginTop={ bot ? 0 : mobile ? 0 : 3 }

              xs={12}//, extra-small: 0px
              sm={6}//, small: 600px
              md={3}//, medium: 900px
              lg={3}//, large: 1200px
              xl={3}//, extra-large: 1536px

              minWidth={250}

              >

                <Box boxShadow={2} borderRadius={2} margin={1} padding={1}>

                  <Grid container xs={12}>
                    { item?.image && 
                      <Grid xs={2} sx={{
                      }}>

                        <Box
                          component="img"
                          color="inherit"
                          sx={{
                            height: 50,
                            width: 50,
                            maxHeight: { xs: 50, md: 50 },
                            maxWidth: { xs: 50, md: 50 },
                            ...(themeMode === 'dark' && {
                              filter: 'brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%)' 
                            }),
                          }}
                          alt=""
                          src={ item?.image?.url && replaceImageFormat(item?.image?.url?.replace(REACT_APP_MINIO_URL_FROM, REACT_APP_MINIO_URL_TO)) }
                        />

                      </Grid>
                    }
                    <Grid xs={ item?.image ? 10 : 12}>
                      <Typography
                        variant="subtitle2"
                        dangerouslySetInnerHTML={{__html: item?.name}}
                        sx={{
                          display: 'flex',
                          direction: 'row',
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                          height: `50px`,

                          ...(item?.image === null && {
                            pt: 2,
                          }),
                          ...(item?.image !== null && {
                            pl: 3,
                          }),
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Grid xs={12}>
                    <Typography
                      variant="body2"
                      sx={{
                      '& p':{
                        padding: 0,
                        margin: 0,
                      }}}
                      dangerouslySetInnerHTML={{__html: item?.text}}
                    />
                  </Grid>

                </Box>
                
              </Grid>

            ))}

          </Box>
        </Container>

        { advantages?.footer?.title &&

          <Typography variant="h6" gutterBottom align="center"> { advantages?.footer?.title } </Typography>
        }

        { advantages?.footer?.caption &&

          <Typography variant="caption" gutterBottom display="block" align="center"> { advantages?.footer?.caption } </Typography>
        }

        { advantages?.footer?.body &&
          
          <Typography variant="body2" dangerouslySetInnerHTML={{__html: advantages?.footer?.body?.text}}/>
        }

        </Container>
            
      </>
  )
}

export default Advantages

import { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'

import { filtersStateAtom } from '@state/filters'

import {isArray, isObject, isString} from 'lodash'

function useCountsFilters() {

  const filters = useRecoilValue<any>(filtersStateAtom)

  const [counts, setCounts] = useState<number>(0)

  useEffect(() => {

    for (const filter in filters) {
    
      if (
        /* (filter === `brand` && filters[filter]) || 
        (filter === `model` && filters[filter]) ||  */
        (filter === `drive` && filters[filter]) || 
        (filter === `body` && filters[filter]) || 
        (filter === `fuel` && filters[filter]) || 
        (filter === `transmission` && filters[filter])
      ){ 
        setCounts((prevState) => prevState + 1)
  
      }else
      if (isArray(filters[filter]) && filter !== `countries` ) {
  
        for(const color of filters[filter]) setCounts((prevState) => prevState + 1);
  
      }else
      if (isObject(filters[filter])) {
  
        if(filters[filter]?.min) setCounts((prevState) => prevState + 1);
        if(filters[filter]?.max) setCounts((prevState) => prevState + 1);
  
      }else
      if(isString(filters[filter])){
  
      }
      
    }
    return () => {
      setCounts(0)
    }

  }, [filters])

  return counts
}

export default useCountsFilters;

export { useCountsFilters };


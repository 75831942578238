import { atom, selector } from "recoil"

export const callBackStateAtom = atom({
  key: "callBackStateAtom",
  default: false,
})

export const callBackStateSelector = selector({
  key: "callBackStateSelector",
  get: ({ get }) => {
    const callBack = get(callBackStateAtom)
    return callBack
  }
})


import { atom, selector } from "recoil"
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

export const productsStateAtom = atom({
  key: "productsStateAtom",
  default: [],
  effects_UNSTABLE: [persistAtom],
})

export const productsStateSelector = selector({
  key: "productsStateSelector",
  get: ({ get }) => {
    const products = get(productsStateAtom)
    return products
  }
})


import { FC, useState } from 'react'

import { useRecoilValue } from 'recoil'
import { companyStateAtom } from '@state/company'

import Grow from '@mui/material/Grow'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Link from '@mui/material/Link'

import Typography from '@mui/material/Typography'

import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Switch, switchClasses } from '@mui/base/Switch';

import { styled } from '@mui/material/styles'

import useUrlParams from '@hooks/useUrlParams'

const  { REACT_APP_VERSION } = process.env

const AboutApp: FC<any> = () => {

  const params = useUrlParams()

  const label = { slotProps: { input: { 'aria-label': 'switch' } } }

  const company = useRecoilValue<any>(companyStateAtom)

  const [dev, setDev] = useState<boolean>(false)

  return (
      <>

        <Container sx={{pt: 0}}>
          
          <Typography variant="h6" gutterBottom align="center"> О приложении </Typography>

          <Box sx={{m: 2}}justifyContent="center" alignItems="center" display='flex' flexWrap='wrap' flexDirection='column'>

            <Typography variant="subtitle1" gutterBottom  justifyContent="center" alignItems="center">

              Версия

            </Typography>

            <Typography
              variant="caption"
              sx={{ whiteSpace: "wrap", wordWrap: "break-word", }}
              justifyContent="center" alignItems="center"
              gutterBottom
            >
              
              {REACT_APP_VERSION} Beta

            </Typography>

          </Box>

          <Box sx={{m: 2,}} justifyContent="center" alignItems="center" display='flex' flexWrap='wrap' flexDirection='column'>

            <Typography variant="subtitle1" gutterBottom justifyContent="center" alignItems="center">

              Разработчик

            </Typography>

            <Typography
              variant="caption"
              sx={{ whiteSpace: "wrap", wordWrap: "break-word", textAlign: "center"  }}
              justifyContent="center" alignItems="center"
              gutterBottom
            >

              <Link onClick={()=>{
                window?.navigator?.vibrate && window?.navigator?.vibrate(10);
                window?.Telegram?.WebApp?.openLink("https://genikineg.ru")
              }} sx={{ width: `100%`, textAlign: `center`, mt: 5, color: 'text.primary' }}>

                Genikineg.ru 

              </Link>

              <Box sx={{ m: 1 }}/>

              <Link onClick={()=>{
                window?.navigator?.vibrate && window?.navigator?.vibrate(10);
                window?.Telegram?.WebApp?.openLink("tg://msg?text=Здравствуйте, меня интересует бот продаж автомобилей&to=+79502913351")
              }} sx={{ width: `100%`, textAlign: `center`, mt: 5, color: 'text.primary' }}>

                @genikineg

              </Link>

              <Box sx={{ m: 1 }}/>

              <Link onClick={()=>{
                window?.navigator?.vibrate && window?.navigator?.vibrate(10);
                window?.Telegram?.WebApp?.openLink("https://genikineg.ru/contacts-card")
              }} sx={{ width: `100%`, textAlign: `center`, mt: 5,  color: 'text.primary' }}>

                +7 950-291-33-51

              </Link>
            </Typography>

          </Box>

          <Box sx={{ml: 2, mr: 2, mb: 2}}>

            <FormControl component="fieldset">

              <FormGroup>
                  
                <FormControlLabel
                  control={<Switch checked={dev} slots={{
                    root: Root,
                  }}
                  {...label}
                  onChange={(event: any)=>{
                    window?.navigator?.vibrate && window?.navigator?.vibrate(10);
                    setDev(event.target.checked)
                  }} />}
                  label={
                    <Typography
                      variant="body2"
                      sx={{ whiteSpace: "wrap", wordWrap: "break-word", width: 'calc(100vw - 120px)' }}
                      gutterBottom
                    >
                      
                      Показать отладочную информацию

                    </Typography>
                  }
                />

              </FormGroup>
            </FormControl>

          </Box>

          <Grow in={dev || false} timeout={700} unmountOnExit>

            <Box>

              <Box sx={{m: 2}}>
                      
                <Typography
                  variant="caption"
                  sx={{ whiteSpace: "wrap", wordWrap: "break-word", width: 'calc(100vw - 120px)' }}
                  gutterBottom
                >
                  use telegram scheme: {window?.Telegram?.WebApp?.colorScheme}

                </Typography>

              </Box>

            <Box sx={{m: 2}}>

                { params && params?.tgWebAppStartParam &&
                <>
                  {params?.tgWebAppStartParam.split('#')[0]}
                  {params?.tgWebAppStartParam.replace('#tgWebAppData', '')}
                </>
                }

                <Typography variant="subtitle1" gutterBottom onClick={()=>{
                      window.navigator.clipboard.writeText(JSON.stringify(window?.Telegram?.WebApp?.initData))
                      .then(() => {
                      })
                      .catch((error) => {});
                }}>

                  Данные пользователя (telegram)
            
                </Typography>

                <Typography
                  variant="caption"
                  sx={{ whiteSpace: "wrap", wordWrap: "break-word", width: 'calc(100vw - 120px)' }}
                  gutterBottom
                >
                  {JSON.stringify(window?.Telegram?.WebApp?.initData)}
                </Typography>

              </Box>

              <Box sx={{m: 2}}>

                <Typography variant="subtitle1" gutterBottom>

                  Данные компании

                </Typography>

                <Typography
                  variant="caption"
                  sx={{ whiteSpace: "wrap", wordWrap: "break-word", width: 'calc(100vw - 120px)' }}
                  gutterBottom
                >
                  {JSON.stringify(company)}
                </Typography>

              </Box>
            
            </Box>

          </Grow>

        </Container>

      </>
  )
}

export default AboutApp


const blue = {
  200: '#99CCF3',
  500: '#007FFF',
  700: '#0059B2',
};

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};

const Root = styled('span')(
  ({ theme }) => `
  box-sizing: border-box;
  font-size: 0;
  position: relative;
  display: inline-block;
  width: 38px;
  height: 24px;
  margin: 10px;
  cursor: pointer;

  &.${switchClasses.disabled} {
    opacity: 0.4;
    cursor: not-allowed;
  }

  & .${switchClasses.track} {
    box-sizing: border-box;
    background: ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
    border-radius: 24px;
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 120ms;
    box-shadow: inset 0px 1px 1px ${
      theme.palette.mode === 'dark' ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0.05)'
    };
  }

  &:hover .${switchClasses.track} {
    background: ${theme.palette.mode === 'dark' ? grey[800] : grey[100]};
    border-color: ${theme.palette.mode === 'dark' ? grey[600] : grey[300]};
  }

  &.${switchClasses.focusVisible} .${switchClasses.track} {
    box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[700] : blue[200]};
  }

  & .${switchClasses.thumb} {
    box-sizing: border-box;
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
    display: block;
    width: 16px;
    height: 16px;
    top: 4px;
    left: 4px;
    border-radius: 16px;
    background-color: #FFF;
    position: relative;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 120ms;
    box-shadow: 0px 1px 2px ${
      theme.palette.mode === 'dark' ? 'rgba(0, 0, 0, 0.25)' : 'rgba(0, 0, 0, 0.1)'
    };
  }

  &.${switchClasses.checked} {
    .${switchClasses.thumb} {
      left: 18px;
      background-color: #fff;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
    }

    .${switchClasses.track} {
      border: none;
      background: ${blue[500]};
    }
  }

  &:hover .${switchClasses.checked} .${switchClasses.track} {
    background: ${blue[700]};
  }

  & .${switchClasses.input} {
    cursor: inherit;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 1;
    margin: 0;
  }
  `,
);

import { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'

import { filtersStateAtom } from '@state/filters'
import { subscriptionsStateAtom } from '@state/subscriptions'

function useCheckingBrandModelSubscription() {

  const subscriptions = useRecoilValue<any>(subscriptionsStateAtom)

  const filters = useRecoilValue<any>(filtersStateAtom)

  const [ allow, setAllow ] = useState<boolean>(true)

  useEffect(() => {

    for (const subscription of subscriptions) {
    
      if ( filters?.brand?.id === subscription?.brand?.id && filters?.model?.id === subscription?.model?.id ) setAllow(false);
    }
    return () => {
      setAllow(true)
    }

  }, [filters, subscriptions])

  return allow
}

export default useCheckingBrandModelSubscription

export { useCheckingBrandModelSubscription }


const replaceImageFormat = (imageUrl: string) => {
  if (!imageUrl) {
    return '';
  }
  // Регулярное выражение для поиска форматов изображений в URL
  const regex = /\.(jpeg|jpg|png|gif|bmp)/gi;
  // Замена форматов изображений на .webp
  return imageUrl.replace(regex, '.webp');
};


export default replaceImageFormat
export { replaceImageFormat }

import { atom, selector } from "recoil"
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

export const filtersShowStateAtom = atom({
  key: "filtersShowStateAtom",
  default: false,
  effects_UNSTABLE: [persistAtom],

})

export const filtersShowStateSelector = selector({
  key: "filtersShowStateSelector",
  get: ({ get }) => {
    const filtersShow = get(filtersShowStateAtom)
    return filtersShow
  }
})


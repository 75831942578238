
import { FC, useEffect } from 'react'

import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil'

import { companyStateAtom } from '@state/company'

import { filtersStateAtom } from '@state/filters'
import { filtersDataStateAtom } from '@state/filtersData'

import { brandsStateAtom } from '@state/brands'
import { modelsStateAtom } from '@state/models'

import Box from '@mui/material/Box'

import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'

import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  customCursor: {
    cursor: 'pointer',
  },
})

const Brands: FC = () => {

  const classes = useStyles()

  const company = useRecoilValue<any>(companyStateAtom)

  const [filters, setFilters] = useRecoilState<any>(filtersStateAtom)

  const filtersData = useRecoilValue<any>(filtersDataStateAtom)
  const brands = useRecoilValue<any>(brandsStateAtom)
  const setModels = useSetRecoilState<any>(modelsStateAtom)

   const checkedNotNullItem = (item: any, company: any) => {

    let count = 0

    for(const country of company?.countries){

      if(country.value === 'Korea'){
        count = count + item?.count_korea
      }else
      if(country.value === 'China'){
        count = count + item?.count_china
      }else
      if(country.value === 'Japan'){
        count = count + item?.count_japan
      }else
      if(country.value === 'UAE'){
        count = count + item?.count_uae
      }else
      if(country.value === 'USA'){
        count = count + item?.count_usa
      }
    }

    return count
  }

  const renderOption = (props:any, option:any) => {

    const count = checkedNotNullItem(option, company)

    return <li {...props}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'nowrap', width: '100%', opacity: count > 0 ? 1 : 0.5}}>
        <Box>
          {option.name} 
        </Box>
        {/* <Box>
          {count} шт
        </Box> */}
      </Box>
    </li>
  }

  useEffect(()=>{}, [filters, filtersData])

  return (
    <Autocomplete
      value={filters.brand}
      onChange={(event, newValue) => {
        window?.navigator?.vibrate && window?.navigator?.vibrate(10);
        setModels([])
        setFilters((prevState:any) => ({ ...prevState, brand: newValue, model: null }));
      }}
      options={brands}
      getOptionLabel={(option) => option.name}
      noOptionsText={'Нету донных'}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderOption={renderOption}
      renderInput={(params) => (
        <TextField
          onClick={()=>{window?.navigator?.vibrate && window?.navigator?.vibrate(10);}}
          sx={{
            justifyContent: `center`,
            alignItems: `center`,
            '& .MuiInputBase-root': {
              cursor: 'pointer', 
            },
            '& .MuiInputBase-input': {
              cursor: 'pointer', 
            },
          }}
          {...params}
          label="Бренд"
          /* placeholder={filters.brand ? '' : 'Бренд'} */
          variant="outlined"
          fullWidth
          value={'filters.brand?.name'}
          inputProps={{
            ...params.inputProps,

            className:  classes.customCursor, // Применяем пользовательский класс CSS при не выбранной опции
          }}
          InputLabelProps={{sx: {  marginTop: -0.25 } }}

        />
      )}
    />
  )
}

export default Brands;

import { atom, selector } from "recoil"

export const modalProductsStateAtom = atom({
  key: "modalProductsStateAtom",
  default: false,
})

export const modalProductsStateSelector = selector({
  key: "modalProductsStateSelector",
  get: ({ get }) => {
    const modalProducts = get(modalProductsStateAtom)
    return modalProducts
  }
})


import { atom, selector } from "recoil"
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

export const openSearchFormBrandStateAtom = atom({
  key: "openSearchFormBrandStateAtom",
  default: false,
  effects_UNSTABLE: [persistAtom],
})

export const openSearchFormBrandStateSelector = selector({
  key: "openSearchFormBrandStateSelector",
  get: ({ get }) => {
    const openSearchFormBrand = get(openSearchFormBrandStateAtom)
    return openSearchFormBrand
  }
})


import { FC } from 'react'

import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil'
import { productStateAtom } from '@state/product'
import { callBackButtonStateAtom } from '@state/callBackButton'
import { callBackStateAtom } from '@state/callBack'
import { companyStateAtom } from '@state/company'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Fade from '@mui/material/Fade'

import NotesIcon from '@mui/icons-material/Notes'

import useMediaQuery from '@mui/material/useMediaQuery'

const CallBackButton: FC = () => {

  const company = useRecoilValue<any>(companyStateAtom)

  const [product, setProduct] = useRecoilState<any>(productStateAtom)
  const setCallBackButton = useSetRecoilState<boolean>(callBackButtonStateAtom)

  const setCallback = useSetRecoilState<any>(callBackStateAtom)
  
  const callBackButton = useRecoilValue<boolean>(callBackButtonStateAtom)

  const matchesUseMediaQuery = useMediaQuery('(max-width:420px)')

  return (
    <Fade in={(callBackButton && matchesUseMediaQuery) || false} timeout={500} unmountOnExit>

      <Box sx={{
        position: 'fixed',
        top: window?.Telegram?.WebApp?.viewportHeight ? window?.Telegram?.WebApp?.viewportHeight - 65 : 0,
        left: 0,
        width: '100%',
        zIndex: 1202,
      }}>

        <Button variant="contained" color={'secondary'} fullWidth onClick={()=>{

          window?.navigator?.vibrate && window?.navigator?.vibrate(10);
          setCallback(true)
          setProduct(null)
          setCallBackButton(false)
        }}
        sx={{
          //backdropFilter: "blur(6px)", // лагает на моб.

          left: '50%',
          transform: 'translateX(-50%)', 

          width: 'calc(100% - 35px)',
          maxWidth:  !matchesUseMediaQuery ? 400 : 'unset',

          bgcolor: 'primary.company',
          color: 'primary.main',

          boxShadow: 'none',

          "&:hover": {
            bgcolor: 'primary.company',
            opacity: 0.7,
          },
          "&:active": {
            bgcolor: 'primary.company',
            opacity: 0.7,
          },
        }}
        endIcon={<NotesIcon/>}>
          
          Оставить заявку

        </Button>

      </Box>
    </Fade>
  )
}

export default CallBackButton

import { useMemo } from 'react'
import { useRecoilValue } from 'recoil'
import { darkModeStateAtom } from '@state/darkMode'

import { getTheme } from '@utils/getTheme'

function useThemeMode() {

  const darkMode = useRecoilValue<any>(darkModeStateAtom)

  const theme = useMemo(() => getTheme(), [])

  const themeMode = darkMode.value === 'system' ? theme : darkMode.value === 'dark' ? `dark` : `light`

  return themeMode
}

export default useThemeMode;

export { useThemeMode };


import { FC, useEffect, useState } from 'react'

import { NumericFormat } from 'react-number-format'

import AnimateHeight from 'react-animate-height'

import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

import Grid from '@mui/material/Unstable_Grid2'
import CropSquareIcon from '@mui/icons-material/CropSquare';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';

import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined'
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined'

import { useRecoilState, useSetRecoilState } from 'recoil'
import { productStateAtom } from '@state/product'
import { bookmarksStateAtom } from '@state/bookmarks'

import {milesToKilometers} from '@utils/milesToKilometers'

const Bookmarks: FC = () => {
  
  const [bookmarks, setBookmarks] = useRecoilState<any>(bookmarksStateAtom)

  const setProduct = useSetRecoilState<any>(productStateAtom)

  const [open, setOpen] = useState<number>(-1)

  useEffect(() => {
    
  }, [bookmarks])

  const handleDelete = (id: number) => {
      
    const isPresent = bookmarks.some((item: any) => item.id === id );

    if (isPresent) {

      const updatedArray = bookmarks.filter((item: any) => !(item.id === id));

      setBookmarks(updatedArray);
    }
  }

  return (
    <>
      <Container sx={{pt: 0}}>
          
        <Typography variant="h6" gutterBottom align="center"> Избранное </Typography>
  
      <Box>

        {bookmarks.length === 0 &&
          
          <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection={'column'}
          mt={'calc(50vh - 100px)'}>

            <Typography variant="body1" display={'flex'} flexDirection={'row'} gutterBottom sx={{ mt: 0, ml: -0.9, textAlign: 'center'}}>
              Тут, к сожалению, пусто :(
            </Typography>

            <Typography variant="caption" display={'flex'} flexDirection={'row'} alignItems={'center'} gutterBottom sx={{ mt: 0, ml: -0.9, transform: 'scale(1.1)', textAlign: 'center'}}>
                Но, Вы всегда можете добавить авто в избранное
            </Typography>

          </Box>  
        }

        {bookmarks?.map((item:any, index:number) => (
          
          <Box key={`${item?.id}-${item?.brand?.name}-${item?.model?.name}`} boxShadow={2} borderRadius={2} marginTop={3} padding={1}>
            
            <Box onClick={() =>{

              if(open===item.id) return setOpen(-1);

              setOpen(item.id)

            }}
            style={{
              display: 'flex',
              flexFlow: 'row-reverse',
              alignContent: 'space-between',
              justifyContent: 'space-between',
              marginTop: 10,
              marginBottom: open === item.id ? 10 : 0,
            }}
            >

              <Box>

                <KeyboardArrowDownOutlinedIcon fontSize="large" style={{transform: open === item.id ? 'rotate(-180deg)':'unset', transition: '550ms'}}/>
              
              </Box>

              <Box>

                <Box display={'flex'} flexDirection={'column'}>

                  <Box>{item?.brand?.name} {item?.model?.name}</Box>

                    <Typography variant="caption" display={'flex'} flexDirection={'row'} gutterBottom sx={{ mt: -0.5, ml: -0.9, transform: 'scale(0.9)'}}>


                    <Box sx={{ m: 0, mr: 2 }}>{ item?.year } год</Box>
                    
                    <Box sx={{ mt: -0.3, mr: 2 }}>{milesToKilometers(item?.mileage)}<Typography variant="overline" fontSize={10}>км</Typography></Box>
                    
                    <Box sx={{ mr: 2 }}><NumericFormat allowLeadingZeros thousandSeparator=" "  decimalScale={0} displayType="text" value={item?.price} suffix={'₽'} /></Box>

                  </Typography>

              </Box>

              <AnimateHeight
                duration={500}
                height={open === item.id ? 'auto':0}
              >
  {/* 
  id	41820
  price	1532258
  mileage	40959
  engine	null
  engine_capacity	1968
  status	"open"
  hp	0
  kW	0
  rank	null
  grade	null
  createdAt	"2023-12-15T07:28:37.089Z"
  updatedAt	"2023-12-15T07:28:37.089Z"
  price_original	21990000
  currency_key	"KRW"
  year	"2021"
  date_of_issue	"2021-04-30T14:00:00.000Z"
  url_or_id	"36501106"
  date_of_auction	null
  photo	[ {…} ]
  country	
  city	
  currency	Object { id: 1, Symbol: "₩", name: "Южнокорейская вона", … }
  id	1
  Symbol	"₩"
  name	"Южнокорейская вона"
  key	"KRW"
  rank	null
  createdAt	"2023-10-05T09:02:35.964Z"
  updatedAt	"2023-10-29T08:37:20.556Z"
  rate	14.54556
  locale	"ru"
  transmission	
  drive	null
  fuel	
  body	null
  color	
  brand	Object { id: 21, name: "Volkswagen", active: true, … }
  model	Object { id: 4650, active: true, name: "Taro", … }
  photos	Object { id: 41727, createdAt: "2023-12-15T07:28:37.280Z", updatedAt: "2023-12-15T07:28:37.280Z", … }
  */}
                <Box sx={{ flexGrow: 1, mt: 2 }}>

                  <Grid container spacing={2}>
                    <Grid xs={12}>
                    
                      Страна экспортер

                    </Grid>

                    <Grid xs={12} sx={{ mt: -2.5 }}>

                      {item?.country?.name}

                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>

                    <Grid xs={12}>

                      Цена

                    </Grid>

                    <Grid xs={12} sx={{ mt: -2.5 }}>

                      <NumericFormat allowLeadingZeros thousandSeparator=" "  decimalScale={0} displayType="text" value={item?.price} suffix={'₽'} />

                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>

                    <Grid xs={12}>

                      Год  

                    </Grid>

                    <Grid xs={12} sx={{ mt: -2.5 }}>

                      { item?.year }

                    </Grid>

                  </Grid>

                  <Grid container spacing={2}>

                    <Grid xs={12}>

                      пробег

                    </Grid>

                    <Grid xs={12} sx={{ mt: -2.5 }}>
                      
                      <>{milesToKilometers(item?.mileage)}<Typography variant="overline" fontSize={10}>км</Typography></>

                    </Grid>

                  </Grid>

                  <Grid container spacing={2}>
                    <Grid xs={12}>
                    
                      Цвет

                    </Grid>

                    <Grid xs={12} sx={{ mt: -2.5 }}>

                      {item?.color?.name }

                    </Grid>

                  </Grid>

                  
                  <Grid container spacing={2}>

                    <Grid xs={6}>

                      <Button
                      variant="contained"
                      sx={{
                        mt: 1,
                        height: '100%',
                        width: 120,
                        p: 1,
                        fontSize: 10,
                        backdropFilter: "blur(2px)",
                        "& .MuiButton-startIcon": { marginRight: "2px" },
                        bgcolor: 'primary.company',
                        "&:hover": {
                          bgcolor: 'primary.company',
                          opacity: 0.9,
                        },
                        "&:active": {
                          bgcolor: 'primary.company',
                          opacity: 0.9,
                        },
                      }}
                      startIcon={<CropSquareIcon />}
                      onClick={()=>{
                        
                        window?.navigator?.vibrate && window?.navigator?.vibrate(10);
                        setProduct(null);

                        setTimeout(() => {

                          setProduct(item);
                        }, 1000)
                      }}>

                        Открыть

                      </Button>

                    </Grid>

                    <Grid xs={6}>
                  
                      <Button
                      variant="contained"
                      sx={{
                        mt: 1,
                        height: '100%',
                        width: 120,
                        p: 1,
                        fontSize: 10,
                        backdropFilter: "blur(2px)",
                        "& .MuiButton-startIcon": { marginRight: "2px" },
                        bgcolor: 'rgba(0,0,0, 0.5)',
                        "&:hover": {
                          bgcolor: 'rgba(0,0,0, 0.5)',
                          opacity: 0.9,
                        },
                        "&:active": {
                          bgcolor: 'rgba(0,0,0, 0.5)',
                          opacity: 1,
                        },
                      }}
                      startIcon={<DeleteForeverOutlinedIcon />}
                      onClick={()=>{window?.navigator?.vibrate && window?.navigator?.vibrate(10);handleDelete(item.id)}}>

                        Удалить

                      </Button>

                    </Grid>

                  </Grid>

                </Box>

              </AnimateHeight>

            </Box>

          </Box>

        </Box>
        
      ))}

      </Box>


      </Container>
    </>
  )
}


export default Bookmarks

export { Bookmarks }


import { atom, selector } from "recoil"
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

export const modelStateAtom = atom({
  key: "modelStateAtom",
  default: null,
  effects_UNSTABLE: [persistAtom],
})

export const modelStateSelector = selector({
  key: "modelStateSelector",
  get: ({ get }) => {
    const model = get(modelStateAtom)
    return model
  }
})


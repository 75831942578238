import { FC, useEffect, useState } from 'react'

import axios from 'axios'
import qs from 'qs'

import { NumericFormat } from 'react-number-format'

import AnimateHeight from 'react-animate-height'

import CircularProgress from '@mui/material/CircularProgress'

import Container from '@mui/material/Container'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';

import Checkbox from '@mui/material/Checkbox';

import Grow from '@mui/material/Grow';
import Slide from '@mui/material/Slide';

import Grid from '@mui/material/Unstable_Grid2'

import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'

import useBotDetect from '@hooks/useBotDetect'
import useMobileDetect from '@hooks/useMobileDetect'

import { makeStyles } from '@mui/styles'

import api from '@services/api'

const isMobileDevice = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)

const useStyles = makeStyles({
  customCursor: {
    cursor: 'pointer',
  },
})

const Calculators: FC = () => {
  
  const bot = useBotDetect()
  const mobile = useMobileDetect()

  const classes = useStyles()
  
  const [tax, setTax] = useState<any>(null)
  
  const [loading, setLoading] = useState<boolean>(false)
  
  const [showResult, setShowResult] = useState<boolean>(false)

  const [currency, setCurrency] = useState<any>()
  const [currencies, setCurrencies] = useState<any>([])

  const [fuel, setFuel] = useState<any>()
  const [fuels, setFuels] = useState<any>([])

  const [under3YearsOld, setUnder3YearsOld] = useState<boolean>(false)
  
  const [price, setPrice] = useState<number|null>(null)
  const [year, setYear] = useState<number|null>(null)
  const [volume, setVolume] = useState<number|null>(null)
  const [power, setPower] = useState<number|null>(null)
  
  useEffect(() => {
  
    if( !currency || !price || !year ) {

      if(!under3YearsOld) return setShowResult(false);
    }

    const len = year?.toString()?.length || 0

    if(year && len !== 4 && !under3YearsOld) return setShowResult(false);

    const checkCarAge = ({year=2000}) => {

      try {

        const currentYear = new Date().getFullYear()
        const carAge = currentYear - year
      
        if (carAge < 3) {

          //console.log( "Меньше 3-х лет" )
          return 2
        }
        if (carAge >= 3 && carAge <= 5) {

          //console.log( "3-5 лет" )
          return 4
        }
        if (carAge > 5 && carAge <= 7) {

          //console.log( ("5-7 лет") )
          return 6
        }

        //console.log("Больше 7 лет" )
        return 8
      } catch (error: any) {
        
        //console.error(error.message)
        return 8
      }
    }



    function calculateElectroCarTax(cost:any, engineVolume:any, age:any) {

      return {}
    }


    /* 
    https://www.tks.ru/auto/calc/
    https://vinculum.ru/oformlenie/tamozhennoe-oformlenie-vo-vladivostoke/tamozhennoe-oformlenie-avtomobilej
    https://www.drom.ru/customs/cartable.php

    электро тут
    https://avtodonor-25.ru/
    https://ucsol.ru/rastamozhka/avto/elektro

    */
    function calculateCarTax(cost:any, engineVolume:any, age:any, volume:any) {

      let taxRate = 0;
      let customsClearance = 0;
      let recyclingFee = 5200
      let taxAmount = 0

      if (age <= 3) {

        recyclingFee = 3400

        if (cost <= 8500) {

          taxRate = 2.5;
          customsClearance =  3100 // RUB

        } else if (cost <= 16700) {

          taxRate = 3.5;
          customsClearance =  5000

        } else if (cost <= 27000) {

          taxRate = 5.5;
          customsClearance =  8530

        } else if (cost <= 42300) {

          taxRate = 5.5;
          customsClearance =  12000

        } else if (cost <= 84500) {

          taxRate = 7.5;
          customsClearance =  23000

        } else if (cost <= 169000) {

          taxRate = 15.0;
          customsClearance =  25000

        } else {

          taxRate = 20.0;
          customsClearance =  30000

        }

        if(cost < 8500)
        {
          taxAmount = (cost/100*54) > (engineVolume * taxRate) ? (cost/100*54) : (engineVolume * taxRate);

        }else{

          taxAmount = (cost/100*48) > (engineVolume * taxRate) ? (cost/100*48) : (engineVolume * taxRate);
        }

      } else if (age <= 5) {

        if (engineVolume <= 1000) {

          taxRate = 1.5;
          customsClearance =  3100 // RUB
        } else if (engineVolume <= 1500) {

          taxRate = 1.7;
          customsClearance =  5000
        } else if (engineVolume <= 1800) {

          taxRate = 2.5;
          customsClearance =  12000
        } else if (engineVolume <= 2300) {

          taxRate = 2.7;
          customsClearance =  23001
        } else if (engineVolume <= 3000) {

          taxRate = 3;
          customsClearance =  25000
        } else {

          taxRate = 3.6;
          customsClearance =  30000
        }

        taxAmount = engineVolume * taxRate;

      } else {
        if (engineVolume <= 1000) {

          taxRate = 3;
          customsClearance =  3100 // RUB
        } else if (engineVolume <= 1500) {

          taxRate = 3.2;
          customsClearance =  5000
        } else if (engineVolume <= 1800) {

          taxRate = 3.5;
          customsClearance =  12000
        } else if (engineVolume <= 2300) {

          taxRate = 4.8;
          customsClearance =  23000
        } else if (engineVolume <= 3000) {

          taxRate = 5;
          customsClearance =  25000
        } else {

          taxRate = 5.7;
          customsClearance =  30000
        }
      }
    
      //48%, но не менее €5.5 / см3

      console.log(`engineVolume * taxRate`)
      console.log(engineVolume * taxRate)

      console.log((engineVolume * taxRate) / volume)


      return {tax: taxAmount, rate: taxRate,  customsClearance, recyclingFee};
    }

    const rubPrice:any = Price({exchange: currency?.key, direction: 'sell', value: price || 0 }) // key  to RUB,
    console.log(`rubPrice`)
    console.log(rubPrice)
    
    let eurPrice:any = Price({exchange:'EUR', direction:'buy', value: rubPrice }) // RUB to EUR
    /* console.log(`eurPrice`)
    console.log(eurPrice) */

    if(currency?.key === 'EUR'){
      eurPrice = price
    }
    
    const  calculateExcise = (volume:number, power:number):number => {

      if (power <= 67.5) {
        return 0;
      } else if (power <= 112.5) {
        return (55 * 0.75) * power;
      } else if (power <= 150) {
        return (531 * 0.75) * power;
      } else if (power <= 225) {
        return (869 * 0.75) * power;
      } else if (power <= 300) {
        return (1482 * 0.75) * power;
      } else if (power <= 375) {
        return (1534 * 0.75) * power;
      } else {
        return (1584 * 0.75) * power;
      }
    }

    const carAgeCategory = checkCarAge({year: year ? year : new Date().getFullYear()});
    const carAge = under3YearsOld ? 2 : carAgeCategory; // Возраст автомобиля в годах

    let carTax:any = calculateCarTax(eurPrice, volume, carAge, volume);

    const finalPrice = Number(convertTo({exchange:'EUR', direction:'sell', value:carTax?.tax }).toFixed(2)) || 0

    carTax = {
      ...carTax, 
      price: rubPrice,
      tax: finalPrice,
      duty: 0, // Пошлина
      excise: 0, // Акциз
      VAT: 0, // НДС
      taxAndCustomsClearance: finalPrice + carTax?.customsClearance,
    }
    
    if(fuel?.value === 'Electro'){

      const duty = (rubPrice * 0.15)
      const excise = 0
      const VAT = 0

      carTax = {
        ...carTax, 
        price: rubPrice,
        tax: 0,
        duty, // Пошлина
        excise, // Акциз
        VAT, // НДС
        taxAndCustomsClearance: finalPrice + carTax?.customsClearance,
      }

      if(power && power > 67 && power > 0){
        
        const excise= calculateExcise(power, power) // Акциз

        const VAT = ((rubPrice + excise + duty)* 0.2)

        carTax = {
          ...carTax, 
          excise,
          VAT,
        }
      }
    }
    
    /* console.log(`carTax`)
    console.log(carTax) */

    setTax(carTax)
    
    setShowResult(true)

  }, [currency, price, year, volume, power, fuel, under3YearsOld])
  
  const handleChangeUnder3YearsOld = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUnder3YearsOld(event.target.checked);
  };

    
  const convertTo = ({exchange='CNY', direction='sell'/* buy, sell */, value=1 }:any):number => {

    if(direction==='sell'){

      for(const currencys of currencies){

        if(currencys.key===exchange) return value / currencys.rate;
      }

      return 0
    }else{

      for(const currencys of currencies){
        if(currencys.key===exchange) return value * currencys.rate;
      }

      return 0
    }
  }

  const Price = ({exchange='CNY', direction='sell', value=1 }) => {

    try {

      if(direction==='buy')return (Number(convertTo({exchange, direction, value }).toFixed(2)));
      
      return (Number(convertTo({exchange, direction, value }).toFixed(2)))
    } catch (error:any) {
      
      console.error(error.message)
      return(((value)))
    }
  }

  useEffect(() => {


      
    const query = qs.stringify({
      random: false,
      randomSort: false,
      domain: window.location.hostname,
    }, {
      encodeValuesOnly: true,
    })

    setLoading(true);

    api({  collection: 'currencies', query}).then((data) => {

      if(data){
        
        setCurrencies(data?.data)

        api({  collection: 'fuels', query}).then((data) => {

          if(data) setFuels(data?.data);

          setLoading(false);
    
        }).catch((error:any) => {
    
          console.log(error)
          setLoading(false)
        })
      }

    }).catch((error:any) => {

      console.log(error)
      setLoading(false)
    })
  }, [])

  return (
    <>

      {  loading &&

        <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection={'column'}
        height={'100%'}
        >

          <CircularProgress color="inherit" />

          <Typography variant="subtitle2" gutterBottom  align="center" sx={{ mt: 2.5 , ml: 5, mr: 5}}>

            Загружаем данные...

          </Typography>

        </Box>
      }

      { !loading && currencies && currencies.length > 0 ? 
        
        <Box
        id={'calculators'}

        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection={'column'}
        >

          { !mobile && <Typography variant="h6" gutterBottom align="center"> Калькулятор </Typography> }
          
          <Grow in={showResult || false} mountOnEnter unmountOnExit>
            
            <Box sx={{ml: -2.5, p: 0, pb: 2, textAlign: 'left', width: '100%'}} display={'flex'} flexDirection={'column'} alignItems={'left'}>

              <Typography variant="body2" display={'flex'} flexDirection={'row'} gutterBottom sx={{ mt: 1, textAlign: 'left', transform: 'scale(0.9)'}}>

                Таможенное оформление:

                <NumericFormat allowLeadingZeros thousandSeparator=" "  decimalScale={0} displayType="text" value={tax?.customsClearance} suffix={'руб.'} />

              </Typography>

              <Typography variant="body2" display={'flex'} flexDirection={'row'} gutterBottom sx={{ mt: 0, textAlign: 'left', transform: 'scale(0.9)'}}>

                Утилизационный сбор:

                <NumericFormat allowLeadingZeros thousandSeparator=" "  decimalScale={0} displayType="text" value={tax?.recyclingFee} suffix={'руб.'} />

              </Typography>


              { fuel && fuel?.value !== 'Electro' &&
              <>
                <Typography variant="body2" display={'flex'} flexDirection={'row'} gutterBottom sx={{ mt: 0, textAlign: 'left', transform: 'scale(0.9)'}}>

                  Единая ставка: {tax?.rate} евро/см3 

                </Typography>

                <Typography variant="body2" display={'flex'} flexDirection={'row'} gutterBottom sx={{ mt: 0, textAlign: 'left', transform: 'scale(0.9)'}}>

                  Таможенные платежи: 

                  <NumericFormat allowLeadingZeros thousandSeparator=" "  decimalScale={0} displayType="text" value={(tax?.taxAndCustomsClearance)} suffix={'руб.'} />

                </Typography>
              </>
              }


              { fuel && fuel?.value === 'Electro' &&
              <>
                <Typography variant="body2" display={'flex'} flexDirection={'row'} gutterBottom sx={{ mt: 0, textAlign: 'left', transform: 'scale(0.9)'}}>

                Пошлина: 

                <NumericFormat allowLeadingZeros thousandSeparator=" "  decimalScale={0} displayType="text" value={(tax?.duty)} suffix={'руб.'} />

                </Typography>
                <Typography variant="body2" display={'flex'} flexDirection={'row'} gutterBottom sx={{ mt: 0, textAlign: 'left', transform: 'scale(0.9)'}}>

                Акциз: 

                <NumericFormat allowLeadingZeros thousandSeparator=" "  decimalScale={0} displayType="text" value={(tax?.excise)} suffix={'руб.'} />

                </Typography>
                <Typography variant="body2" display={'flex'} flexDirection={'row'} gutterBottom sx={{ mt: 0, textAlign: 'left', transform: 'scale(0.9)'}}>

                НДС: 

                <NumericFormat allowLeadingZeros thousandSeparator=" "  decimalScale={0} displayType="text" value={(tax?.VAT)} suffix={'руб.'} />

                </Typography>
              </>
              }

              <Typography variant="body2" display={'flex'} flexDirection={'row'} gutterBottom sx={{ mt: 0, textAlign: 'left', transform: 'scale(0.9)'}}>

                Итого: 

                <NumericFormat allowLeadingZeros thousandSeparator=" "  decimalScale={0} displayType="text"
                value={(tax?.price + tax?.duty + tax?.excise + tax?.VAT + tax?.customsClearance + tax?.recyclingFee + (fuel?.value !== 'Electro' ? tax?.taxAndCustomsClearance : 0 ))} suffix={'руб.'} />

              </Typography>

            </Box>

          </Grow>

          <Box sx={{ mt: ( ( showResult && volume && volume > 599 ) || false ? 0 : (!mobile && !bot) ? 3.5 : 0) }}>

            <Grid container spacing={2}>

              <Grid xs={12}>

                <Autocomplete
                value={currency && currency}
                onChange={(event, newValue) => {
                  window?.navigator?.vibrate && window?.navigator?.vibrate(10);
                  setCurrency( newValue );
                }}
                options={currencies}
                getOptionLabel={(option:any) => option.name}
                isOptionEqualToValue={(option, value) => option.name === value.name}
                noOptionsText={'Нету донных'}
                renderInput={(params) => (
                  <TextField
                    onClick={()=>{window?.navigator?.vibrate && window?.navigator?.vibrate(10);}}
                    sx={{
                      justifyContent: `center`,
                      alignItems: `center`,
                    }}
                    {...params}
                    label="Валюта"
                    variant="outlined"
                    fullWidth
                    inputProps={{
                      ...params.inputProps,
                      readOnly: true,
                      className:  classes.customCursor,
                    }}
                  />
                )}
                />

              </Grid>

              <Grid xs={12}>

                <TextField
                  disabled={currency?.name ? false : true}
                  value={price}
                  onChange={(value:any)=>{

                    const onlyNumbers = value?.target?.value.replace(/[^0-9]/g, '')
                    setPrice(onlyNumbers)
                  }}
                  sx={{
                    justifyContent: `center`,
                    alignItems: `center`,
                  }}
                  label={ currency?.name ? `Стоимость в ${currency?.key}` : 'Стоимость'} 
                  variant="outlined"
                  fullWidth
                  inputProps={{
                    inputMode: isMobileDevice() ? 'numeric' : 'text',
                  }}
                />

              </Grid>

              <Grid container spacing={2}>

                <Grid xs={7}>

                  <TextField
                  disabled={under3YearsOld ? true : false}
                  value={year}
                    onChange={(value:any)=>{

                      const onlyNumbers = value?.target?.value.replace(/[^0-9]/g, '')
                      if(onlyNumbers.length < 5) setYear(onlyNumbers);
                    }}
                    sx={{
                      justifyContent: `center`,
                      alignItems: `center`,
                    }}
                    label="Год выпуска"
                    variant="outlined"
                    fullWidth
                    inputProps={{
                      inputMode: isMobileDevice() ? 'numeric' : 'text',
                    }}
                  />

                </Grid>

                <Grid xs={5} sx={{pt: 1.5, pl: 0, pr: 0}}>
                  <FormControl>
                    <FormGroup>
                      <FormControlLabel control={<Checkbox checked={under3YearsOld} onChange={handleChangeUnder3YearsOld} 
                      sx={{
                        color: 'rgb(86, 86, 86) !important;',
                      }}
                      />} label='до 3 лет' labelPlacement="start"/>
                    </FormGroup>
                  </FormControl>
                </Grid>
                
              </Grid>

              <Grid xs={12}>

                <Autocomplete
                value={fuel && fuel}
                onChange={(event, newValue) => {
                  window?.navigator?.vibrate && window?.navigator?.vibrate(10);
                  /* setPower(null) */
                  setFuel( newValue );
                }}
                options={fuels.filter((option:any) => option.value === 'Gasoline' || option.value === 'Diesel' || option.value === 'Hybrid' || option.value === 'Electro')}
                filterOptions={(options, { inputValue }) => {
                  return options.filter(option =>
                    option.name.toLowerCase().includes(inputValue.toLowerCase())
                  );
                }}
                getOptionLabel={(option:any) => option.name}
                isOptionEqualToValue={(option, value) => option.name === value.name}

                noOptionsText={'Нету донных'}
                renderInput={(params) => (
                  <TextField
                    onClick={()=>{window?.navigator?.vibrate && window?.navigator?.vibrate(10);}}
                    sx={{
                      justifyContent: `center`,
                      alignItems: `center`,
                    }}
                    {...params}
                    label="Топливо"
                    variant="outlined"
                    fullWidth
                    inputProps={{
                      ...params.inputProps,
                      readOnly: true,
                      className:  classes.customCursor,
                    }}
                  />
                )}
                />

              </Grid>

              <Grow in={(fuel && fuel?.value !== 'Electro' ) || false} mountOnEnter unmountOnExit>

                <Grid xs={12}>

                  <TextField
                    value={volume}
                    onChange={(value:any)=>{

                      const onlyNumbers = value?.target?.value.replace(/[^0-9]/g, '')
                      setVolume(onlyNumbers)
                    }}
                    sx={{
                      justifyContent: `center`,
                      alignItems: `center`,
                    }}
                    label="Объем куб.см. "
                    variant="outlined"
                    fullWidth
                    inputProps={{
                      inputMode: isMobileDevice() ? 'numeric' : 'text',
                    }}
                  />

                </Grid>

              </Grow>

              <Grow in={(fuel && fuel?.value === 'Electro' ) || false} mountOnEnter unmountOnExit>

                <Grid xs={12}>

                  <TextField
                    value={power}
                    onChange={(value:any)=>{

                      const onlyNumbers = value?.target?.value.replace(/[^0-9]/g, '')
                      setPower(onlyNumbers)
                    }}
                    sx={{
                      justifyContent: `center`,
                      alignItems: `center`,
                      '& .MuiFormHelperText-root': {
                        mt: -0,
                        ml: 5,
                        width: `100%`,
                      }
                    }}
                    label={ fuel && fuel?.value === 'Electro' ? 'Мощность кВт' : 'Мощность л.с.'}
                    helperText={ fuel && fuel?.value !== 'Electro' && 'необязательное поле (Мощность)'}
                    variant="outlined"
                    fullWidth
                    inputProps={{
                      inputMode: isMobileDevice() ? 'numeric' : 'text',
                    }}
                  />
                </Grid>

              </Grow>

            </Grid>

          </Box>


          {/* <Grow in={showResult || false} timeout={700} unmountOnExit>
            
            <Box sx={{p: 1}}>

              <Typography variant="body2" display={'flex'} flexDirection={'row'} gutterBottom sx={{ mt: 2, textAlign: 'center'}}>

                Таможенное оформление:
                <NumericFormat allowLeadingZeros thousandSeparator=" "  decimalScale={0} displayType="text" value={tax?.customsClearance} suffix={'руб.'} />

              </Typography>

              <Typography variant="body2" display={'flex'} flexDirection={'row'} gutterBottom sx={{ mt: 2, textAlign: 'center'}}>

                Единая ставка: {tax?.rate} евро/см3 

              </Typography>
              
              <Typography variant="body1" display={'flex'} flexDirection={'row'} gutterBottom sx={{ mt: 2, textAlign: 'center'}}>

                Итого: 

                <NumericFormat allowLeadingZeros thousandSeparator=" "  decimalScale={0} displayType="text" value={tax?.tax} suffix={'руб.'} />

              </Typography>

            </Box>

          </Grow> */}

        </Box>

        :

        <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection={'column'}
       >

          <Typography variant="body1" display={'flex'} flexDirection={'row'} gutterBottom sx={{ mt: 0, ml: -0.9, textAlign: 'center'}}>

            {/* ЧТо-то пошло не так... */}

          </Typography>

        </Box>
        
      }

{/* 

Таможенное оформление   	3100 руб. 	3100.00 руб. 
Утилизационный сбор   	 	5200 руб. 
Единая ставка   	2.7 евро/см3 	539442.72 руб. 

Итого с утилизационным сбором   	  	547742.72 руб. 
 */}

    </>
  )
}


export default Calculators

export { Calculators }



type Currency = {
    id: number;
    Symbol: string;
    name: string;
    key: string;
    // ... other properties
    rate: number;
  };
  
  const convertCurrency = (currencies: Currency[], from: string, to: string, amount: number): number | null => {

    if(from === 'RUB'){

        const fromCurrency = currencies.find((currency) => currency.key === from);
        const toCurrency = currencies.find((currency) => currency.key === to);

        /* console.log(`fromCurrency toCurrency`)
        console.log(fromCurrency)
        console.log(toCurrency) */

        if (fromCurrency && toCurrency) {

          return (amount) * toCurrency.rate;
        } else {
          return null;
        }
        return null;
    }
    const fromCurrency = currencies.find((currency) => currency.key === from);
    const toCurrency = currencies.find((currency) => currency.key === to);
  
    if (fromCurrency && toCurrency) {
      return (amount / fromCurrency.rate) * toCurrency.rate;
    } else {
      return null;
    }
  };

  export default convertCurrency
  export { convertCurrency }

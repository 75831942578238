import { atom, selector } from "recoil"
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

export const productsScrollStateAtom = atom({
  key: "productsScrollStateAtom",
  default: 0,
  effects_UNSTABLE: [persistAtom],
})

export const productsScrollStateSelector = selector({
  key: "productsScrollStateSelector",
  get: ({ get }) => {
    const productsScroll = get(productsScrollStateAtom)
    return productsScroll
  }
})


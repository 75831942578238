import { FC, useEffect, useState } from 'react'

import { useRecoilState, useSetRecoilState } from 'recoil'

import { calculatorStateAtom } from '@state/calculator'
import { darkModeStateAtom } from '@state/darkMode'
import { chatBotStateAtom } from '@state/chatBot'

import Grid from '@mui/material/Unstable_Grid2'

import Grow from '@mui/material/Grow'
import Box from '@mui/material/Box'

import IconButton from '@mui/material/IconButton'

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined'

import DarkModeIcon from '@mui/icons-material/DarkMode'
import LightModeIcon from '@mui/icons-material/LightMode'

import { useMobileDetect } from '@hooks/useMobileDetect'

const VerticalFixedButtons: FC<any> = () => {

  const mobile = useMobileDetect()

  const [scroll, setScroll] = useState<boolean>(false)

  const setCalculator = useSetRecoilState<any>(calculatorStateAtom)
  const setChatBot = useSetRecoilState<any>(chatBotStateAtom)

  const [darkMode, setDarkMode] = useRecoilState<any>(darkModeStateAtom)

  const [tooltip, setTooltip] = useState<boolean>(true)
  
  useEffect(() => {
    setTimeout(() => {

      setTooltip(false)
  
    }, 5000)
  })
  
  useEffect(() => {
    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  })
  
  const onScroll = (e: Event) => {

    const window = e.currentTarget as Window
    let currentPosition = window.scrollY

    if( currentPosition > 0) return setScroll(true);
    
    return setScroll(false)
  }
  
  const scrollToTop = () => {

    window?.navigator?.vibrate && window?.navigator?.vibrate(10);
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  const [actions, setActions] = useState<any>([])

  useEffect(() => {
    
    setActions([
      
      { icon: <Box component={'img'} src={'/images/AiAssistant.gif'} sx={{ width: 40, height: 40 }}/>, name: 'Ai', action: ()=>{

        const random = Math.random().toString(36)

        window.history.pushState({ menuPage: random }, "menuPage", "?menuPage="+random);

        window?.navigator?.vibrate && window?.navigator?.vibrate(10);
        setChatBot(true)
      }},

      { icon: <CalculateOutlinedIcon />, name: 'Калькулятор', action: ()=>{

        const random = Math.random().toString(36)

        window.history.pushState({ menuPage: random }, "menuPage", "?menuPage="+random);

        window?.navigator?.vibrate && window?.navigator?.vibrate(10);
        setCalculator(true)
      }},
      { icon: darkMode.value === 'light' ? <DarkModeIcon /> : <LightModeIcon />, name: 'Тема', action: ()=>{

        window?.navigator?.vibrate && window?.navigator?.vibrate(10);

        if( darkMode.value !== 'light' ) return setDarkMode({ id: 0, value: 'light', name: 'Светлая' });
        
        return setDarkMode({ id: 1, value: 'dark', name: 'Темная' })
      }}])

    return () => {
      setActions([])
    }
  }, [darkMode])
  
  return (
    <Box
    sx={{
      position: 'fixed',

      display: 'flex',
      flexDirection: 'column',

      width:  mobile ? 0 : 340,

      ...(mobile ? {
        left: 5,
        bottom: 5,

        justifyContent: 'flex-start',
        alignItems: 'flex-start',
      } : {
        right: 20,
        bottom: 20,

        justifyContent: 'flex-end',
        alignItems: 'flex-end',
      }),
    }}>
      
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="flex-start"
      >

      <Grow in={scroll || false} timeout={700} unmountOnExit>

        <Grid container xs={12} 
          direction="row"
          justifyContent={mobile ? 'flex-start' : 'flex-end'}
          alignItems="center">
          <IconButton
          sx={{
            backgroundColor: 'primary.company',
            color: 'primary.main',
            m: mobile ? 1 : 1,
            
            height: 40,
            width: 40,

            ':hover': {
              backgroundColor: 'primary.company',
              color: 'primary.main',
              opacity: [0.9, 0.8, 0.7],
            },
            ':active': {
              backgroundColor: 'primary.company',
              color: 'primary.main',
              opacity: [0.9, 0.8, 0.7],
            },
          }}
          onClick={scrollToTop}>

            <KeyboardArrowUpIcon/>

          </IconButton>

        </Grid>

      </Grow>

      {actions?.map((item:any, index:number) => (

        <Grid key={index} container xs={12} spacing={1} 
        direction="row"
        justifyContent="flex-end"
        alignItems="center">

          { mobile ?
          <>

            <Grid xs={tooltip ? 4 : 12}>

              <IconButton
              sx={{
                backgroundColor: 'primary.company',
                color: 'primary.main',
                ':hover': {
                  backgroundColor: 'primary.company',
                  color: 'primary.main',
                  opacity: [0.9, 0.8, 0.7],
                },
                m: 1,
                height: 40,
                width: 40,
              }}
              onClick={item.action}>
                
                {item?.icon}

              </IconButton>

            </Grid>
  
            {( (tooltip|| false) && 

              <Grid xs={8} sx={{ display: 'flex', justifyContent: 'flex-start', pointerEvents: 'none' }}>

                <Box sx={{
                  height: '100%',
                  textAlign: 'right',
                  backgroundColor: 'rgba(0,0,0, 0.35)',
                  color: 'primary.main',
                  ml: 1,
                  p:1 ,
                  pt: 0.2,
                  pb: 0.2,
                  borderRadius: 1.5,
                  userSelect: 'none',
                  pointerEvents: 'none',
                }}>
                  
                  {item?.name}
                  
                </Box>

              </Grid>

            )}

          </>

          :

          <>

            <Grid xs={8} sx={{ display: 'flex', justifyContent: 'flex-end' }}>

              <Box sx={{
                height: '100%',
                textAlign: 'right',
                backgroundColor: 'rgba(0,0,0, 0.5)',
                color: 'primary.main',
                p:1 ,
                pt: 0.2,
                pb: 0.2,
                borderRadius: 1.5,
                userSelect: 'none',
                whiteSpace: 'nowrap',
                pointerEvents: 'none',
              }}>
                
                {item?.name}
                
              </Box>

            </Grid>

            <Grid xs={4}>

              <IconButton
              sx={{
                backgroundColor: 'primary.company',
                color: 'primary.main',
                ':hover': {
                  backgroundColor: 'primary.company',
                  color: 'primary.main',
                  opacity: [0.9, 0.8, 0.7],
                },
                m: 1,
                height: 40,
                width: 40,
              }}
              onClick={item.action}>
                
                {item?.icon}

              </IconButton>

            </Grid>
          </>
        }

        </Grid>

      ))}

      </Grid>
    </Box>
  )
}

export default VerticalFixedButtons

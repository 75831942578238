import { atom, selector } from "recoil"
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

export const openSubscriptionsStateAtom = atom({
  key: "openSubscriptionsStateAtom",
  default: false,
  effects_UNSTABLE: [persistAtom],
})

export const openSubscriptionsStateSelector = selector({
  key: "openSubscriptionsStateSelector",
  get: ({ get }) => {
    const openSubscriptions = get(openSubscriptionsStateAtom)
    return openSubscriptions
  }
})


import { useMemo, useEffect, useState } from 'react'

import { css, keyframes } from '@mui/system';

import { useRecoilState } from 'recoil'
import { trainingStateAtom } from '@state/training'

import useThemeMode from '@hooks/useThemeMode'

function useTraining(): any {

  const [training, setTraining] = useRecoilState<any>(trainingStateAtom)
  
  const themeMode = useThemeMode()

  const rgbaColor = useMemo(() => {
    return themeMode === 'dark' ? '255,255,255' : '0,0,0' 
  }, [themeMode])

  const boxShadow = keyframes`
  0% {
    box-shadow: 0px 0px 4px 3px rgba(${rgbaColor},0.025);
    transform: scale(1);
  }
  50% {
    box-shadow: 0px 0px 4px 3px rgba(${rgbaColor},0.15);
    transform: scale(1.02);
  }
  100% {
    box-shadow: 0px 0px 4px 3px rgba(${rgbaColor},0.025);
    transform: scale(1);

  }
  `;

  const trainingAnimation = {
    animation: `${boxShadow} 2s linear infinite`,
    zIndex: 10003,
  };

  const handleNextStep = (step?: any) => {

    console.log(`step`)
    console.log(step)

    if(step) return setTraining((prevState:any) => ({ ...prevState, step }));

    if(!training.step === training.steps.length) return setTraining((prevState:any) => ({ ...prevState, active: false, completed: true }));

    if(!training.active) return;

    setTraining((prevState:any) => ({ ...prevState, step: prevState.step + 1 }))
  }

  useEffect(() => {
    //handleNextStep()
  }, [])

  useEffect(() => {
    //handleNextStep()
  }, [training])

  return { 
    training: {
      ...training,
      trainingAnimation,
      active: training.active && !training.completed ? true : false, 
      step: training.step,
      next: handleNextStep,
    }
  }
}

export default useTraining
export { useTraining }

import { atom, selector } from "recoil"
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

export const darkModeStateAtom = atom({
  key: "darkModeStateAtom",
  default: { id: 2, value: 'system', name: 'Системная' },
  effects_UNSTABLE: [persistAtom],
})

export const darkModeStateSelector = selector({
  key: "darkModeStateSelector",
  get: ({ get }) => {
    const darkMode = get(darkModeStateAtom)
    return darkMode
  }
})


import { atom, selector } from "recoil"
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

export const globalProgressBackdropStateAtom = atom({
  key: "globalProgressBackdropStateAtom",
  default: false,
  effects_UNSTABLE: [persistAtom],

})

export const globalProgressBackdropStateSelector = selector({
  key: "globalProgressBackdropStateSelector",
  get: ({ get }) => {
    const globalProgressBackdrop = get(globalProgressBackdropStateAtom)
    return globalProgressBackdrop
  }
})


import { useEffect, useState } from 'react';

import { useRecoilValue } from 'recoil'

import { currenciesStateAtom } from '@state/currencies'

type Currency = {
  id: number;
  Symbol: string;
  name: string;
  key: string;
  // ... other properties
  rate: number;
};

const useCurrencyConversion = (from: string, to: string, amount: number) => {

  const currencies = useRecoilValue<any>(currenciesStateAtom)

  const [convertedAmount, setConvertedAmount] = useState<number>(0);

  useEffect(() => {

    if(!currencies||currencies.length === 0) return;

    const fromCurrency = currencies.find((currency:any) => currency.key === from);
    const toCurrency = currencies.find((currency:any) => currency.key === to);

    if (fromCurrency && toCurrency) {
      const result = (amount / fromCurrency.rate) * toCurrency.rate;
      setConvertedAmount(result);
    } else {
      setConvertedAmount(0);
    }
  }, [currencies, from, to, amount]);

  return convertedAmount;
};

export default useCurrencyConversion

export { useCurrencyConversion }


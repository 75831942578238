import { atom, selector } from "recoil"
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

export const modelsSearchStateAtom = atom({
  key: "modelsSearchStateAtom",
  default: '',
  effects_UNSTABLE: [persistAtom],
})

export const modelsSearchStateSelector = selector({
  key: "modelsSearchStateSelector",
  get: ({ get }) => {
    const modelsSearch = get(modelsSearchStateAtom)
    return modelsSearch
  }
})


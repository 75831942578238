import { FC, useMemo, useEffect, useState } from 'react'

import AnimateHeight from 'react-animate-height'

import { useRecoilValue } from 'recoil'
import { companyStateAtom } from '@state/company'

import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'

import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';

import parseDynamicComponent from '@utils/parseDynamicComponent'

import { useBotDetect } from '@hooks/useBotDetect'
import { useMobileDetect } from '@hooks/useMobileDetect'

const Faq: FC<any> = () => {

  const company = useRecoilValue<any>(companyStateAtom)

  const bot = useBotDetect()
  const mobile =   useMobileDetect()

  const [open, setOpen] = useState<number>(-1)

  const faq:any = useMemo(() => parseDynamicComponent(company?.faq?.faq),[company?.faq?.faq])

  useEffect(()=>{

    window?.navigator?.vibrate && window?.navigator?.vibrate(10);

  }, [open])

  return (
      <>
        <Container maxWidth="md" id={'faq'} sx={{ pt: 0,  mt: { xs: bot ? 0 : 5, md: 5 }, }}>

        { faq?.header?.title &&
          
          <Typography variant="h6" gutterBottom align="center"> { faq?.header?.title } </Typography>
        }

        { faq?.header?.caption &&

        <Typography variant="caption" gutterBottom display="block" align="center"> { faq?.header?.caption } </Typography>
        }

        { faq?.header?.body &&
          
          <Typography variant="body2" dangerouslySetInnerHTML={{__html: faq?.header?.body?.text}}/>
        }

        <Box>

          {faq?.data?.map((item:any) => (

            <Box key={`faq-${item.id}-${item?.name}`} boxShadow={2} borderRadius={2} p={2} pb={1}  mt={1} sx={{ cursor: 'pointer'}} onClick={() =>{

              if(open===item.id) return setOpen(-1);

              setOpen(item.id)
            }}>

              <Box
              style={{
                display: 'flex',
                flexFlow: 'row-reverse',
                alignContent: 'space-between',
                justifyContent: 'space-between',
              }}>

                <KeyboardArrowDownOutlinedIcon fontSize="large" style={{transform: open === item.id ? 'rotate(-180deg)':'unset', transition: '550ms'}}/>

                <Box>

                  <Box sx={{ '& p': { mt: 0, mb: 0, } }} dangerouslySetInnerHTML={{ __html: item?.name }}/>
  
                  <AnimateHeight
                    duration={500}
                    height={open === item.id ? 'auto':0}
                  >

                  <Box dangerouslySetInnerHTML={{ __html: item?.text }}/>

                </AnimateHeight>

              </Box>

            </Box>

            </Box>
            
          ))}
          
        </Box>


        { faq?.footer?.title &&
          
          <Typography variant="h6" gutterBottom align="center"> { faq?.footer?.title } </Typography>
        }

        { faq?.footer?.caption &&

        <Typography variant="caption" gutterBottom display="block" align="center"> { faq?.footer?.caption } </Typography>
        }

        { faq?.footer?.body &&
          
          <Typography variant="body2" dangerouslySetInnerHTML={{__html: faq?.footer?.body?.text}}/>
        }

        </Container>
      </>
  )
}

export default Faq

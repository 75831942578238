import { atom, selector } from "recoil"
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

export const bookmarksStateAtom = atom({
  key: "bookmarksStateAtom",
  default: [],
  effects_UNSTABLE: [persistAtom],
})

export const bookmarksStateSelector = selector({
  key: "bookmarksStateSelector",
  get: ({ get }) => {
    const bookmarks = get(bookmarksStateAtom)
    return bookmarks
  }
})


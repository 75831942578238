import { atom, selector } from "recoil"
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

export const effectsStateAtom = atom({
  key: "effectsStateAtom",
  default: null,
  effects_UNSTABLE: [persistAtom],

})

export const effectsStateSelector = selector({
  key: "effectsStateSelector",
  get: ({ get }) => {
    const effects = get(effectsStateAtom)
    return effects
  }
})


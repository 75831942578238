import { atom, selector } from "recoil"

export const menuPageStateAtom = atom({
  key: "menuPageStateAtom",
  default: 'menu',
})

export const menuPageStateSelector = selector({
  key: "menuPageStateSelector",
  get: ({ get }) => {
    const menuPage = get(menuPageStateAtom)
    return menuPage
  }
})


import { FC, useRef } from 'react'

import { useSetRecoilState } from 'recoil'
import { callBackCalculatorPriceStateAtom } from '@state/callBackCalculatorPrice'

import { styled } from '@mui/material/styles'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Unstable_Grid2'
import Link from '@mui/material/Link'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import Button from '@mui/material/Button'

import LocalPhoneIcon from '@mui/icons-material/LocalPhone';

import Logo from '@components/Logo'

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',

  height: '100%',
  width: '100%',
  
}))

const Menus: FC = () => {

  const containerRef = useRef<any>(false)

  const setCallBackCalculatorPrice = useSetRecoilState<boolean>(callBackCalculatorPriceStateAtom)

  return (
    <Container ref={containerRef} maxWidth="lg" sx={{ pr: 0, mb: 3, }}>
      <Grid container spacing={0}>

        <Grid xs={2}>
          <Item>
            <Logo show={true } onClick={ window.scrollTo({top: 0, behavior: 'smooth'} )}/>
          </Item>
        </Grid>

        <Grid xs>
          <Item sx={{pr: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column',}}>

            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row',}}>

              <Link href="tel:+79502913351" underline="none" sx={{display: 'flex', alignItems: 'center', color: 'text.primary',}} >

              <LocalPhoneIcon/> <Typography gutterBottom align="center" sx={{ml: 1, typography: { sm: 'body2', md: 'h6' }}}> +7 (950) 291 33 51 </Typography>

              </Link>

              <Link href="tel:+79502913351" underline="none" sx={{display: 'flex', alignItems: 'center', ml: 1, color: 'text.primary',}}>

                <LocalPhoneIcon/> <Typography gutterBottom align="center" sx={{ml: 1, typography: { sm: 'body2', md: 'h6' }}}> +7 (950) 291 33 51 </Typography>

              </Link>

            </Box>

            <Box>

              <Typography variant="caption" gutterBottom align="center" sx={{ml: 1}}> Работаем с 9:00 до 21:00 без выходных </Typography>

            </Box>

          </Item>
        </Grid>

        <Grid xs={3}>
          <Item sx={{pr: 0, display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
            <Button
            variant="contained"
            sx={{
              width: 280,
              p: 1,
              backdropFilter: "blur(2px)",
              "& .MuiButton-startIcon": { marginRight: "2px" },
              
              bgcolor: 'primary.company',
              color: 'primary.main',

              "&:hover": {
                bgcolor: 'primary.company',
                opacity: 0.9,
              },
              "&:active": {
                bgcolor: 'primary.company',
                opacity: 0.9,
              },
            }}
            onClick={()=>{

              window?.navigator?.vibrate && window?.navigator?.vibrate(10);
              setCallBackCalculatorPrice(true)
            }}>
              
              Рассчитать стоимость

            </Button>
          </Item>
        </Grid>

      </Grid>

    </Container>
  )
}

export default Menus

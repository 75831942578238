import { FC } from 'react'

import { useRecoilValue } from 'recoil'
import { pageStateAtom } from '@state/page'

import Grow from '@mui/material/Grow'
import Box from '@mui/material/Box'

import Header from '@components/Header'
import PopupCallBack from '@components/PopupCallBack'
import Start from '@components/Start'
import Brands from '@components/Brands'
import Models from '@components/Models'
import Products from '@components/Products'
import Product from '@components/Product'
import Menu from '@components/Menu'
import CallBackButton from '@components/Buttons/CallBack'

import Training from '@components/Training'

const Bot: FC<any> = () => {

  const page = useRecoilValue<any>(pageStateAtom)

  return (
      <>

        <Header/>

        <Menu/>

        <Grow in={ page === `brands` || false } unmountOnExit>

          <Box>

            <Brands/>

          </Box>

        </Grow>

        <Grow in={ page === `models` || false } unmountOnExit>

          <Box>

            <Models/>

          </Box>
          
        </Grow>

        <Grow in={ page === `products` || false } unmountOnExit>

          <Box>

            <Products/>

          </Box>

        </Grow>

        <Training/>

      </>
  )
}

export default Bot

export { Bot }




import { FC } from 'react'

import { useRecoilValue, } from 'recoil'

import Grow from '@mui/material/Grow'
import Button from '@mui/material/Button'

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'

const ScrollToTopVirtualList: FC<any> = ({atom, listRef}:any) => {

  const scroll = useRecoilValue<number>(atom)

  return (
    <Grow in={scroll > 0 || false} timeout={700} unmountOnExit>

    <Button
      variant="contained"
      sx={{ 
        borderRadius: 5,

        position: 'fixed',
        left: 'calc(50% - 45px)',

        transform: 'translateX(-50%)',

        height: 15,
        
        //backdropFilter: "blur(2px)",
        //boxShadow: '0px 0px 6px 3px rgba(0,0,0,0.1)',

        backdropFilter: 'unset', 
        boxShadow: 'unset',

        zIndex: 2,

        width: 90,
        
        fontSize: 10,

        backgroundColor: `rgba(255, 255, 255, 0.8)`, 
        color: `black`, 

        p: 1, 
        pt: 1.5, 
        pb: 1.5, 
        "& .MuiButton-startIcon": { marginRight: "0px" }
      }}
      startIcon={<ArrowUpwardIcon sx={{ transform: 'scale(0.8)' }} />}
      onClick={()=>{window?.navigator?.vibrate && window?.navigator?.vibrate(10); listRef.current.scrollTo(0)}}
    >

      Наверх

    </Button>

  </Grow>
  )
}

export default ScrollToTopVirtualList

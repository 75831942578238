import { FC, useMemo } from 'react'

import Fade from '@mui/material/Fade'
import Box from '@mui/material/Box'

import { useRecoilValue, useSetRecoilState } from 'recoil'

import { companyStateAtom } from '@state/company'

import { menuStateAtom } from '@state/menu'
import { menuPageStateAtom } from '@state/menuPage'

import parseDynamicComponent from '@utils/parseDynamicComponent'

import useBotDetect from '@hooks/useBotDetect'

import useThemeMode from '@hooks/useThemeMode'

import { replaceImageFormat } from '@utils/replaceImageFormat'

const  { REACT_APP_MINIO_URL_FROM, REACT_APP_MINIO_URL_TO } = process.env

const Logo: FC<any> = ({show}) => {

  const bot = useBotDetect()

  const themeMode = useThemeMode()

  const company = useRecoilValue<any>(companyStateAtom)

  const setMenu = useSetRecoilState<any>(menuStateAtom)
  const setMenuPage = useSetRecoilState<any>(menuPageStateAtom)

  const design:any = useMemo(() => parseDynamicComponent(company?.design),[company?.design])
  
  const logo = themeMode === 'dark' ? design?.data?.[0]?.logo_dark: design?.data?.[0]?.logo_light

  return (
    <Fade in={show || false} timeout={700}>
      <Box
        component="img"
        sx={{
          position: `relative`,
          top: 5,
          left: 0,
          width: 160,
          transition:' all 1s ease-out',
          objectFit: "cover",
          cursor: 'pointer',
        }}
        alt={company?.name}
        src={ logo?.url && replaceImageFormat(logo?.url.replace(REACT_APP_MINIO_URL_FROM, REACT_APP_MINIO_URL_TO))}
        onClick={()=>{

          if(bot) return;

          if(!bot) {

            setMenu(false)
            setMenuPage('menu')
          }

          window.scrollTo({top: 0, behavior: 'smooth'})
        }}
      />
    </Fade>
  )
}

export default Logo

import { useMemo, useEffect, useState } from 'react'

import useMediaQuery from '@mui/material/useMediaQuery'
import { useWindowSize } from "@uidotdev/usehooks"

function useDesktopDetect() {

  const matchesUseMediaQuery = useMediaQuery('(min-width:420px)')

  const [desktop, setDesktop] = useState<boolean|null>(false)
  
  const isMobileDevice = useMemo(() => () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent), [navigator.userAgent])
  
  const handle = () => {

    if(matchesUseMediaQuery){

      setDesktop(true)
    }else
    if(!isMobileDevice()){
      
      setDesktop(true)
    }else{

      setDesktop(false)
    }
  };

  useEffect(() => {

    handle();

  }, [])

  return desktop
}

export default useDesktopDetect

export { useDesktopDetect }


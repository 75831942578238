import { atom, selector } from "recoil"

export const menuStateAtom = atom({
  key: "menuStateAtom",
  default: false,
})

export const menuStateSelector = selector({
  key: "menuStateSelector",
  get: ({ get }) => {
    const menu = get(menuStateAtom)
    return menu
  }
})



import { FC, useEffect } from 'react'

import { useRecoilState, useRecoilValue } from 'recoil'
import { filtersStateAtom } from '@state/filters'
import { filtersDataStateAtom } from '@state/filtersData'
import { countriesStateAtom } from '@state/countries'

import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'

const Countries: FC = () => {

  const [filters, setFilters] = useRecoilState<any>(filtersStateAtom)

  const filtersData = useRecoilValue<any>(filtersDataStateAtom)
  const countries = useRecoilValue<any>(countriesStateAtom)

  useEffect(()=>{}, [filters, filtersData])

  return (
          <Autocomplete
            disableClearable
            sx={{
              '& .MuiInputBase-root': {
                flexWrap: 'nowrap',
/*              
                overflowX: 'auto',
                overflowY: 'hidden',
                paddingRight: 0, 
*/
              },
/* 
              '& .MuiOutlinedInput-root .MuiAutocomplete-endAdornment': {
                right: -70,
              },
 */
            }}
            multiple
            limitTags={3}
            size="small" // хорошо заходи бля iphone se

            //disabling selected options
            getOptionDisabled={(option) =>
              filters.countries.some((selectedOption:any) => {
                if(selectedOption.name === option.name) return true;
              })
            }

            onChange={(event, newValue) => {
              window?.navigator?.vibrate && window?.navigator?.vibrate(10);
              setFilters((prevState:any) => ({ ...prevState, countries: newValue }))
            }}
            

            defaultValue={countries}
            value={filters.countries}

            options={countries}
            getOptionLabel={(option) => option.name}
            noOptionsText={'Нету донных'}
/* 
            renderTags={(value: Option[], getTagProps) =>
              value.map((option:any, index:any) => (
                <div {...getTagProps({ index })}>
                  {option.name}
                </div>
              ))
            } */
            isOptionEqualToValue={(option, value) => option.id === value.id}

            renderInput={(params) => (
              <TextField
              onClick={()=>{window?.navigator?.vibrate && window?.navigator?.vibrate(10);}}
              {...params}
              inputProps={{
                ...params.inputProps,
                readOnly: true,
              }}
              sx={{
                '& .MuiInputBase-root': {
                  cursor: 'pointer', 
                },
                '& .MuiInputBase-input': {
                  cursor: 'pointer', 
                },
              }}
              label="Страны экспортеры"
/*               InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                  </>
                ),
              }} */
              /* placeholder="Страны" */
            />
/*               <TextField
                {...params}
                placeholder={filters.fuel ? '' : 'Топливо'}
                variant="outlined"
                fullWidth
                inputProps={{
                  ...params.inputProps,
                  readOnly: true,
                  className:  classes.customCursor, // Применяем пользовательский класс CSS при не выбранной опции
                }}
                              InputLabelProps={{sx: {  marginTop: -0.25 } }}

              /> */
            )}
          />
  )
}

export default Countries;

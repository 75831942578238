import { atom, selector } from "recoil"
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

export const aiStateAtom = atom({
  key: "aiStateAtom",
  default: true,
  effects_UNSTABLE: [persistAtom],

})

export const aiStateSelector = selector({
  key: "aiStateSelector",
  get: ({ get }) => {
    const ai = get(aiStateAtom)
    return ai
  }
})


import { atom, selector } from "recoil"
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

export const openSearchFormModelStateAtom = atom({
  key: "openSearchFormModelStateAtom",
  default: false,
  effects_UNSTABLE: [persistAtom],
})

export const openSearchFormModelStateSelector = selector({
  key: "openSearchFormModelStateSelector",
  get: ({ get }) => {
    const openSearchFormModel = get(openSearchFormModelStateAtom)
    return openSearchFormModel
  }
})


import { FC, useRef, useState, useEffect, useMemo } from 'react'

import qs from 'qs'

import { size } from 'lodash'

import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil'
import { productStateAtom } from '@state/product'

import { callBackStateAtom } from '@state/callBack'
import { callBackButtonStateAtom } from '@state/callBackButton'
import { callBackAttachDataStateAtom } from '@state/callBackAttachData'

import { companyStateAtom } from '@state/company'
import { aiStateAtom } from '@state/ai'

import { bookmarksStateAtom } from '@state/bookmarks'

import { useCheckingProductBookmarks } from '@hooks/useCheckingProductBookmarks'

import SwipeableDrawer from '@components/SwipeableDrawer'

import QRcode from '@components/QRcode'

import MobileRows from '@components/Product/MobileRows'
import DesktopRows from '@components/Product/DesktopRows'

import Grid from '@mui/material/Unstable_Grid2'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Snackbar from '@mui/material/Snackbar';
import Modal from '@mui/material/Modal'

import ListItem from '@mui/material/ListItem'

import CircularProgress from '@mui/material/CircularProgress'
import LinkedCameraOutlinedIcon from '@mui/icons-material/LinkedCameraOutlined';
import ShareIcon from '@mui/icons-material/Share';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import NotesIcon from '@mui/icons-material/Notes'
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';

import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

import useMeasure from '@hooks/useMeasure'

import useMobileDetect from '@hooks/useMobileDetect'

import ButtonAdditionalFeatures from './ButtonAdditionalFeatures'

import Options from './Options'

import api from '@services/api'

import { replaceImageFormat } from '@utils/replaceImageFormat'

const  { REACT_APP_FAKE_AWS, REACT_APP_MINIO_URL_FROM, REACT_APP_MINIO_URL_TO } = process.env

declare var window: Window & typeof globalThis;

const Product: FC = () => {

  const { windowSize } = useMeasure()

  const mobile = useMobileDetect()

  const checkingProductBookmarks = useCheckingProductBookmarks()

  const [heightModal, setHeightModal] = useState<number>(380)

  const [product, setProduct] = useRecoilState<any>(productStateAtom)

  const [options, setOptions] = useState<boolean>(false)

  const [loading, setLoading] = useState<boolean>(false)

  const [morePhotos, setMorePhotos] = useState<boolean>(false)
  
  const [bookmarks, setBookmarks] = useRecoilState<any>(bookmarksStateAtom)
  
  const ai = useRecoilValue<any>(aiStateAtom)
  const company = useRecoilValue<any>(companyStateAtom)

  const setCallBack = useSetRecoilState<boolean>(callBackStateAtom)  

  const [shareLot, setShareLot] = useState<boolean>(false)

  const [qrcode, setQrcode] = useState<boolean>(false)

  const setCallBackButton = useSetRecoilState<boolean>(callBackButtonStateAtom)
  const setCallBackAttachData = useSetRecoilState<any>(callBackAttachDataStateAtom)

  const [data, setData] = useState<any>(null)
  const [status, setStatus] = useState<any>(null)

  useEffect(() => {}, [bookmarks])

  useEffect(() => {

    if(!product) return;
    const random = Math.random().toString(36)

    window.history.pushState({ product: random }, "product", "?product="+random);

  }, [product])
  
  const onClose = () => {

    setHeightModal(380)

    setLoading(false)
    setMorePhotos(false)
    setOptions(false)
    setProduct(null)
    setCallBackButton(false)
  }

  useEffect(() => {
    
    if(size(product)>0){

      setLoading(true)

      const query = qs.stringify({
        ai,
        randomSort: false,
        populate: {


          auction: {
            fields: ['name', 'grade']
          },

          donor: {
            fields: ['name', 'grade', 'options']
          },


          brand: {
            fields: ['name']
          },
          model: {
            fields: ['name']
          },


          country: {
            fields: ['code', 'value', 'name']
          },
          city: {
            fields: ['value', 'name']
          },
          
          currency: {
            fields: ['key', 'Symbol', 'name', 'rate']
          },


          drive: {
            fields: ['name']
          },
          fuel: {
            fields: ['name']
          },
          transmission: {
            fields: ['name']
          },
          body: {
            fields: ['name']
          },
          color: {
            fields: ['name']
          },


          photo: {
            fields: ['url']
          },
          
          photos: true,

          photos_aws: true,
          auction_sheet: true
        },
        locale: ['ru'],
      }, {
        encodeValuesOnly: true, // prettify URL
      })

      api({ id: product.id, collection: 'lots', query }).then((data) => {

        if(data){
          setCallBackAttachData(data.data)
          setData(data.data)
          setStatus(data.status)
          setCallBackButton(true)

        }
  
        setLoading(false)
  
      }).catch((error:any) => {
        setLoading(false)
      })
    }

  }, [product])

  const handleClickClipBoardWriteText = () => {

    //https://t.me/aicarseller_bot/app?startapp=L41817
    //window.navigator.clipboard.writeText(`https://t.me/${company?.telegram_bot}/app?startapp=L${product?.id}`)

    /* const params = encodeURIComponent(JSON.stringify({
      p: product?.id
    })) */
    const params = `L${product?.id}`
    window.navigator.clipboard.writeText(`https://t.me/${company?.telegram_bot}/app?startapp=${params}`)
      .then(() => {
        setShareLot(true)
      })
      .catch((error) => {});
  }

  const ref = useRef<any>(null)

  useEffect(() => {

    if(!mobile){

      setTimeout(() => {
        if(ref.current) {
          
          ref.current.scrollTo({ top:  320, behavior: 'smooth' })
          setHeightModal(windowSize.height - 200)
        };
      }, 250)
    }
  }, [morePhotos, options])



/* 
  { data?.donor?.options &&
    <Grid xs={data?.donor?.options ? 2.0 : 2.4}>

      <ButtonAdditionalFeatures name='Подробней'  icon={<FormatAlignJustifyIcon fontSize='large'/>} onClick={()=>{

        window?.navigator?.vibrate && window?.navigator?.vibrate(10);

        setOptions(!options)
      }}/>

    </Grid>
  }

  { ( data?.photos?.length > 1 || data?.photos_aws?.length > 0 ) && 
 */

  const auctionsDate = useMemo(() => data?.date_of_auction, [data])
  
  const morePhotosButton = useMemo(() => ( data?.photos?.photos?.length > 1 || data?.photos_aws?.length > 0 ) , [data])
  
  const moreOptionsButton = useMemo(() => ( data?.donor?.options ) , [data])

  
  return (
    <>

        { !mobile ?

          <Modal
          disableAutoFocus
          open={size(product) > 0 || false}
          onClose={()=>{
            onClose()
          }}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
          sx={{
           "&" : {
              backdropFilter: "blur(6px)",
            }
          }}
          >

          <Box
            ref={ref}
            sx={{
              position: 'absolute' as 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 'calc(100vw - 20px)', 
              maxWidth: 1200,
              height: auctionsDate ? heightModal + 20 : heightModal,

              maxHeight:  'calc(100vh - 40px)', 
              bgcolor: 'background.paper',
              border: 0,
              borderRadius: 2.5,
              boxShadow: 24,
            }}>


            <IconButton
              aria-label="close"
              size="large"
              sx={{
                position: 'absolute',
                padding:0.25,
                top: 2.5,
                right: -45,
  
                height: 'unset',
  
                bgcolor: 'background.paper',
                color: 'text.primary',
  
                boxShadow: 4,
  
                transform: 'scale(1.25)',
                transition: 'transform 0.5s',
                '&:hover': {
                  bgcolor: 'background.paper',
                  color: 'text.primary',
                  transform: 'scale(1.3) translate(0, 0)',
                },
              }}
              onClick={()=>{
                onClose()
              }}>
                
                <CloseIcon/>

              </IconButton>

            <Box
            sx={{
              height: heightModal,

              pt: 4,
              px: 4,
              pb: 4,
              overflow: (morePhotos||options) ? 'auto' : 'hidden',
            }}>
              

              <Box>

                { loading ?

                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    height={heightModal}
                  >

                    <CircularProgress color="inherit" />

                    <Typography variant="subtitle2" gutterBottom   align="center" sx={{ mt: 2.5 , ml: 5, mr: 5}}>

                      Загружаем...

                    </Typography>
                    
                  </Box>

                  :

                  <>

                    { status === 'result-null' ?
                      
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        height="80vh"
                      >

                        <CircularProgress color="inherit" />

                        <Typography variant="subtitle2" gutterBottom  align="center" sx={{ mt: 2.5 , ml: 5, mr: 5}}>

                          Автомабиль продан

                        </Typography>
                        
                      </Box>

                    :
                      
                      <>

                        <Grid container spacing={0}>
                          
                          <Grid xs={4}>

                            <div style={{
                              backgroundImage: `url(${data?.photo?.[0]?.formats?.small ? replaceImageFormat(data?.photo?.[0]?.formats?.small?.url?.replace(REACT_APP_MINIO_URL_FROM, REACT_APP_MINIO_URL_TO)) :  replaceImageFormat(data?.photo?.[0]?.url?.replace(REACT_APP_MINIO_URL_FROM, REACT_APP_MINIO_URL_TO))})`,
                              backgroundPosition: `center`,
                              backgroundSize: `cover`,
                              backgroundRepeat: `no-repeat`,
                              height: auctionsDate ? '340px' : `320px`,
                              borderRadius: 15,
                              marginBottom: 15,
                            }}/>

                          </Grid>
                          
                          <Grid container xs={8} sx={{pl: 2}} >

                          <Grid xs={12}>

                            <ListItem component="div" disablePadding>
                              
                              <Stack
                              direction="column"
                              justifyContent="space-between"
                              alignItems="flex-start"
                              spacing={0}
                              sx={{ p: 0, pl:0, width: '100%', boxShadow: '0px 0px 6px 3px rgba(0,0,0,0.1)', background: 'rgba(119, 126, 141, 0.15)'}}
                              padding={0}
                              margin={1}
                              marginTop={0}
                              marginBottom={0}
                              border={0}
                              borderColor="primary.main"
                              borderRadius={2.5}
                              >

                                <DesktopRows data={data} company={company}/>
                  
                            </Stack>
                        </ListItem>
                        
                        </Grid>

                        <Grid container xs={12}>
                                  
                          <Grid container spacing={2} xs={12} sx={{ml: 0, mr: 0}}>
                            
                            { moreOptionsButton &&

<Grid xs={ moreOptionsButton ? ( morePhotosButton ? 2.0 : 2.4 ) : ( morePhotosButton ? 2.4 : 3.0 ) }>

                                <ButtonAdditionalFeatures name='Подробней'  icon={<FormatAlignJustifyIcon fontSize='large'/>} onClick={()=>{

                                  window?.navigator?.vibrate && window?.navigator?.vibrate(10);

                                  setOptions(!options)
                                }}/>

                              </Grid>
                            }

                            { morePhotosButton && 

<Grid xs={ moreOptionsButton ? ( morePhotosButton ? 2.0 : 2.4 ) : ( morePhotosButton ? 2.4 : 3.0 ) }>

                                <ButtonAdditionalFeatures name='Фотографии' disabled={morePhotos} icon={<LinkedCameraOutlinedIcon fontSize='large'/>} onClick={()=>{

                                  window?.navigator?.vibrate && window?.navigator?.vibrate(10);
                                  
                                  setMorePhotos(!morePhotos)
                                }}/>

                              </Grid>
                            }

<Grid xs={ moreOptionsButton ? ( morePhotosButton ? 2.0 : 2.4 ) : ( morePhotosButton ? 2.4 : 3.0 ) }>
                                
                              <ButtonAdditionalFeatures
                              name={ checkingProductBookmarks ? <>В Избранное</> : <>Удалить</>}
                              icon={checkingProductBookmarks ? <BookmarkBorderIcon fontSize='large'/> : <BookmarkIcon fontSize='large'/>}
                              onClick={()=>{
                                  window?.navigator?.vibrate && window?.navigator?.vibrate(10);
                                  
                                  const isPresent = bookmarks.some((item: any) => item.id === product?.id );

                                  if (isPresent) {

                                    const updatedArray = bookmarks.filter((item: any) => !(item.id === product?.id));

                                    return setBookmarks(updatedArray);
                                  } 
                                    
                                  return setBookmarks((prevArray:any) => [...prevArray, product]);
                              }}/>

                            </Grid>

<Grid xs={ moreOptionsButton ? ( morePhotosButton ? 2.0 : 2.4 ) : ( morePhotosButton ? 2.4 : 3.0 ) }>
                              
                              <ButtonAdditionalFeatures
                              name={'QR код'}
                              icon={<QrCode2Icon fontSize='large'/>}
                              onClick={()=>{

                                window?.navigator?.vibrate && window?.navigator?.vibrate(10);
                                setQrcode(true)
                              }}/>

                            </Grid>

<Grid xs={ moreOptionsButton ? ( morePhotosButton ? 2.0 : 2.4 ) : ( morePhotosButton ? 2.4 : 3.0 ) }>
                                
                              <ButtonAdditionalFeatures
                                name={'Ссылка'}
                                icon={<InsertLinkIcon fontSize='large'/>}
                                onClick={()=>{

                                  window?.navigator?.vibrate && window?.navigator?.vibrate(10);
                                  handleClickClipBoardWriteText()
                                }}/>

                            </Grid>

<Grid xs={ moreOptionsButton ? ( morePhotosButton ? 2.0 : 2.4 ) : ( morePhotosButton ? 2.4 : 3.0 ) }>
                            
                            <ButtonAdditionalFeatures
                              name={'Поделиться'}
                              icon={<ShareIcon fontSize='large'/>}
                              href={`https://t.me/share/url?url=https://t.me/${company?.telegram_bot}/app?startapp=L${product?.id}&text=${product?.brand?.name} ${product?.model?.name}`}
                              onClick={()=>{

                                window?.navigator?.vibrate && window?.navigator?.vibrate(10);
                              }}/>
                            
                            </Grid>

                          </Grid>

                        </Grid>

                        
                        <Grid xs={12} sx={{ml: 0, mr: 0,}}>

                          <Button variant="contained"
                          fullWidth onClick={()=>{

                            window?.navigator?.vibrate && window?.navigator?.vibrate(10);
                            onClose()
                            setCallBack(true)
                          }}
                          sx={{

                            left: '50%',
                            transform: 'translateX(-50%)', 

                            width: 'calc(100% - 15px)',
                            height:  50,

                            bgcolor: 'primary.company',
                            color: 'primary.main',

                            "&:hover": {
                              bgcolor: 'primary.company',
                              opacity: 0.7,
                            },
                            "&:active": {
                              bgcolor: 'primary.company',
                              opacity: 0.7,
                            },
                          }}
                          endIcon={<NotesIcon/>}
                          >
                            
                            Оставить заявку

                          </Button>

                        </Grid>

                      </Grid>

                      { morePhotos &&
                        
                        <Grid container spacing={2} xs={12}>
                        
                          {data?.photos?.photos?.map((item: any, i:number) => (
                            
                            <Grid xs={12}>
                            
                              {/*
                              <LazyLoadImage key={i}
                              alt={''}
                              src={`${REACT_APP_FAKE_AWS}/image?image=${item}`}
                              effect="blur"
                              placeholder={<CircularProgress/>}
                              style={{ width: '100%', marginTop: 25, borderRadius: 15, boxShadow: '0px 0px 16px 7px rgba(0,0,0,0.17)' }}
                              />
                              */}

                              <div
                              key={i}
                              style={{
                                backgroundImage: `url(${REACT_APP_FAKE_AWS}/image?image=${item})`,
                                backgroundPosition: `center`,
                                backgroundSize: `cover`,
                                backgroundRepeat: `no-repeat`,
                                minHeight: `620px`,
                                maxHeight: `920px`,
                                borderRadius: 15,
                                marginTop: 20,
                                boxShadow: '0px 0px 16px 7px rgba(0,0,0,0.17)'
                              }}/>
                            </Grid>
                              
                          ))}

                        </Grid>
                      }
                      
                      <Options open={options} id={product?.url_or_id}/>

                    </Grid>
                    
                  </>
                }
                

              </>
              }
                  
            </Box>

            </Box>
          </Box>

        </Modal> 

:

        <SwipeableDrawer
          anchor={'bottom'}
          open={size(product)>0||false}
          onClose={onClose}
          onOpen={()=>{}}
          maxWidth
          sx={{
            zIndex: 1201,
            '& .MuiPaper-root': {
              "&" : {
                borderTopLeftRadius: 15,
                borderTopRightRadius: 15,
              },
            },
          }}
        >

          <Container maxWidth="sm" sx={{height: '90vh', p: 0, }}>
          
            { loading ?

              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                height="100%"
              >

                <CircularProgress color="inherit" />

                <Typography variant="subtitle2" gutterBottom  align="center" sx={{ mt: 2.5 , ml: 5, mr: 5}}>

                  Загружаем...

                </Typography>
                
              </Box>

              :

              <>

                { status === 'result-null' ?
                  
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    height="80vh"
                  >

                    <CircularProgress color="inherit" />

                    <Typography variant="subtitle2" gutterBottom  align="center" sx={{ mt: 2.5 , ml: 5, mr: 5}}>

                      Автомабиль продан

                    </Typography>
                    
                  </Box>

                :
                  
                  <>

                    <div style={{
                      backgroundImage: `url(${data?.photo?.[0]?.formats?.small ? replaceImageFormat(data?.photo?.[0]?.formats?.small?.url?.replace(REACT_APP_MINIO_URL_FROM, REACT_APP_MINIO_URL_TO)) :  replaceImageFormat(data?.photo?.[0]?.url?.replace(REACT_APP_MINIO_URL_FROM, REACT_APP_MINIO_URL_TO))})`,

                      backgroundPosition: `center`,
                      backgroundSize: `cover`,
                      backgroundRepeat: `no-repeat`,
                      height: '250px',
                      borderRadius: 15,
                      borderBottomLeftRadius: 0,
                      borderBottomRightRadius: 0,
                      marginBottom: 15,
                    }}/>

                    <Container maxWidth="sm" sx={{ p: 2.5, pt: 0 }}>
                      
                      <MobileRows data={data} company={company}/>

                      { moreOptionsButton &&
                        <>
                        
                          <Options open={options} id={product?.url_or_id}/>

                          <Button
                          
                          startIcon={<FormatAlignJustifyIcon/>}
                          fullWidth
                          sx={{
                            mt: 2,
                            pt: 3,
                            pb: 3,
                            height: 10,
                            ...(company?.background_color_for_light_theme !== null && {
                              opacity: 0.9,
                              backgroundColor: 'rgba(0, 0, 0, 0.6)',
                            }),
                          }}
                          onClick={()=>{
                            
                            window?.navigator?.vibrate && window?.navigator?.vibrate(10);

                            setOptions(!options)
                          }}
                          >

                            Подробней

                          </Button>
                        </>
                      }

                      <Grid container spacing={2} sx={{ mt: 1.5 }}>
                        
                        <Grid xs={4}>
                            
                          <ButtonAdditionalFeatures
                          name={ checkingProductBookmarks ? <>В Избранное</> : <>Удалить</>}
                          icon={checkingProductBookmarks ? <BookmarkBorderIcon fontSize='large'/> : <BookmarkIcon fontSize='large'/>}
                          onClick={()=>{
                            
                            window?.navigator?.vibrate && window?.navigator?.vibrate(10);
                            
                            const isPresent = bookmarks.some((item: any) => item.id === product?.id );

                            if (isPresent) {

                              const updatedArray = bookmarks.filter((item: any) => !(item.id === product?.id));
                              return setBookmarks(updatedArray);
                            } 
                              
                            return setBookmarks((prevArray:any) => [...prevArray, product]);
                          }}/>

                        </Grid>

                        <Grid xs={4}>
                          
                          <ButtonAdditionalFeatures
                          name={'QR код'}
                          icon={<QrCode2Icon fontSize='large'/>}
                          onClick={()=>{

                            window?.navigator?.vibrate && window?.navigator?.vibrate(10);
                            setQrcode(true)
                          }}/>

                        </Grid>

                        <Grid xs={4}>
                            
                          <ButtonAdditionalFeatures
                            name={'Ссылка'}
                            icon={<InsertLinkIcon fontSize='large'/>}
                            onClick={()=>{

                              window?.navigator?.vibrate && window?.navigator?.vibrate(10);
                              handleClickClipBoardWriteText()
                            }}/>

                        </Grid>
                        
                      </Grid>

                      <Button
                      href={`https://t.me/share/url?url=https://t.me/${company?.telegram_bot}/app?startapp=L${product?.id}&text=${product?.brand?.name} ${product?.model?.name}`}
                      endIcon={<ShareIcon/>}
                      fullWidth
                      sx={{
                        mt: 2,
                        pt: 3,
                        pb: 3,
                        height: 10,
                        ...(company?.background_color_for_light_theme !== null && {
                          opacity: 0.9,
                          backgroundColor: 'rgba(0, 0, 0, 0.6)',
                          boxShadow: 'none',
                        }),
                      }}>

                        Поделиться

                      </Button>

                      { ( morePhotosButton && !morePhotos ) &&
                        <>
                          {( data?.photos_aws?.length > 0 || data?.photos?.photos?.length > 1 || data?.auction_sheet ) &&
                            <Button
                              startIcon={<LinkedCameraOutlinedIcon/>}
                              fullWidth
                              sx={{
                                mt: 5,
                                  backgroundColor: 'rgba(0, 0, 0, 0)',
                                  mixBlendMode: 'difference',
                                  boxShadow: 'none',
                              }}
                              onClick={()=>{
                                window?.navigator?.vibrate && window?.navigator?.vibrate(10);
                                setMorePhotos(!morePhotos)
                              }}
                            >

                              Загрузить фотографии

                            </Button>
                          }
                        </>
                      }

                      { morePhotos && data?.donor?.name !== 'exportcar.jp' &&
                          <>
                          {data?.photos?.photos?.map((item: any, i:number) => (
                            <>
                              { i > 0 && 
                              
                                <div
                                key={i}
                                style={{
                                  backgroundImage: `url(${REACT_APP_FAKE_AWS}/image?image=${item})`,
                                  backgroundPosition: `center`,
                                  backgroundSize: `cover`,
                                  backgroundRepeat: `no-repeat`,
                                  height: `220px`,
                                  borderRadius: 15,
                                  marginTop: 20,
                                  //boxShadow: '0px 0px 16px 7px rgba(0,0,0,0.17)'// лагает на слабых устройствах и в некоторых мобильных браузерах
                                }}/>
                              }
                            </>
                          ))}
                        </>
                      }

                      { morePhotos && data?.donor?.name === 'exportcar.jp' &&
                          <>

                          { data?.auction_sheet && 
                            <>
                              
                              <div
                              style={{
                                backgroundImage: `url(${data?.auction_sheet?.formats?.small ? replaceImageFormat(data?.auction_sheet?.formats?.small?.url?.replace(REACT_APP_MINIO_URL_FROM, REACT_APP_MINIO_URL_TO)) :  replaceImageFormat(data?.auction_sheet?.url?.replace(REACT_APP_MINIO_URL_FROM, REACT_APP_MINIO_URL_TO))})`,

                                backgroundPosition: `center`,
                                backgroundSize: `cover`,
                                backgroundRepeat: `no-repeat`,
                                height: `360px`,
                                borderRadius: 15,
                                marginTop: 20,
                                //boxShadow: '0px 0px 16px 7px rgba(0,0,0,0.17)'// лагает на слабых устройствах и в некоторых мобильных браузерах
                              }}/>
                              
                            </>
                          }

                          {data?.photos_aws?.length > 0 && data?.photos_aws?.map((item: any, i:number) => (
                            <>
                              { i > 0 && 
                              
                                <div
                                key={i}
                                style={{
                                  backgroundImage: `url(${item?.formats?.small ? replaceImageFormat(item?.formats?.small?.url?.replace(REACT_APP_MINIO_URL_FROM, REACT_APP_MINIO_URL_TO)) :  replaceImageFormat(item?.url?.replace(REACT_APP_MINIO_URL_FROM, REACT_APP_MINIO_URL_TO))})`,

                                  backgroundPosition: `center`,
                                  backgroundSize: `cover`,
                                  backgroundRepeat: `no-repeat`,
                                  height: `220px`,
                                  borderRadius: 15,
                                  marginTop: 20,
                                  //boxShadow: '0px 0px 16px 7px rgba(0,0,0,0.17)'// лагает на слабых устройствах и в некоторых мобильных браузерах
                                }}/>
                              }
                            </>
                          ))}
                        </>
                      }


                    </Container>

                    <Box sx={{ height: 80 }}/>
                    
                  </>
                }
                
              </>
            }

          </Container>

        </SwipeableDrawer>

        }

        <QRcode open={qrcode} setOpen={setQrcode} data={`https://t.me/${company?.telegram_bot}/app?startapp=L${product?.id}`}/>

        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={shareLot||false}
          autoHideDuration={2500}
          onClose={()=>{setShareLot(!shareLot)}}
          message="Ссылка скопирована"
          sx={{
            '& .MuiPaper-root': {
              borderRadius: 2,
              zIndex: 10000,
            },
            '& .MuiSnackbarContent-message ': {
              display: 'block',
              textAlign: "center",
              width: '100%',
            },
          }}
        />
        
    </>
  )
}

export default Product

import { FC, useEffect } from 'react'

import { useQRCode } from 'next-qrcode'

import { useRecoilValue } from 'recoil'

import { companyStateAtom } from '@state/company'
import { pageStateAtom } from '@state/page'
import { filtersStateAtom } from '@state/filters'

import Box from '@mui/material/Box'

import DesktopOrMobileOrBot from '@components/DesktopOrMobileOrBot'

const QRcode: FC<any> = ({open, setOpen, data, onOpen=()=>{}, onClose=()=>{}, }) => {
  
  const { SVG } = useQRCode();

  const company = useRecoilValue<any>(companyStateAtom)
  const page = useRecoilValue<any>(pageStateAtom)
  const filters = useRecoilValue<any>(filtersStateAtom)
  
  useEffect(()=>{

    window?.navigator?.vibrate && window?.navigator?.vibrate(10);

    if(open){
      return onOpen();
    }

    onClose()
  }, [open])

  const getData = () => {

    if(!data) {

      if(page === `brands`){

        return `https://t.me/${company?.telegram_bot}/app`
      }

      if(page === `models`){

        const params = `B${filters?.brand?.id}`

        return `https://t.me/${company?.telegram_bot}/app?startapp=${params}`
      }

      if(page === `products`){

        const params = `B${filters?.brand?.id}M${filters?.model?.id}`

        return `https://t.me/${company?.telegram_bot}/app?startapp=${params}`
      }
    }

    return data
  }

  return (

    <DesktopOrMobileOrBot open={open || false} onClose={()=>{setOpen(false); onClose()}} sx={{zIndex: 1203}}>

      <Box display={'flex'} alignItems={'center'} flexDirection={'column'}>

        <SVG
          text={getData()}
          options={{
            margin: 2,
            width: 250,
            color: {},
          }}
        />

      </Box>

    </DesktopOrMobileOrBot>
  )
}

export default QRcode

import { atom, selector } from "recoil"

export const callBackCalculatorPriceStateAtom = atom({
  key: "callBackCalculatorPriceStateAtom",
  default: false,
})

export const callBackCalculatorPriceStateSelector = selector({
  key: "callBackCalculatorPriceStateSelector",
  get: ({ get }) => {
    const callBackCalculatorPrice = get(callBackCalculatorPriceStateAtom)
    return callBackCalculatorPrice
  }
})


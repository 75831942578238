import { FC, ReactNode  } from 'react'

import { useRecoilValue } from 'recoil'

import { effectsStateAtom } from '@state/effects'

import SwipeableDrawer from '@mui/material/SwipeableDrawer'

import useMediaQuery from '@mui/material/useMediaQuery'

interface I {
  open: boolean;
  onOpen: () => void;
  onClose: () => void;
  anchor: "top" | "left" | "right" | "bottom" | undefined;
  disableSwipeToOpen?: boolean;
  transitionDuration?: any,
  sx?: any;
  maxWidth?: boolean;
  PaperProps?: any; // Include a '?' before the colon to make the property optional

  children: ReactNode;
}

const CustomSwipeableDrawer: FC<I> = ({ open=false, onOpen=()=>{}, onClose=()=>{}, sx, maxWidth, anchor='top', disableSwipeToOpen=true, transitionDuration, children, PaperProps }) => {

  const matchesUseMediaQuery = useMediaQuery('(max-width:420px)')

  const effects = useRecoilValue<boolean|null>(effectsStateAtom)

  let isInIframe;

  try {
    isInIframe = window.self !== window.top;
  } catch (e) {
    isInIframe = true;
  }
  
  if (isInIframe) {

    
    if(anchor==='bottom' && window.location.hostname.includes('webapp')) {

      //console.log('fix telegram web version in iframe (telegram auto add main buttom)') 

      anchor='top'
    }
  }
  
  return (
      <>
        <SwipeableDrawer
          
          disableSwipeToOpen={disableSwipeToOpen}

          disableBackdropTransition={true} // без этой опции лагает на слабых устройствах и в некоторых мобильных браузерах

          anchor={anchor}

          open={open || false}

          onClose={onClose}
          onOpen={onOpen}

          transitionDuration={{ appear: 500, enter: 500, exit: 500 }} // Добавление transitionDuration
          
          minFlingVelocity={50}
          
          {...(PaperProps ? { PaperProps } : {})}

          sx={ sx ? {...sx, "&" : {
              ...((effects || effects === null) ? {
                //backdropFilter: "blur(3px)" // лагает на слабых устройствах и в некоторых мобильных браузерах
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
              }:
              {
                backdropFilter: "unset"
              }
            ),
              width: "100%",
            },
           } : { 
            "&" : {
              ...((effects || effects === null) ? {
                //backdropFilter: "blur(3px)" // лагает на слабых устройствах и в некоторых мобильных браузерах
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
              }:
              {
                backdropFilter: "unset"
              }
            ),
              width: "100%",
            },
            '& .MuiPaper-root': {
              borderTopLeftRadius: 15,
              borderTopRightRadius: 15,
            },
          }}
          PaperProps={{
            style: {
              maxWidth: maxWidth ? !matchesUseMediaQuery ? 420 : 'unset' : 'unset',
              width: '100%',
              marginLeft: 'auto',
              marginRight: 'auto',
            },
          }}
        >

          {children}

        </SwipeableDrawer>
    </>
  )
}

export default CustomSwipeableDrawer

import { FC } from 'react'

import { useRecoilState } from 'recoil'

import { globalProgressBackdropStateAtom } from '@state/globalProgressBackdrop'

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const GlobalProgressBackdrop: FC<any> = () => {
  
  const [globalProgressBackdrop, setGlobalProgressBackdrop] = useRecoilState<boolean>(globalProgressBackdropStateAtom)

  const handleClose = () => {
    setGlobalProgressBackdrop(false);
  };

  return (
    <Backdrop
    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
    open={globalProgressBackdrop}
    onClick={handleClose}
  >
    <CircularProgress color="inherit" />
  </Backdrop>
  )
}

export default GlobalProgressBackdrop

import { atom, selector } from "recoil"
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

export const trainingStateAtom = atom({
  key: "trainingStateAtom",
  default: {
    active: false,
    completed: false,
    step: 0,
    brand: 'volkswagen', // по key в коллекции brands из базы данных 
    model: 'taro', // по key в коллекции models из базы данных 
    steps: [
      {
        step: 0,
        name: 'Выбор бренда',
        swiper: null,
        button: {
          show: false,
          text: '',
        },
        description: {
          show: true,

          position: 'bottom',
          text: 'Быстрое обучение <br><br> Сначала нужно выбрать бренд Volkswagen'
        }
      },
      {
        step: 1,
        name: 'Выбор модели',
        swiper: null,
        button: {
          show: false,
          text: '',
        },
        description: {
          show: true,

          position: 'bottom',
          text: 'Выберите модель Taro'
        }
      },

      {
        step: 2,
        name: 'Откроем лот',
        swiper: null,
        button: {
          show: false,
          nextStep: 5,
          text: 'Пропустить',
        },
        description: {
          show: true,

          position: 'bottom',
          text: 'Нажмите на лот для просмотра деталей'
        }
      },

      {
        step: 3,
        name: 'пакаазываем как посмотреть допольнительные детали лота сдевав свайп снизу-вверх',
        swiper: {
          show: true,
          direction: 'horizontal',
          position: {
            start: {
              x: 'calc(50vw - 12px)',
              y: 'calc(80vh - 100px)',
            }
          },
          from: 'bottom',
        },
        button: {
          show: false,
          text: '',
        },
        description: {
          show: true,

          position: 'top',
          text: 'Чтобы показать больше деталей, сделайте свайп снизу - вверх' 
        }
      },

      {
        step: 4,
        name: 'Закрыть лот свайпом сверху - вниз',
        swiper: {
          show: false,
          name: 'азываем как закрыть меню свайпом',
          direction: 'horizontal',
          position: {
            start: {
              x: 'calc(50vw - 12px)',
              y: 'calc(25vh - 25px)'
            }
          },
          from: 'top',
        },
        button: {
          show: false,
          text: '',
        },
        description: {
          show: true,

          position: 'bottom',
          text: 'Чтобы закрыть лот, сделайте свайп сверху - вниз'
        }
      },

      {
        step: 5,
        name: 'Фильтры открыть',
        swiper: null,
        button: {
          show: false,
          text: 'Далее',
        },
        description: {
          show: true,

          position: 'bottom',
          text: 'Откройте фильтры'
        }
      },

      {
        step: 6,
        name: 'Фильтры закрыть',
        swiper: null,
        button: {
          show: false,
          text: 'Далее',
        },
        description: {
          show: true,

          position: 'bottom',
          text: 'Примените фильтры'
        }
      },

      {
        step: 7,
        name: 'Откроем меню',
        swiper: null,
        button: {
          show: false,
          text: 'Далее',
        },
        description: {
          show: true,

          position: 'center',
          text: 'Откройте меню'
        }
      },

      {
        step: 8,
        name: 'Закрыть меню',
        swiper: {
          show: false,
          name: 'азываем как закрыть меню свайпом',
          direction: 'horizontal',
          position: {
            start: {
              x: 'calc(10vw - 12px)',
              y: 'calc(50vh - 25px)'
            }
          },
          from: 'left',
        },
        buttons: {
          top: '',
          left: '',
          right: '',
          bottom: '',
        },
        description: {
          show: true,

          position: 'bottom',
          text: 'Чтобы закрыть меню сделайте свайп'
        }
      },

      {
        step: 9,
        name: 'Финал',
        swiper: null,
        buttons: {
          top: '',
          left: '',
          right: '',
          bottom: '',
        },
        description: {
          show: true,

          position: 'bottom',
          text: 'Мы познакомили вас с основными функциями дальше вы справитесь сами!'
        }
      },


    ]
  },
  effects_UNSTABLE: [persistAtom],

})

export const trainingStateSelector = selector({
  key: "trainingStateSelector",
  get: ({ get }) => {
    const training = get(trainingStateAtom)
    return training
  }
})


import { atom, selector } from "recoil"

export const skinStateAtom = atom({
  key: "skinStateAtom",
  default: 'default', /* default, catalog, catalogFixFilters */
})

export const skinStateSelector = selector({
  key: "skinStateSelector",
  get: ({ get }) => {
    const skin = get(skinStateAtom)
    return skin
  }
})



import { FC, useState, useEffect, useRef, useMemo } from 'react'

import InfiniteLoader from "react-window-infinite-loader"

import axios from 'axios'
import qs from 'qs'

import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Container from '@mui/material/Container'

import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { LoadingButton } from '@mui/lab'
import TextField from '@mui/material/TextField'
import Fade from '@mui/material/Fade'
import Button from '@mui/material/Button'
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'

import CircularProgress from '@mui/material/CircularProgress'

import { useForm, Controller } from 'react-hook-form'

import { useRecoilValue, useSetRecoilState, useRecoilState,useRecoilCallback } from 'recoil'

import { modelsStateAtom } from '@state/models'
import { modelsScrollStateAtom } from '@state/modelsScroll'
import { modelsSearchStateAtom } from '@state/modelsSearch'
import { aiStateAtom } from '@state/ai'
import { filtersStateAtom } from '@state/filters'
import { companyStateAtom } from '@state/company'

import { pageStateAtom } from '@state/page'
import { openSearchFormModelStateAtom } from '@state/openSearchFormModel'

import { globalProgressBackdropStateAtom } from '@state/globalProgressBackdrop'

import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import { FixedSizeList as List , ListChildComponentProps } from 'react-window'

import useMeasure from '@hooks/useMeasure'
import { styled } from '@mui/material/styles'

import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"

import SwipeableDrawer from '@components/SwipeableDrawer'
import ScrollToTopVirtualList from '@components/Buttons/ScrollToTopVirtualList'

import useMediaQuery from '@mui/material/useMediaQuery';

import useTraining from '@hooks/useTraining'

import api from '@services/api'

const renderRow = (props: ListChildComponentProps) => {

  const { data, index, style } = props;

  const checkedNotNullItem = (item: any, filters: any) => {

    let count = 0

    for(const country of filters?.countries){

      if(country.value === 'Korea'){
        count = count + item?.count_korea
      }else
      if(country.value === 'China'){
        count = count + item?.count_china
      }else
      if(country.value === 'Japan'){
        count = count + item?.count_japan
      }else
      if(country.value === 'UAE'){
        count = count + item?.count_uae
      }else
      if(country.value === 'USA'){
        count = count + item?.count_usa
      }
    }

    return count
  }
  
  const count = checkedNotNullItem(data?.data[index], data?.filters)
  
  return (
    <ListItem style={style} sx={{padding: 0,}} key={index} component="div" disablePadding onClick={() => {

      if( data.training &&  data.training.active && data.training.step === 1 ) {

        if(data?.data[index]?.key !== data.training.model) {
          
          return;

        }else{

          data.training.next()
        }
      }

      window?.navigator?.vibrate && window?.navigator?.vibrate(10);
      data.onClick(data?.data[index])
    }}>
      <ListItemButton disableRipple sx={{
        height: 'unset',
        borderBottom: `1px solid black`,
        borderRadius: `10px`,
        margin: 1,
        /* boxShadow: `0px 0px 6px 3px rgba(0,0,0,0.1)`, */

        ...( data.training &&  data.training.active && data.training.step === 1 && data?.data[index]?.key === data.training.model && {
          ...data.training.trainingAnimation
        })
      }}>
        <Stack
        sx={{ width: '100%' }}
        key={data?.data[index].key}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}>
        <Item sx={{ opacity: count > 0 ? 1 : 0.5 }}>
          {data?.data[index].name}
        </Item>
        { count > 0 &&
          <Item sx={{ opacity: count > 0 ? 1 : 0.5 }}>
            {count} шт
          </Item>
        }
        </Stack>
      </ListItemButton>
    </ListItem>
  )
}

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}))

const Schema = yup.object().shape({
  name: yup.string().required("Необходимо ввести ключ"),
})

const mergeCarDataWithNoDuplicates = (

  existingData: any[],
  newData: any[]
): any[] => {

  const uniqueUrls = new Set(existingData.map((item:any) => item.id));

  const filteredNewData = newData.filter((item:any) => !uniqueUrls.has(item.id));

  const mergedData = [...existingData, ...filteredNewData];

  return mergedData;
}

const Models: FC<any> = () => {

  const { windowSize } = useMeasure()

  const { training } = useTraining()

  const listRef = useRef<any>(null)
  const fixScroll = useRef<any>(false)
  
  const { handleSubmit, control, getValues, setValue, formState: { errors } } = useForm({
    resolver: yupResolver(Schema),
    defaultValues: {
      name: '',
    }
  })

  const ai = useRecoilValue<boolean>(aiStateAtom)

  const company = useRecoilValue<any>(companyStateAtom)

  const [data , setData] = useRecoilState<any>(modelsStateAtom)
  //const [scroll , setScroll] = useRecoilState<number>(modelsScrollStateAtom)
  
  const setGlobalProgressBackdrop = useSetRecoilState<boolean>(globalProgressBackdropStateAtom)

  
  const setScroll = useSetRecoilState<number>(modelsScrollStateAtom)
  const [scroll, setComponentScroll] = useState<number>(0)



  const [openSearchFormModel, setOpenSearchFormModel] = useRecoilState<boolean>(openSearchFormModelStateAtom)
  const [modelsSearch, setBrandsSearch] = useRecoilState<string>(modelsSearchStateAtom)

  const filteredData = useMemo(() => {
    return data.filter((item:any) =>
    typeof item.name === 'string' && item.name.toLowerCase().includes(modelsSearch.toLowerCase())
  );
  }, [data, modelsSearch])
  
  useEffect(() => {
    window?.navigator?.vibrate && window?.navigator?.vibrate(10);
  }, [openSearchFormModel])

  const setPage = useSetRecoilState<any>(pageStateAtom)

  const [filters, setFilters] = useRecoilState<any>(filtersStateAtom)

  const page = useRef(0)
  const hasNextPage = useRef(true)

  const [loading, setLoading] = useState<boolean>(false)

  const onHeadlerSearch = (e:any) => {  setScroll( 0 ); setBrandsSearch(e.target.value); if( listRef.current ) listRef.current.scrollTo(0); }

  const onClick = (data: any) => {

    setGlobalProgressBackdrop(true)

    setTimeout(() => {

      setFilters((prevState:any) => ({ ...prevState, model: data }))

      setPage('products')
      
    }, 250)
  }

  const loadData = () => {

    if(!hasNextPage.current) return;

    setLoading(true)

    page.current = page.current + 1

    const query = qs.stringify({
      ai,
      randomSort: false,
      filters: {
        [`brand`]: {
          id: filters?.brand?.id,
        },
        [`active`]: {
          $eq: true,
        }
      },
      /* populate: {
        image: `*`
      }, */
      pagination: {
        pageSize: 100,
        page: page.current,
      },
      /* sort: "name:ASC", */

      locale: ['ru'],
    }, {
      encodeValuesOnly: true, // prettify URL
    })

    api({ collection: 'models', query }).then((data) => {
      setGlobalProgressBackdrop(false)

        
        if(data?.data){

          //setData((prevData:any) => [...prevData, ...loaded?.data?.data])

          setData((prevData: any) => mergeCarDataWithNoDuplicates(prevData, data?.data));

          if(scroll !== 0 && listRef.current) listRef.current.scrollTo(scroll);
        }
        
        setLoading(false)
      

    }).catch((error:any) => {

      console.log(error)
    })


  }

  const loadMoreData = () => { if (!loading) loadData(); }

  useEffect(() => {

    if(data.length === 0) loadData();
/* 
    if(scroll !== 0 && listRef.current) listRef.current.scrollTo(scroll); */

  }, [])


  const isItemLoaded = (index: number) => { return index < data.length }

  const handleScroll = (scroll:any) => setScroll( scroll.scrollOffset );

  const searchFormTextFieldRef = useRef<any>(null)

  useEffect(() => {
    if (openSearchFormModel) {

      if(searchFormTextFieldRef.current) searchFormTextFieldRef.current.focus();
    } else {

      if(searchFormTextFieldRef.current) searchFormTextFieldRef.current.blur();
    }
  }, [openSearchFormModel])

  const matchesUseMediaQuery = useMediaQuery('(max-width:340px)');

  const getAtomValue = useRecoilCallback(({ snapshot }) => async () => {
    
    const value = await snapshot.getPromise(modelsScrollStateAtom);
    return value;
  })
  
  const fetchAtomValue = async () => {

    const value = await getAtomValue();

    if(value !== 0 && listRef.current) {

      if(!fixScroll.current&&!training.active){

        fixScroll.current = true;
        listRef.current.scrollTo(value);

        setComponentScroll(value);
      }
    };
  }
 
  fetchAtomValue()

  useEffect(() => {

    if( training &&  training.active && training.step === 1 ) {

      console.log(`eee del`)

      let lookBrand:number|null = null;

      for(let i = 0; i < data.length; i++) {
        
        if(data[i]?.key === training.model) {

          console.log(`eee model ${training.model}`)

          lookBrand = i;
        }
      }

      if(lookBrand && listRef.current) {
        console.log(`eee del 2`)

        if(!fixScroll.current){
          console.log(`eee del 3`)

          listRef.current.scrollToItem(lookBrand, "center");
        }
      }
    }
  }, [data])


  return <Container maxWidth="sm" sx={{ pt: 0}}>

          <Typography variant="subtitle2" gutterBottom align="center" sx={{ mt: 3}}>

            {filters?.brand?.name} {/* Выберите модель */}

          </Typography>

          <ScrollToTopVirtualList listRef={listRef} atom={modelsScrollStateAtom}/>

          { data.length > 0 &&
          
            <Box sx={{ 
              width: matchesUseMediaQuery ? windowSize.width - 20 : `unset`,
            }}>

              <InfiniteLoader
              isItemLoaded={isItemLoaded}
              itemCount={data.length + 1}
              loadMoreItems={loadMoreData}
              >
              {({ onItemsRendered, ref }) => (
                <List
                  height={windowSize.height - 120}
                  width={ matchesUseMediaQuery ? (windowSize.width - ( matchesUseMediaQuery ? 0 : 40)) : 'unset'}

                  
                  itemCount={filteredData.length}
                  itemSize={80}
                  itemData={{
                    data: filteredData,
                    onClick: onClick,
                    company,
                    filters,
                    training
                  }}
                  onItemsRendered={onItemsRendered}
                  ref={(list) => {
                    ref(list);
                    if (list) listRef.current = list;
                  }}
                  onScroll={handleScroll} // Обработчик события прокрутки
                >

                  {renderRow}

                </List>
              )}

              </InfiniteLoader>

            </Box>

          }

          { loading &&

            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"  // Center vertically
              justifyContent="center"  // Center horizontally
              height="70vh"  // Optional: Set a specific height for centering in the viewport
            >

              <CircularProgress color="inherit" />

              <Typography variant="subtitle2" gutterBottom  align="center" sx={{ mt: 2.5 , ml: 5, mr: 5}}>

                Загружаем данные...

              </Typography>
              
            </Box>

          }

          {  !loading && data.length === 0 &&

            <Typography variant="subtitle2" gutterBottom color="primary" align="center" sx={{ mt: 1, pt: '50%'}}>

              Что-то пошло не так! Попробуйте зайти позже.
              
            </Typography>
          }

          <Fade in={!openSearchFormModel || false} unmountOnExit>
            <Button   sx={{
              width: 50,
              height: 50,
              minWidth: 'unset',
              borderRadius:  `50%`,
              position: 'fixed',
              bottom: 15,
              right: -15,
              transform: 'translateX(-50%)',
              bgcolor: 'primary.company',
              "&:hover": {
                bgcolor: 'primary.company',
                opacity: 0.7,
              },
              "&:active": {
                bgcolor: 'primary.company',
                opacity: 0.7,
              },
            }} variant="contained" /* endIcon={<SearchIcon />} */ onClick={()=>{setOpenSearchFormModel(!openSearchFormModel)}}>
              {/* Поиск */} <SearchIcon fontSize='small'/> { modelsSearch && <> {modelsSearch}</> }
            </Button>
          </Fade>

          <SwipeableDrawer
            anchor={'bottom'}
            open={openSearchFormModel||false}
            onClose={()=>{setOpenSearchFormModel(!openSearchFormModel)}}
            onOpen={()=>{}}
            sx={{
              'justifyContent': 'center','display': 'flex', 'alignitems': 'center',
              '& .MuiPaper-root': {
                heightMin: 100,
                /* left:  `calc(50% - 170px)` , */
                "&" : {
                  backdropFilter: "blur(2px)",
                  width: "100%",
                  borderTopLeftRadius: 15,
                  borderTopRightRadius: 15,
                },
              },
            }}
          >
            <Container maxWidth="sm" sx={{minHeight: '20vh', p: 2 }}>

              <Box
                component="form"
                sx={{
                  //padding: 1,
                  '& > :not(style)': { m: 1, width: '95.5%' },
                }}
                noValidate
                autoComplete="off"
              >

                <Controller
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { isTouched, isDirty, error },
                  }) => (
                    <TextField
                      onClick={()=>{window?.navigator?.vibrate && window?.navigator?.vibrate(10);}}
                      autoFocus={false}
                      placeholder="Введите модель"
                      value={modelsSearch}
                      onBlur={onBlur}
                      //inputRef={ref}
                      inputRef={(input) => {
                        ref(input);
                        if (input) searchFormTextFieldRef.current = input;
                      }}
                      onChange={(e:any)=>{
                        onChange(e)
                        onHeadlerSearch(e)
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end" sx={{ marginRight: 1 }}>
                
                              <IconButton onClick={()=>{
                                
                                if(modelsSearch === '') return setOpenSearchFormModel(!openSearchFormModel);

                                setValue('name', '')
                                setBrandsSearch('')
                              }} edge="end">
                                <ClearIcon />
                              </IconButton>
                            
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                  name="name"
                  control={control}
                  rules={{ required: true }}
                />

                {/* {errors?.name && <Typography variant="overline" color="error">{errors.name.message}</Typography>} */}

                { modelsSearch ?
                  <LoadingButton loading={loading} variant="outlined" onClick={()=>{setOpenSearchFormModel(!openSearchFormModel)}} endIcon={<SearchIcon />}
                  sx={{
                    height:  40,

                    bgcolor: 'primary.company',
                    "&:hover": {
                      bgcolor: 'primary.company',
                      opacity: 0.9,
                    },
                    "&:active": {
                      bgcolor: 'primary.company',
                      opacity: 0.9,
                    },
                  }}>
                    
                    Показать
                    
                    </LoadingButton>
                  :
                  <LoadingButton loading={loading} variant="outlined" onClick={()=>{setOpenSearchFormModel(!openSearchFormModel)}}
                  sx={{
                    height:  40,

                    bgcolor: 'primary.company',
                    "&:hover": {
                      bgcolor: 'primary.company',
                      opacity: 0.9,
                    },
                    "&:active": {
                      bgcolor: 'primary.company',
                      opacity: 0.9,
                    },
                  }}>
                    
                    Показать
                    
                  </LoadingButton>
                }
                
              </Box>

            </Container>

          </SwipeableDrawer>

        </Container>
}

export default Models


import { FC, useMemo } from 'react'

import { useRecoilValue } from 'recoil'
import { companyStateAtom } from '@state/company'

import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Rating from '@mui/material/Rating'

import PlayCircleIcon from '@mui/icons-material/PlayCircle'

import parseDynamicComponent from '@utils/parseDynamicComponent'
import {useBotDetect} from '@hooks/useBotDetect'
import {useMobileDetect} from '@hooks/useMobileDetect'

import { replaceImageFormat } from '@utils/replaceImageFormat'

const  { REACT_APP_MINIO_URL_FROM, REACT_APP_MINIO_URL_TO } = process.env

const Reviews: FC<any> = () => {

  const company = useRecoilValue<any>(companyStateAtom)

  const bot = useBotDetect()
  const mobile =   useMobileDetect()

  const reviews:any = useMemo(() => parseDynamicComponent(company?.reviews?.reviews),[company?.reviews?.reviews])
  
  const margin = 15

  return (
      <>
            
        <Container id={'reviews'} sx={{pt: 0, pl: 0, pr: 0,  mt: { xs: bot ? 0 : 5, md: 5 },}}>

          { reviews?.header?.title &&
            <>
            
            <Typography variant="h6" gutterBottom align="center"> { reviews?.header?.title } </Typography>
            </>
          }

          { reviews?.header?.caption &&

          <Typography variant="caption" gutterBottom display="block" align="center"> { reviews?.header?.caption } </Typography>
          }

          { reviews?.header?.body &&
            
            <Typography variant="body2" dangerouslySetInnerHTML={{__html: reviews?.header?.body?.text}}/>
          }

          <Container sx={{ overflowY: 'hidden', overflowX: 'auto', pl: 0, pr: 5, pt: 0,
            ...(mobile && {
              pl: 0, pr: 0,
            }),
          }}>

            <Box
              sx={{
                display: 'flex',
                flexWrap: bot  ? 'wrap' : mobile ? 'nowrap' : 'nowrap',
                p: 2,
                overflowX: 'auto', scrollSnapType: 'x mandatory', '& > *': { scrollSnapAlign: 'center', }, 
                '& > *:first-of-type': {
                  ...((mobile && !bot) && {
                    marginLeft: `${margin}px !important`,
                  }),
                },
              }}
            >

              {reviews?.data?.map((item:any, index:number) => (

                <Box key={`reviews-${index}`}>
                  
                  { item.type === 'video' && 
                
                    <Box key={`reviews-${index}`}
                    boxShadow={2}
                    borderRadius={2}
                    marginTop={ bot ? 3 : mobile ? 0 : 0 }
                    padding={0} 
                    paddingLeft={ !mobile ? 5 : 0} 
                    paddingRight={ !mobile ? 5 : 0} 
                    sx={{
                      width: '100%',
                      minWidth: mobile ? 'calc(100vw - 60px)' : bot ? 'calc(100vw - 30px)' : 450,
                      mr: (bot || mobile )? 1 : 3,
                    }}>
                      
                      <Typography variant="subtitle1" sx={{m:2, textAlign: 'center'}} display={'flex'} justifyContent={'center'}> {item?.brand?.name} {item?.model?.name} </Typography>

                      <Box sx={{mt: 1, position: 'relative'}} display="flex" justifyContent="center">

                        <Box
                        style={{
                          backgroundImage: `url(${  item?.video_thumbnail?.formats?.thumbnail?.url && replaceImageFormat(item?.video_thumbnail?.formats?.thumbnail?.url?.replace(REACT_APP_MINIO_URL_FROM, REACT_APP_MINIO_URL_TO)) })`,
                          backgroundPosition: `center`,
                          backgroundSize: `cover`,
                          backgroundRepeat: `no-repeat`,
                          height: `140px`,
                          minHeight: `140px`,
                          width: 'calc(100% - 30px)',
                          borderRadius: 15,
                          marginBottom: 15,

                          cursor: 'pointer',
                        }}
                        onClick={()=>{

                          window?.Telegram?.WebApp?.openLink(`${item.external_file}`)
                        }}/>

                          <Box
                          sx={{
                            mt: 1,
                            position: 'absolute',
                            top: 'calc(50% - 10px)',
                            left: '50%',
                            transform: 'translate(-50%, -50%)', 
                            color: 'rgba(255, 255, 255, 1)',
                          }}>
                            
                            <PlayCircleIcon  fontSize='large' sx={{ color: 'rgba(217, 30, 24, 1)'}}/>

                          </Box>

                        </Box>

                      <Box sx={{
                        display: 'flex',
                        flexDirection:'column',
                        justifyContent: 'center',
                        justifyItems:'center',
                        flexWrap: 'wrap',
                        margin: 1,                            
                      }}
                      alignItems={'center'}
                      >

                        <Rating name="read-only" value={item.grade} readOnly />

                        <Typography variant="subtitle1" sx={{mt: 1,}}> {item?.text} </Typography>

                      </Box>

                    </Box>
                  }
                </Box>
              ))}

            </Box>

          </Container>

          { reviews?.footer?.title &&

            <Typography variant="h6" gutterBottom align="center"> { reviews?.footer?.title } </Typography>
          }

          { reviews?.footer?.caption &&

          <Typography variant="caption" gutterBottom display="block" align="center"> { reviews?.footer?.caption } </Typography>
          }

          { reviews?.footer?.body &&
            
            <Typography variant="body2" dangerouslySetInnerHTML={{__html: reviews?.footer?.body?.text}}/>
          }

        </Container>
    
      </>
  )
}

export default Reviews

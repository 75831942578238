
import { FC, useEffect } from 'react'

import { useRecoilState, useRecoilValue } from 'recoil'
import { filtersStateAtom } from '@state/filters'
import { filtersDataStateAtom } from '@state/filtersData'

import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import Chip from '@mui/material/Chip';

import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  customCursor: {
    cursor: 'pointer', // Устанавливаем пользовательский стиль курсора
  },
})

interface Option {
  id: number;
  rgba: string;
  value: string;
  name: string;
}

const options: Option[] = [

  { id: 6, rgba: '38, 194, 129, 1', value: 'Green', name: 'Зеленый' },

  { id: 7, rgba: '255, 255, 255, 1', value: 'White', name: 'Белый' },

  { id: 8, rgba: '217, 30, 24, 1', value: 'Red/purple', name: 'Красный/Фиолетовый' },

    
  { id: 9, rgba: '234, 195, 150, 1', value: 'champagne/brown', name: 'Шампанское/Коричневый' },

  { id: 10, rgba: '0, 0, 0, 1', value: 'Black', name: 'Черный' },


  { id: 14, rgba: '211, 211, 211, 1', value: 'silver/gray', name: 'Серебристый/Серый' },



  { id: 15, rgba: '255, 174, 66, 1', value: 'yellow/orange', name: 'Желтый/Оранжевый' },

  { id: 16, rgba: '45, 85, 255, 1', value: 'blue', name: 'Синий' },

  { id: 126, rgba: '255,223,0, 1', value: 'gold', name: 'Золото' },


]

const Color: FC = () => {

  const classes = useStyles()

  const [filters, setFilters] = useRecoilState<any>(filtersStateAtom)

  const filtersData = useRecoilValue<any>(filtersDataStateAtom)

  useEffect(()=>{}, [filters, filtersData])

  const renderOption = (props:any, option:any) => (
    <li {...props}>
      <Box sx={{ borderRadius: '50%', width: 20, height: 20, mr: 1, backgroundColor: `rgba(${option.rgba})` }} border={ option.value === 'White' ? 1 : 0}/> {option.name}
    </li>
  )

  const handleDelete = (index:number) => {
    const newValues = [...filters.color];
    newValues.splice(index, 1)
    setFilters((prevState:any) => ({ ...prevState, color: newValues }))
  }

  return (
          <Autocomplete
            
            multiple
            limitTags={5}
            size="small" // хорошо заходи бля iphone se

            getOptionDisabled={(option) =>
              filters.color.some((selectedOption:any) => {
                if(selectedOption.name === option.name) return true;
              })
            }

            onChange={(event, newValue) => {
              window?.navigator?.vibrate && window?.navigator?.vibrate(10);
              setFilters((prevState:any) => ({ ...prevState, color: newValue }))
            }}

            defaultValue={filters.color}
            value={filters.color}

            options={options}
            renderOption={renderOption}

            getOptionLabel={(option) => option.name}
            noOptionsText={'Нету донных'}

            renderTags={(value: Option[], getTagProps) =>
              value.map((option:any, index:number) => (
                <Chip
                  /* icon={<Box sx={{ border: 0, borderRadius: '50%', width: 20, height: 20, backgroundColor: `rgba(${option.rgba})`, }} />} */
                  deleteIcon={<></>}
                  {...getTagProps({ index })}
                  sx={{ 
                    width: 20,
                    height: 20,
                    margin: '2px',
                    marginLeft: '10px !important',
                    pl: '5px', 

                    ...(option.value !== 'White' && {
                      border: `0px solid red !important`,
                    }),
                    /* background: `rgba(${option.rgba})`, */ 
                    backgroundColor: `rgba(${option.rgba})`,

                    '& .MuiButtonBase-root': {  
                      border: `0px solid red !important`,
                    },
                    '& .MuiChip-label': {
                      paddingLeft: `0px !important`,
                    },
                   }}
                  onClick={() => handleDelete(index)}
                  variant="outlined"
                />
              ))
            }

            renderInput={(params) => (
              <TextField
              onClick={()=>{window?.navigator?.vibrate && window?.navigator?.vibrate(10);}}
              {...params}
              inputProps={{
                ...params.inputProps,
                readOnly: true,

              }}

              sx={{
                '& .MuiInputBase-root': {
                  cursor: 'pointer', 
                },
                '& .MuiInputBase-input': {
                  cursor: 'pointer', 
                },
                '& .MuiFormLabel-root': {
                  
                  ...(filters.color.length === 0 && {
                    mt: 0.7,
                    
                  }),
                },
              }}
              label="Цвета"
              /* placeholder={ filters.color.length < 1 ? "Выбрать цвета" : ""} */
            />
            )}
          />
  )
}

export default Color;

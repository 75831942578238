import { FC } from 'react'

import useMobileDetect from '@hooks/useMobileDetect'
import useBotDetect from '@hooks/useBotDetect'
import usePlatformDetect from '@hooks/usePlatformDetect'

import Desktop from './Desktop'
import Mobile from './Mobile'
import Bot from './Bot'

const Header: FC = () => {

  const mobile = useMobileDetect()
  const bot = useBotDetect()
  const platform = usePlatformDetect()

  if(!platform) return null;

  return ( <>

  
  {
    platform === 'bot' && bot ? <Bot/> : platform === 'mobile' && mobile ? <Mobile/> : platform === 'desktop' && <Desktop/>
  }
  </> )

}


export default Header

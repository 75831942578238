import { atom, selector } from "recoil"
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

export const modelsStateAtom = atom({
  key: "modelsStateAtom",
  default: [],
  effects_UNSTABLE: [persistAtom],
})

export const modelsStateSelector = selector({
  key: "modelsStateSelector",
  get: ({ get }) => {
    const models = get(modelsStateAtom)
    return models
  }
})



import { FC } from 'react'

import { useRecoilState } from 'recoil'
import { filtersStateAtom } from '@state/filters'

import Grid from '@mui/material/Grid'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'

import { NumericFormat } from 'react-number-format';

const isMobileDevice = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)

const options = [
  { value: 700, name: "0.7" },
  { value: 800, name: "0.8" },
  { value: 1000, name: "1.0" },
  { value: 1100, name: "1.1" },
  { value: 1200, name: "1.2" },
  { value: 1300, name: "1.3" },
  { value: 1400, name: "1.4" },
  { value: 1500, name: "1.5" },
  { value: 1600, name: "1.6" },
  { value: 1700, name: "1.7" },
  { value: 1800, name: "1.8" },
  { value: 1900, name: "1.9" },
  { value: 2000, name: "2.0" },
  { value: 2200, name: "2.2" },
  { value: 2300, name: "2.3" },
  { value: 2400, name: "2.4" },
  { value: 2500, name: "2.5" },
  { value: 2700, name: "2.7" },
  { value: 2800, name: "2.8" },
  { value: 3000, name: "3.0" },
  { value: 3200, name: "3.2" },
  { value: 3300, name: "3.3" },
  { value: 3500, name: "3.5" },
  { value: 3600, name: "3.6" },
  { value: 4000, name: "4.0" },
  { value: 4200, name: "4.2" },
  { value: 4400, name: "4.4" },
  { value: 4500, name: "4.5" },
  { value: 4600, name: "4.6" },
  { value: 4700, name: "4.7" },
  { value: 5000, name: "5.0" },
  { value: 5500, name: "5.5" },
  { value: 5700, name: "5.7" },
  { value: 6000, name: "6.0" },
];

const Price: FC = () => {

  const [filters, setFilters] = useRecoilState<any>(filtersStateAtom)

  const renderOption = (props:any, option:any) => (
    <li {...props}>
      <NumericFormat allowLeadingZeros thousandSeparator=" "  decimalScale={1} displayType="text" value={option.name} suffix={' см3'} />
    </li>
  )

  const optionsReverse = [...options].reverse()

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item xs={6}>
        <Autocomplete
          value={filters.engine_capacity.min}
          onChange={(_, newValue) => {
            window?.navigator?.vibrate && window?.navigator?.vibrate(10);
            setFilters((prevState:any) => ({ ...prevState, engine_capacity: { min: newValue, max: prevState.engine_capacity.max } }))
          }}
          options={options}
          renderOption={renderOption}
          getOptionLabel={(option) => option.name.toString()}
          noOptionsText={'Нет донных'}
          renderInput={(params) => (
            <TextField
              onClick={()=>{window?.navigator?.vibrate && window?.navigator?.vibrate(10);}}
              sx={{
                justifyContent: `center`,
                alignItems: `center`,
              }}
              {...params}
              label="Объем от, см3"
              /* placeholder="Объем от, см3" */
              variant="outlined"
              fullWidth
              inputProps={{
                ...params.inputProps,
                readOnly: true,
                inputMode: isMobileDevice() ? 'numeric' : 'text', // Установка числового режима для клавиатуры на мобильных устройствах
              }}
              InputLabelProps={{sx: {  marginTop: -0.25 } }}

            /> 
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          value={filters.engine_capacity.max}
          onChange={(_, newValue) => {
            window?.navigator?.vibrate && window?.navigator?.vibrate(10);
            setFilters((prevState:any) => ({ ...prevState, engine_capacity: { min: prevState.engine_capacity.min, max:  newValue} }))
          }}
          options={optionsReverse}
          renderOption={renderOption}
          getOptionLabel={(option) => option.name.toString()}
          noOptionsText={'Нет донных'}
          renderInput={(params) => (
            <TextField
              onClick={()=>{window?.navigator?.vibrate && window?.navigator?.vibrate(10);}}
              sx={{
                justifyContent: `center`,
                alignItems: `center`,
              }}
              {...params}
              label="Объем до, см3"
              /* placeholder="Объем до, см3" */
              variant="outlined"
              fullWidth
              inputProps={{
                ...params.inputProps,
                readOnly: true,
                inputMode: isMobileDevice() ? 'numeric' : 'text', // Установка числового режима для клавиатуры на мобильных устройствах
              }}
              InputLabelProps={{sx: {  marginTop: -0.25 } }}

            />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default Price;

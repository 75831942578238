
import { FC, useState, useEffect, useRef, useMemo } from 'react'

import InfiniteLoader from "react-window-infinite-loader"

import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { LoadingButton } from '@mui/lab'
import TextField from '@mui/material/TextField'
import Fade from '@mui/material/Fade'
import Button from '@mui/material/Button'
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';

import CircularProgress from '@mui/material/CircularProgress'

import { useForm, Controller } from 'react-hook-form'

import { useRecoilValue, useSetRecoilState, useRecoilState, useRecoilCallback } from 'recoil'

import { brandsStateAtom } from '@state/brands'
import { brandsScrollStateAtom } from '@state/brandsScroll'
import { brandsSearchStateAtom } from '@state/brandsSearch'

import { pageStateAtom } from '@state/page'
import { companyStateAtom } from '@state/company'
import { filtersStateAtom } from '@state/filters'

import { openSearchFormBrandStateAtom } from '@state/openSearchFormBrand'

import { globalProgressBackdropStateAtom } from '@state/globalProgressBackdrop'


import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import { FixedSizeList as List , ListChildComponentProps } from 'react-window'

import useMeasure from '@hooks/useMeasure'
import { styled } from '@mui/material/styles'

import SendIcon from '@mui/icons-material/Send';

import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"

import SwipeableDrawer from '@components/SwipeableDrawer'
import ScrollToTopVirtualList from '@components/Buttons/ScrollToTopVirtualList'

import VirtualList from "@components/VirtualList"

import Intro from "@components/Intro"

import useMediaQuery from '@mui/material/useMediaQuery';


import useTraining from '@hooks/useTraining'

const  { REACT_APP_MINIO_URL_FROM, REACT_APP_MINIO_URL_TO } = process.env


const renderRow = (props: any) => {

  const { data, index, style } = props;

  const checkedNotNullItem = (item: any, company: any) => {

    let count = 0

    for(const country of company?.countries){

      if(country.value === 'Korea'){
        count = count + item?.count_korea
      }else
      if(country.value === 'China'){
        count = count + item?.count_china
      }else
      if(country.value === 'Japan'){
        count = count + item?.count_japan
      }else
      if(country.value === 'UAE'){
        count = count + item?.count_uae
      }else
      if(country.value === 'USA'){
        count = count + item?.count_usa
      }
    }

    return count
  }
  
  const count = checkedNotNullItem(data?.data[index], data?.company)

  return (
    <>
    
    { index === 0 && data.intro }

    <ListItem 
    style={{
      ...style,
      top: `${parseFloat(style.top) + 150}px`,
      userSelect: 'none',
    }}
    sx={{ padding: 0, }} key={index} component="div" disablePadding onClick={
      () => {

        if( data.training &&  data.training.active && data.training.step === 0 ) {

          if(data?.data[index]?.key !== data.training.brand) {

            console.log(data?.data[index]?.key);
            console.log(data.training.brand);
            console.log(data?.data[index]?.name !== data.training.brand);
            
            return;

          }else{

            data.training.next()
          }
        }

        window?.navigator?.vibrate && window?.navigator?.vibrate(10);
        data.onClick(data?.data[index])
    }}>
      <ListItemButton disableRipple sx={{
            height: 'unset',
            borderBottom: `1px solid black`,
            borderRadius: `10px`,
            margin: 1,

            ...( data.training &&  data.training.active && data.training.step === 0 && data?.data[index]?.key === data.training.brand && {
              ...data.training.trainingAnimation
            })
            
          }}>
        <Stack
          key={data?.data[index].key}
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
        >
          <Item sx={{
            position: `relative`,
            minWidth: `64px`,
              height: `64px`,
          }}>
            <div style={{
              backgroundImage: `url(${data?.data[index]?.image?.url.replace(REACT_APP_MINIO_URL_FROM, REACT_APP_MINIO_URL_TO)})`,
              backgroundPosition: `center`,
              backgroundSize: `cover`,
              backgroundRepeat: `no-repeat`,
              width: `64px`,
              height: `100%`,
              borderRadius: 15,
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
              marginBottom: 15,
              position: `relative`
            }}/>
          </Item>
          <Item sx={{ opacity: count > 0 ? 1 : 0.35 }}>
          {data?.data[index].name} {/* {count} */}
          </Item>
        </Stack>
      </ListItemButton>
    </ListItem>
    </>
  )
}

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
}))


const Schema = yup.object().shape({
  name: yup.string().required("Необходимо ввести ключ"),
})

const Brands: FC<any> = () => {

  const { windowSize } = useMeasure()

  const { training } = useTraining()
  
  const listRef = useRef<any>(null)
  const fixScroll = useRef<any>(false)
  
  const { handleSubmit, control, getValues, setValue, formState: { errors } } = useForm({
    resolver: yupResolver(Schema),
    defaultValues: {
      name: '',
    }
  })
  
  const company = useRecoilValue<any>(companyStateAtom)

  const [data , setData] = useRecoilState<any>(brandsStateAtom)
  
  const setScroll = useSetRecoilState<number>(brandsScrollStateAtom)
  const [scroll, setComponentScroll] = useState<number>(0)

  const setGlobalProgressBackdrop = useSetRecoilState<boolean>(globalProgressBackdropStateAtom)

  const [openSearchFormBrand , setOpenSearchFormBrand] = useRecoilState<boolean>(openSearchFormBrandStateAtom)
  const [brandsSearch, setBrandsSearch] = useRecoilState<string>(brandsSearchStateAtom)

  const filteredData = useMemo(() => {
    return data.filter((item:any) =>
      typeof item.name === 'string' && item.name.toLowerCase().includes(brandsSearch.toLowerCase())
    )
  }, [data, brandsSearch])

  useEffect(() => {
    window?.navigator?.vibrate && window?.navigator?.vibrate(10);
  }, [openSearchFormBrand])

  const setPage = useSetRecoilState<any>(pageStateAtom)

  const setFilters = useSetRecoilState<any>(filtersStateAtom)

  const [loading, setLoading] = useState<boolean>(false)
 
  const onHeadlerSearch = (e:any) => {  setScroll( 0 ); setBrandsSearch(e.target.value); if( listRef.current ) listRef.current.scrollTo(0); }

  const onClick = (data: any) => {

    setGlobalProgressBackdrop(true)
      
    setTimeout(() => {
        
      setFilters((prevState:any) => ({ ...prevState, brand: data }))

      setPage('models')
    }, 250)
    
  }

  const loadMoreData = () => {  }

  useEffect(() => {

    if(scroll !== 0 && listRef.current) listRef.current.scrollTo(scroll);
  }, [])

  const isItemLoaded = (index: number) => { return index < data.length }

  const handleScroll = (scroll:any) => setScroll( scroll.scrollOffset );

  const searchFormTextFieldRef = useRef<any>(null)

  useEffect(() => {
    if (openSearchFormBrand) {

      if(searchFormTextFieldRef.current) searchFormTextFieldRef.current.focus();
    } else {

      if(searchFormTextFieldRef.current) searchFormTextFieldRef.current.blur();
    }
  }, [openSearchFormBrand])

  const matchesUseMediaQuery = useMediaQuery('(max-width:340px)')

  const getAtomValue = useRecoilCallback(({ snapshot }) => async () => {
    
    const value = await snapshot.getPromise(brandsScrollStateAtom);
    return value;
  })

  const fetchAtomValue = async () => {

    const value = await getAtomValue();

    if(value !== 0 && listRef.current) {

      if(!fixScroll.current&&!training.active){

        fixScroll.current = true;
        listRef.current.scrollTo(value);

        setComponentScroll(value);
      }
    };
  }
 
  fetchAtomValue()

  useEffect(() => {

    if( training &&  training.active && training.step === 0 ) {

      let lookBrand:number|null = null;

      for(let i = 0; i < data.length; i++) {
        
        if(data[i]?.key === training.brand) {

          lookBrand = i;
        }
      }

      if(lookBrand && listRef.current) {

        if(!fixScroll.current){

          listRef.current.scrollToItem(lookBrand, "center");
        }
      }
    }
  }, [data])

  return <Container maxWidth="sm">

          {/* <Intro/> */}

          <ScrollToTopVirtualList listRef={listRef} atom={brandsScrollStateAtom}/>

          { data.length > 0 &&
          
            <Box sx={{ 
              width: matchesUseMediaQuery ? windowSize.width - 50 : `unset`,
            }}>

              <InfiniteLoader
              isItemLoaded={isItemLoaded}
              itemCount={data.length + 1}
              loadMoreItems={loadMoreData}
              >
              {({ onItemsRendered, ref }) => (
                <List
                  height={windowSize.height - 95}
                  width={ matchesUseMediaQuery ? (windowSize.width - ( matchesUseMediaQuery ? 0 : 40)) : 'unset'}
                  
                  itemCount={filteredData.length}
                  itemSize={110}
                  itemData={{
                    data: filteredData,
                    onClick: onClick,
                    training,
                    company,
                    intro: <Intro/>
                  }}
                  onItemsRendered={onItemsRendered}
                  ref={(list) => {
                    ref(list);
                    if (list) listRef.current = list;
                  }}
                  onScroll={handleScroll} // Обработчик события прокрутки
                >

                  {renderRow}

                </List>
              )}

              </InfiniteLoader>

            </Box>

          }

          { loading &&

            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              height="70vh"
            >

              <CircularProgress color="inherit" />

              <Typography variant="subtitle2" gutterBottom  align="center" sx={{ mt: 2.5 , ml: 5, mr: 5}}>

                Загружаем данные...

              </Typography>
              
            </Box>
            
          }

          {/* {  !loading && data.length === 0 &&

            <Typography variant="subtitle2" gutterBottom color="primary" align="center" sx={{ mt: 1, pt: '50%'}}>

              Что-то пошло не так! Попробуйте зайти позже.
              
            </Typography>
          } */}
          
          <Fade in={!openSearchFormBrand || false} unmountOnExit>
            <Button sx={{
              width: 50,
              height: 50,
              minWidth: 'unset',
              borderRadius:  `50%`,
              position: 'fixed',
              bottom: 15,
              right: -15,
              transform: 'translateX(-50%)',
              bgcolor: 'primary.company',
              "&:hover": {
                bgcolor: 'primary.company',
                opacity: 0.9,
              },
              "&:active": {
                bgcolor: 'primary.company',
                opacity: 0.9,
              },
            }} variant="contained" /* endIcon={<SearchIcon />} */ onClick={()=>{setOpenSearchFormBrand(!openSearchFormBrand)}}>
              {/* Поиск */} <SearchIcon fontSize='small'/> { brandsSearch && <> {brandsSearch}</> }
            </Button>
          </Fade>

          <SwipeableDrawer
            anchor={'bottom'}
            open={openSearchFormBrand||false}
            onClose={()=>{setOpenSearchFormBrand(!openSearchFormBrand)}}
            onOpen={()=>{}}
            maxWidth
            sx={{
              justifyContent: 'center','display': 'flex', 'alignitems': 'center',
              '& .MuiPaper-root': {
                heightMin: 100,
                "&" : {
                  /* backgroundColor: 'rgba(255, 255, 255, 1)', */
                  backdropFilter: "blur(2px)",
                  borderTopLeftRadius: 15,
                  borderTopRightRadius: 15,
                },
              },
            }}
          >
            <Container maxWidth="sm" sx={{minHeight: '20vh', p: 2 }}>


              <Box
                component="form"
                sx={{
                  padding: 1,
                  '& > :not(style)': { m: 1, width: '95.5%' },
                }}
                noValidate
                autoComplete="off"
              >

                <Controller
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { isTouched, isDirty, error },
                  }) => (
                    <TextField
                      onClick={()=>{window?.navigator?.vibrate && window?.navigator?.vibrate(10);}}
                      autoFocus={false}
                      placeholder="Введите бренд"
                      value={brandsSearch}
                      onBlur={onBlur}
                      inputRef={(input) => {

                        ref(input);
                        if (input) searchFormTextFieldRef.current = input;
                      }}
                      onChange={(e:any)=>{

                        onChange(e)
                        onHeadlerSearch(e)
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end" sx={{ marginRight: 1 }}>
              
                            <IconButton onClick={()=>{
                              
                              if(brandsSearch === '') return setOpenSearchFormBrand(!openSearchFormBrand);

                              setValue('name', '')
                              setBrandsSearch('')
                            }} edge="end">
                              <ClearIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                  name="name"
                  control={control}
                  rules={{ required: true }}
                />

                {/* {errors?.name && <Typography variant="overline" color="error">{errors.name.message}</Typography>} */}

                { brandsSearch ?
                  <LoadingButton loading={loading} variant="outlined" onClick={()=>{setOpenSearchFormBrand(!openSearchFormBrand)}} endIcon={<SearchIcon />}
                  sx={{
                    height:  40,

                    bgcolor: 'primary.company',
                    "&:hover": {
                      bgcolor: 'primary.company',
                      opacity: 0.9,
                    },
                    "&:active": {
                      bgcolor: 'primary.company',
                      opacity: 0.9,
                    },
                  }}>

                    Показать

                  </LoadingButton>
                  :
                  <LoadingButton loading={loading} variant="outlined" onClick={()=>{setOpenSearchFormBrand(!openSearchFormBrand)}}
                  sx={{
                    height:  40,

                    bgcolor: 'primary.company',
                    "&:hover": {
                      bgcolor: 'primary.company',
                      opacity: 0.9,
                    },
                    "&:active": {
                      bgcolor: 'primary.company',
                      opacity: 0.9,
                    },
                  }}
                  >

                    Показать

                  </LoadingButton>
                }
                
              </Box>

            </Container>

          </SwipeableDrawer>

        </Container>
}

export default Brands


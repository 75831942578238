import { FC, useMemo, useEffect } from 'react'

import SwipeableDrawer from '@components/SwipeableDrawer'

import Grow from '@mui/material/Grow'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Divider from '@mui/material/Divider'
import MenuList from '@mui/material/MenuList'
import MenuItem from '@mui/material/MenuItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import Typography from '@mui/material/Typography'
import ContentCopy from '@mui/icons-material/ContentCopy'

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined'
import AutoAwesomeMotionOutlinedIcon from '@mui/icons-material/AutoAwesomeMotionOutlined'
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined'
import FiberNewOutlinedIcon from '@mui/icons-material/FiberNewOutlined'
import ContactsOutlinedIcon from '@mui/icons-material/ContactsOutlined'
import SubscriptionsOutlinedIcon from '@mui/icons-material/SubscriptionsOutlined'
import SettingsSuggestOutlinedIcon from '@mui/icons-material/SettingsSuggestOutlined'
import TrendingUpOutlinedIcon from '@mui/icons-material/TrendingUpOutlined'

import PriorityHighOutlinedIcon from '@mui/icons-material/PriorityHighOutlined';

import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';

import { useRecoilState, useRecoilValue } from 'recoil'
import { companyStateAtom } from '@state/company'

import { menuStateAtom } from '@state/menu'
import { menuPageStateAtom } from '@state/menuPage'

import { styled } from '@mui/material/styles'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Unstable_Grid2'

import Logo from '@components/Logo'
import ButtonBack from '@components/Buttons/Back'
import ButtonMenu from '@components/Buttons/Menu'

import Subscriptions from '@components/Subscriptions'
import Bookmarks from '@components/Bookmarks'

import About from '@components/About'
import Advantages from '@components/Advantages'
import Faq from '@components/Faq'
import Reviews from '@components/Reviews'
import SchemeOfWork from '@components/SchemeOfWork'
import Calculators from '@components/Calculators'
import Offices from '@components/Offices'
import Settings from '@components/Settings'
import AboutApp from '@components/AboutApp'

import parseDynamicComponent from '@utils/parseDynamicComponent'

import useMobileDetect from '@hooks/useMobileDetect'

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',

  height: '100%',
  width: '100%',
}))

const Menu: FC = () => {

  const mobile = useMobileDetect()

  const [menu, setMenu] = useRecoilState<boolean>(menuStateAtom) 
  const [menuPage, setMenuPage] = useRecoilState<string>(menuPageStateAtom)

  const company = useRecoilValue<any>(companyStateAtom)

  useEffect(() => {

    if(!menu) {
      
      setMenuPage(`menu`)
      setMenu(false)
    }
  }, [menu])

  const onClose = () => {

    setMenuPage(`menu`)
    setMenu(false)
  }
  useEffect(() => {}, [menu])

  const about:any = useMemo(() => parseDynamicComponent(company?.about?.About),[company?.about?.About])
  const advantages:any = useMemo(() => parseDynamicComponent(company?.advantages?.advantages),[company?.advantages?.advantages])
  const scheme_of_work:any = useMemo(() => parseDynamicComponent(company?.scheme_of_work?.scheme_of_work),[company?.scheme_of_work?.scheme_of_work])

  const faq:any = useMemo(() => parseDynamicComponent(company?.faq?.faq),[company?.faq?.faq])
  const reviews:any = useMemo(() => parseDynamicComponent(company?.reviews?.reviews),[company?.reviews?.reviews])

  const contacts:any = useMemo(() => parseDynamicComponent(company?.contacts?.contacts),[company?.contacts?.contacts])

  const MenuItemSX = {}

  return (
    <>
      <SwipeableDrawer
        anchor={'right'}
        open={menu||false}
        disableSwipeToOpen={false}
        onClose={onClose}
        onOpen={()=>{setMenu(true)}}
        sx={{ 
          "&" : {
            width: "100%",
          }
        }}
        PaperProps={{
          style: {
            maxWidth: mobile ? 520 : 380 ,
            width: '100%',
            marginLeft: 'auto',
            marginRight: 'auto',
          },
        }}
      >

        <Grid container spacing={0}>
          <Grid xs>
            <Item>
              { !window?.Telegram?.WebApp?.initData && <ButtonBack show={menuPage !== `menu`} callback={()=>{setMenuPage(`menu`)}}/> }
            </Item>
          </Grid>
          <Grid xs={6}>
            <Item>
              <Logo show={true}/>
            </Item>
          </Grid>
          <Grid xs>
            <Item>

              <ButtonMenu show={ !window?.Telegram?.WebApp?.initData ? true : false}/>
            </Item>
          </Grid>
        </Grid>

        <Container maxWidth="sm"  color="text.primary">

          <Box sx={{ mt: 0, }}>

            <Grow in={menuPage === `menu` || false} unmountOnExit>

              <Box sx={{    
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: 'calc(100vh - 100px)',
              }}>

              <MenuList>

                <Divider />

                <MenuItem onClick={()=>{window?.navigator?.vibrate && window?.navigator?.vibrate(10);setMenuPage(`about`)}} sx={{...MenuItemSX}}>
                  <ListItemIcon>
                  <Typography variant="body2" color="text.secondary">
                    <InfoOutlinedIcon fontSize="medium" />
                    </Typography>
                  </ListItemIcon>
                  <ListItemText>
                    
                    { about?.header?.title }
                    
                  </ListItemText>
                  <Typography variant="body2" color="text.secondary">
                  
                  </Typography>
                </MenuItem>

                <MenuItem onClick={()=>{window?.navigator?.vibrate && window?.navigator?.vibrate(10);setMenuPage(`advantages`)}} sx={{...MenuItemSX}}>
                  <ListItemIcon>
                  <Typography variant="body2" color="text.secondary">
                    <TrendingUpOutlinedIcon fontSize="medium" />
                    </Typography>
                  </ListItemIcon>
                  <ListItemText>

                    { advantages?.header?.title }

                  </ListItemText>
                  <Typography variant="body2" color="text.secondary">
                  
                  </Typography>
                </MenuItem>
                <MenuItem onClick={()=>{window?.navigator?.vibrate && window?.navigator?.vibrate(10);setMenuPage(`faq`)}} sx={{...MenuItemSX}}>
                  <ListItemIcon>
                  <Typography variant="body2" color="text.secondary">
                    <ArticleOutlinedIcon fontSize="medium" />
                    </Typography>
                  </ListItemIcon>
                  <ListItemText>
                    
                    { faq?.header?.title }
                    
                  </ListItemText>
                  <Typography variant="body2" color="text.secondary">
                  
                  </Typography>
                </MenuItem>

                <MenuItem onClick={()=>{window?.navigator?.vibrate && window?.navigator?.vibrate(10);setMenuPage(`calculators`)}} sx={{...MenuItemSX}}>
                  <ListItemIcon>
                    <Typography variant="body2" color="text.secondary">
                      <CalculateOutlinedIcon fontSize="medium" />
                    </Typography>

                    </ListItemIcon>
                    <ListItemText>
                      
                      Калькулятор
                      
                    </ListItemText>
                    <Typography variant="body2" color="text.secondary">
                    
                  </Typography>
                </MenuItem>

                <MenuItem onClick={()=>{window?.navigator?.vibrate && window?.navigator?.vibrate(10);setMenuPage(`scheme_of_work`)}} sx={{...MenuItemSX}}>
                  <ListItemIcon>
                  <Typography variant="body2" color="text.secondary">
                    <ContentCopy fontSize="medium" />
                    </Typography>
                  </ListItemIcon>
                  <ListItemText>
                    
                  { scheme_of_work?.header?.title }
                    
                  </ListItemText>
                  <Typography variant="body2" color="text.secondary">
                  
                  </Typography>
                </MenuItem>

                {/* <MenuItem onClick={()=>{window?.navigator?.vibrate && window?.navigator?.vibrate(10);setMenuPage(`deliverys`)}} sx={{...MenuItemSX}}>
                  <ListItemIcon>
                  <Typography variant="body2" color="text.secondary">
                    <AirportShuttleOutlinedIcon fontSize="medium" />
                    </Typography>
                  </ListItemIcon>
                  <ListItemText>
                    
                    Доставка
                    
                  </ListItemText>
                  <Typography variant="body2" color="text.secondary">
                  
                  </Typography>
                </MenuItem> */}

                <MenuItem onClick={()=>{window?.navigator?.vibrate && window?.navigator?.vibrate(10);setMenuPage(`reviews`)}} sx={{...MenuItemSX}}>
                  <ListItemIcon>
                  <Typography variant="body2" color="text.secondary">
                    <AutoAwesomeMotionOutlinedIcon fontSize="medium" />
                    </Typography>
                  </ListItemIcon>
                  <ListItemText>
                    
                  { reviews?.header?.title }
                    
                  </ListItemText>
                  <Typography variant="body2" color="text.secondary">
                  
                  </Typography>
                </MenuItem>

                <MenuItem onClick={()=>{window?.navigator?.vibrate && window?.navigator?.vibrate(10);setMenuPage(`contacts`)}} sx={{...MenuItemSX}}>
                  <ListItemIcon>
                  <Typography variant="body2" color="text.secondary">

                    <ContactsOutlinedIcon fontSize="medium" />
                    </Typography>
                  </ListItemIcon>
                  <ListItemText>

                    { contacts?.header?.title }
                  
                  </ListItemText>
                  <Typography variant="body2" color="text.secondary">
                  
                  </Typography>
                </MenuItem>

                <Divider />

                <MenuItem onClick={()=>{window?.navigator?.vibrate && window?.navigator?.vibrate(10);setMenuPage(`bookmarks`)}} sx={{...MenuItemSX}}>
                  <ListItemIcon>
                    <Typography variant="body2" color="text.secondary">

                      <BookmarkBorderIcon fontSize="medium" />
                    </Typography>

                  </ListItemIcon>
                  <ListItemText>
                    
                    Избранное
                  
                  </ListItemText>
                  <Typography variant="body2" color="text.secondary">
                    </Typography>
                </MenuItem>

                <MenuItem onClick={()=>{window?.navigator?.vibrate && window?.navigator?.vibrate(10);setMenuPage(`subscriptions`)}} sx={{...MenuItemSX}}>
                  <ListItemIcon>
                    <Typography variant="body2" color="text.secondary">

                      <SubscriptionsOutlinedIcon fontSize="medium" />
                    </Typography>

                  </ListItemIcon>
                  <ListItemText>
                    
                    Подписки
                    
                  </ListItemText>
                  <Typography variant="body2" color="text.secondary">
                    <FiberNewOutlinedIcon fontSize="medium" />
                    </Typography>
                </MenuItem>

                <Divider />

                <MenuItem onClick={()=>{window?.navigator?.vibrate && window?.navigator?.vibrate(10);setMenuPage(`settings`)}} sx={{...MenuItemSX}}>
                  <ListItemIcon>
                  <Typography variant="body2" color="text.secondary">

                    <SettingsSuggestOutlinedIcon fontSize="medium" />
                    </Typography>
                  </ListItemIcon>
                  <ListItemText>

                    Настройки

                  </ListItemText>
                  <Typography variant="body2" color="text.secondary">
                    
                  </Typography>
                </MenuItem>

                <MenuItem onClick={()=>{window?.navigator?.vibrate && window?.navigator?.vibrate(10);setMenuPage(`about_app`)}} sx={{...MenuItemSX}}>
                  <ListItemIcon>
                  <Typography variant="body2" color="text.secondary">

                    <PriorityHighOutlinedIcon fontSize="medium" color='secondary' />
                    </Typography>
                  </ListItemIcon>
                  <ListItemText>

                    О приложении

                  </ListItemText>
                  <Typography variant="body2" color="text.secondary">
                    
                  </Typography>
                </MenuItem>

              </MenuList>

              </Box>
              
            </Grow>

            <Grow in={menuPage === `about` || false} timeout={700} unmountOnExit>

              <Box>

                <About/>
              </Box>
                
            </Grow>
            
            <Grow in={menuPage === `advantages` || false} timeout={700} unmountOnExit>

              <Box>

                <Advantages/>
              </Box>

            </Grow>

            <Grow in={menuPage === `reviews` || false} timeout={700} unmountOnExit>

              <Box>

                <Reviews/>
              </Box>

            </Grow>

            <Grow in={menuPage === `calculators` || false} timeout={700} unmountOnExit>

              <Box>

                <Calculators/>
              </Box>
            </Grow>

            <Grow in={menuPage === `scheme_of_work` || false} timeout={700} unmountOnExit>

              <Box>

                <SchemeOfWork/>
              </Box>
            </Grow>

            <Grow in={menuPage === `deliverys` || false} timeout={700} unmountOnExit>

              <Box>
                deliverys
              </Box>
            </Grow>

            <Grow in={menuPage === `faq` || false} timeout={700} unmountOnExit>

              <Box>

                <Faq/>
              </Box>
            </Grow>

            <Grow in={menuPage === `contacts` || false} timeout={700} unmountOnExit>

              <Box>

                <Offices/>
              </Box>
            </Grow>
            
            <Grow in={menuPage === `bookmarks` || false} timeout={700} unmountOnExit>

              <Box>

                <Bookmarks/>
              </Box>
            </Grow>

            <Grow in={menuPage === `subscriptions` || false} timeout={700} unmountOnExit>

              <Box>

                <Subscriptions/>
              </Box>
            </Grow>

            <Grow in={menuPage === `settings` || false} timeout={700} unmountOnExit>

              <Box>

                <Settings/>
              </Box>

            </Grow>

            <Grow in={menuPage === `about_app` || false} timeout={700} unmountOnExit>

              <Box>

                <AboutApp/>
              </Box>
            </Grow>

          </Box>

        </Container>

      </SwipeableDrawer>

    </>
  )
}

export default Menu

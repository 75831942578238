import { useEffect, useState } from 'react'

function useBotDetect() {

  const [bot, setBot] = useState<boolean|null>(false)

  const handleInit = () => {

    if( window?.Telegram?.WebApp?.initData ) {
    
      setBot(true)
    }else{

      setBot(false)
    }
  }

  useEffect(() => {

    handleInit()
  }, [])

  return bot
}

export default useBotDetect

export { useBotDetect }


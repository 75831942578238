import { FC, useEffect, useState } from 'react'

import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil'

import { filtersStateAtom } from '@state/filters'
import { productsScrollStateAtom } from '@state/productsScroll'

import { menuStateAtom } from '@state/menu'
import { menuPageStateAtom } from '@state/menuPage'

import { openSubscriptionsStateAtom } from '@state/openSubscriptions'

import { modalProductsStateAtom } from '@state/modalProducts'

import ButtonGroup from '@mui/material/ButtonGroup'
import Button from '@mui/material/Button'

import ButtonFilters from '@components/Buttons/Filters'
import Filters from '@components/Filters'


import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'


import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive'

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'

import {useCheckingBrandModelSubscription} from '@hooks/useCheckingBrandModelSubscription'

import usePlatformDetect from '@hooks/usePlatformDetect'

import useMobileDetect from '@hooks/useMobileDetect'

import useBotDetect from '@hooks/useBotDetect'

interface I {
  sx?: any;
  listRef?: any
}

const ProductsTopGroup: FC<I> = ({listRef, sx}) => {

  const platform = usePlatformDetect()

  const mobile = useMobileDetect()
  
  const bot = useBotDetect()

  const setModalProducts = useSetRecoilState<boolean>(modalProductsStateAtom)

  const scroll = useRecoilValue<number>(productsScrollStateAtom)

  const filters = useRecoilValue<any>(filtersStateAtom)

  const setMenu = useSetRecoilState<boolean>(menuStateAtom) 

  const setMenuPage = useSetRecoilState<string>(menuPageStateAtom)

  const [openSubscriptions, setOpenSubscriptions] = useRecoilState<boolean>(openSubscriptionsStateAtom)

  const checkingBrandModelSubscription = useCheckingBrandModelSubscription()

  const [isInIframe, setIsInIframe] = useState(false)

  useEffect(()=>{
    
    try {

      setIsInIframe(window.self !== window.top);
    } catch (e) {

      setIsInIframe(true);
    }
  }, [])

  return (
    
    <>
    
    { mobile &&

      <ButtonGroup  variant="contained" aria-label="outlined primary button group"
      sx={{
        ...sx,
        borderRadius: 5,
        position: 'fixed',
        left: '50%',
        height: 30,
        transform: 'translateX(-50%)',
        zIndex: 2,
        //boxShadow: '0px 0px 6px 3px rgba(0,0,0,0.1)', // лагает на моб.

        backdropFilter: 'unset', 
        boxShadow: 0,
      }}>

        { scroll > 0 ?

          <Button
          sx={{
            height: '100%',
            width: 120,
            fontSize: 10,
            backgroundColor: `rgba(255, 255, 255, 0.8)`, 
            color: `black`, 
            p: 1, 

            //backdropFilter: "blur(5px)", // лагает на моб.
            backdropFilter: 'unset', 
            boxShadow: 0,

            "& .MuiButton-startIcon": { marginRight: "0px" },

            "&:hover": {
              bgcolor: `rgba(255, 255, 255, 1)`, 
              opacity: 0.9,
            },
            "&:active": {
              bgcolor: `rgba(255, 255, 255, 1)`, 
              opacity: 0.9,
            },
          }}
            startIcon={<ArrowUpwardIcon />}
            onClick={()=>{window?.navigator?.vibrate && window?.navigator?.vibrate(10); listRef.current.scrollTo(0)}}
          >

            Наверх

          </Button>

          :

          <>
          
          {( ( ( platform === 'bot' && filters.brand && filters.model ) || platform === 'mobile' || platform === 'desktop') && 

            <Button
            startIcon={ checkingBrandModelSubscription ? <NotificationsNoneIcon sx={{ transform: 'scale(0.8)' }}/> : <NotificationsActiveIcon sx={{ transform: 'scale(0.8)' }}/> }
            sx={{
              height: '100%',
              width: 120,
              fontSize: 10,
              backgroundColor: `rgba(255, 255, 255, 0.8)`, 
              color: checkingBrandModelSubscription ? `black` : `green`, 
              p: 0.5, 

              //backdropFilter: "blur(5px)", // лагает на моб.
              backdropFilter: 'unset', 
              boxShadow: 0,
              
              "& .MuiButton-startIcon": { marginRight: "0px" },

              "&:hover": {
                bgcolor: `rgba(255, 255, 255, 1)`, 
                opacity: 0.9,
              },
              "&:active": {
                bgcolor: `rgba(255, 255, 255, 1)`, 
                opacity: 0.9,
              },
            }}
            onClick={()=>{

              if(checkingBrandModelSubscription){

                setOpenSubscriptions(!openSubscriptions)

                return
              }

              setMenuPage('subscriptions');
              setMenu(true);
            }}
            >

              {checkingBrandModelSubscription ? <>Подписаться</> : <>Подписаны</>} 

            </Button>
          )}
          
          </>

        }

        <ButtonFilters/>

      </ButtonGroup>

      }

      <>
        {  !bot &&
          <IconButton
          aria-label="close"
          size="large"
          sx={{
            position: 'absolute',

            padding:0.25,

            top: mobile ? 15 : 15,
            right: mobile ? 15 : 25,

            height: 'unset',

            bgcolor: 'background.paper',
            color: 'text.primary',

            boxShadow: 0,

            transform: mobile ? 'scale(1.25)' : 'scale(1.75)',
            transition: 'transform 0.5s',

            zIndex: 3, 

            '&:hover': {
              bgcolor: 'background.paper',
              color: 'text.primary',
              transform: 'scale(1.3) translate(0, 0)',
            },
          }}
          onClick={()=>{
            
            if(isInIframe) return window.parent.postMessage('close', '*');
            
            return setModalProducts(false);
          }}>
            
            <CloseIcon/>

          </IconButton>
        }
      </>
    
    </>
  )
}

export default ProductsTopGroup

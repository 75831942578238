
const getTheme = ():string => {

    if(window?.Telegram?.WebApp?.initData){

      return window?.Telegram?.WebApp?.colorScheme || "light";

    }else{

      if(window.matchMedia && window.matchMedia("(prefers-color-scheme:dark)").matches) {
        return "dark";
      } else {
        return "light";
      }
    }
  }

export default getTheme

export { getTheme }

import { atom, selector } from "recoil"
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

export const modelsScrollStateAtom = atom({
  key: "modelsScrollStateAtom",
  default: 0,
  effects_UNSTABLE: [persistAtom],
})

export const modelsScrollStateSelector = selector({
  key: "modelsScrollStateSelector",
  get: ({ get }) => {
    const modelsScroll = get(modelsScrollStateAtom)
    return modelsScroll
  }
})


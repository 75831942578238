import { FC, ReactNode, useMemo, memo } from 'react';
import { NumericFormat } from 'react-number-format';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import {milesToKilometers} from '@utils/milesToKilometers'
import {fee} from '@utils/fee'

import useCurrencyConversion from '@hooks/useCurrencyConversion'

import { styled } from '@mui/material/styles';

interface MobileRowsProps {
  data: any; // Replace YourDataType with the actual type of your data prop
  company: any;
}

const removeForPrefix = (str: any): any => { return str?.startsWith('For') ? str.slice(3) : str};

type Props = {
  children: ReactNode,
  sx?: any,
}
const MyStack = ({children, sx} : Props) => {
  return <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          spacing={1}
          borderBottom={1}
          borderColor="primary.main"
          sx={{transition: 'unset !important', ...sx}}
        >
          {children}
        </Stack>
}

const MyStack2 = ({children, sx} : Props) => {
  return <Stack
          direction="column"
          justifyContent="space-between"
          alignItems="flex-start"
          margin={0.5}
          marginLeft={1}
          marginRight={1}
          border={0}
          borderColor="primary.main"
          sx={{...sx, whiteSpace: `nowrap`, borderRadius: 2,transition: 'unset !important',}}
        >
          {children}
        </Stack>
}


const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  marginTop: 0,
  marginBottom: 0,
  marginLeft: 0,
  paddingTop: 0,
  lineHeight: 1,
}))

const Item2 = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  marginTop: -5,
  marginBottom: 0,
  marginLeft: 0,
  paddingTop: 0,
  lineHeight: 1,
  fontSize: '0.750rem',
}))


const DesktopRows: FC<MobileRowsProps> = memo(({ data, company }) => {
/*   
  const renderStack = (left: string, right: ReactNode) => (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="flex-start"
      spacing={1}
      borderBottom={1}
      borderColor="primary.main"
    >
      <Left>{left}</Left>
      <Right>{right}</Right>
    </Stack>
  );
*/

  const convertedAmount = useCurrencyConversion(data?.currency_key, 'RUB', data?.price_original);

  const price:any = useMemo(() => fee({
    type: 'plus',
    amount: convertedAmount,
    country: data?.country,
    company: company,
    brand: data?.brand,

  }), [convertedAmount, data?.country, data?.brand, company ]);

  return (
    <>
      <Item sx={{width: '100%', padding: 0}}>

        <Grid container spacing={0} sx={{pt: 1, }}>

          <Grid xs={12}>
            <MyStack2 sx={{mt: 1, mb: 1}}>

              <Item sx={{fontWeight: 900}}>

              <Typography variant="h6" gutterBottom align="center" sx={{width: 'calc(100vw - 60px)',maxWidth: 'calc(100vw - 60px)', overflowX: 'hidden', whiteSpace: 'collapse', textAlign: 'left'}}>

                {/* {data?.brand?.name} {data?.model?.name} */}
                {removeForPrefix(data?.header)}

                </Typography>
              </Item>
            </MyStack2>
          </Grid>

        </Grid>

        <Grid container spacing={0}>

          <Grid container xs={12}>

            <Grid xs={6}>

              <MyStack2>
                { data?.country ? 
                    <Item sx={{fontWeight: 600}}>{data?.country?.name}</Item>
                  :
                  <Item>
                    —
                  </Item>
                }
                <Item2>Страна</Item2>
              </MyStack2>
            </Grid>

            <Grid xs={6}>

              <MyStack2>
                { data?.city ? 
                    <Item sx={{fontWeight: 600}}>{data?.city?.name}</Item>
                  :
                  <Item>
                    —
                  </Item>
                }
                <Item2>Город</Item2>
              </MyStack2>

            </Grid>
          </Grid>

          <Grid container xs={12}>

            <Grid xs={6}>
              <MyStack2>
                { data?.year ? 
                    <Item sx={{fontWeight: 600}}>{data?.year}</Item>
                  :
                  <Item>
                    —
                  </Item>
                }
                <Item2>Год</Item2>
              </MyStack2>
            </Grid>

            <Grid xs={6}>
              <MyStack2>
                { data?.mileage === 0 ?
                <Item sx={{fontWeight: 600}}>
                  новый автомобиль
                </Item>
                :
                <Item sx={{fontWeight: 600}}>
                  <NumericFormat allowLeadingZeros thousandSeparator=" "  decimalScale={0} displayType="text" value={milesToKilometers(data?.mileage?.toFixed(0))} suffix={'км'} />
                </Item>
                }
                <Item2>Пробег</Item2>
              </MyStack2>
            </Grid>

          </Grid>

          <Grid container xs={12}>

            <Grid xs={6}>
              <MyStack2>

                <>
                  { data?.fuel?.value === 'Electro' ? 
                    <Item sx={{fontWeight: 600}}>
                      <NumericFormat allowLeadingZeros thousandSeparator=" "  decimalScale={0} displayType="text" value={data?.kW?.toFixed(0)} suffix={'кВт'} />
                    </Item>
                    :
                    <>
                      { data?.engine_capacity ? 
                        <Item sx={{fontWeight: 600}}>
                            <NumericFormat allowLeadingZeros thousandSeparator=" "  decimalScale={0} displayType="text" value={data?.engine_capacity?.toFixed(0)} suffix={'см3'} />
                        </Item>
                        :
                        <Item>
                          —
                        </Item>
                      }
                    </>
                  }
                </>

                {/* { data?.engine_capacity ? 
                  <Item sx={{fontWeight: 600}}>
                    <NumericFormat allowLeadingZeros thousandSeparator=" "  decimalScale={0} displayType="text" value={data?.engine_capacity?.toFixed(0)} suffix={'см3'} />
                  </Item>
                  :
                  <Item>
                    —
                  </Item>
                } */}

                <Item2>Двигатель</Item2>
              </MyStack2>
            </Grid>

            <Grid xs={6}>

              <MyStack2>
                { data?.fuel ? 
                    <Item sx={{fontWeight: 600}}>{data?.fuel?.name}</Item>
                  :
                  <Item>
                    —
                  </Item>
                }
                <Item2>Топливо</Item2>
              </MyStack2>
            </Grid>
          </Grid>

          <Grid container xs={12}>

            <Grid xs={6}>
              <MyStack2>
              { data?.transmission ? 
                  <Item sx={{fontWeight: 600}}>{data?.transmission?.name}</Item>
                  :
                  <Item>
                    —
                  </Item>
                }
                <Item2>КПП</Item2>
              </MyStack2>
            </Grid>
            
            <Grid xs={6}>

              <MyStack2>
                { data?.drive ? 
                    <Item sx={{fontWeight: 600}}>{data?.drive?.name}</Item>
                  :
                  <Item>
                    —
                  </Item>
                }
                <Item2>Привод</Item2>
              </MyStack2>
            </Grid>
          </Grid>

          <Grid container xs={12}>

            <Grid xs={6}>

              <MyStack2>
                { data?.body ? 
                    <Item sx={{fontWeight: 600}}>{data?.body?.name}</Item>
                  :
                  <Item>
                    —
                  </Item>
                }
                <Item2>Класс</Item2>
              </MyStack2>
            </Grid>

            <Grid xs={6}>
              <MyStack2>
                <Item sx={{fontWeight: 600}}>
                  {/* <NumericFormat allowLeadingZeros thousandSeparator=" "  decimalScale={0} displayType="text" value={ data?.price } suffix={'₽'} /> */}
                  <NumericFormat allowLeadingZeros thousandSeparator=" "  decimalScale={0} displayType="text" value={ price } suffix={'₽'} />

                </Item>
                <Item2>{data?.donor?.name === 'exportcar.jp' ? 'Стартовая цена' : 'Цена'}</Item2>
              </MyStack2>
            </Grid>
          </Grid>

          <Grid container xs={12}>

            <Grid xs={6}>

              <MyStack2>
                { data?.auction ? 
                  <Item sx={{fontWeight: 600}}>{data?.auction?.name}</Item>
                    :
                  <Item>
                    —
                  </Item>
                }
                <Item2>Аукцион</Item2>
              </MyStack2>
            </Grid>

            <Grid xs={6}>
              <MyStack2>
                { data?.grade ? 
                  <Item sx={{fontWeight: 600}}>{data?.grade}</Item>
                    :
                  <Item>
                    —
                  </Item>
                }
                <Item2>Оценка</Item2>
              </MyStack2>
            </Grid>
          </Grid> 
                                  
          {data?.donor?.name === 'exportcar.jp' &&

            <Grid xs={12}>
              <MyStack2 sx={{marginTop: 1}}>

                <Item2>
                  Дата аукциона: 
                  { data?.date_of_auction ? new Date(data?.date_of_auction).toLocaleString("ru", {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    second: 'numeric'
                  }) : '' }
                </Item2>

              </MyStack2>
            </Grid>
          }

          <MyStack sx={{borderBottom: 0, marginLeft: 1, marginRight: 1}}> </MyStack>

        </Grid>
      </Item>
{/* 
      {data?.brand?.name && renderStack('Бранд', data?.brand?.name)}
      {data?.model?.name && renderStack('Модель', data?.model?.name)}
      {data?.year && renderStack('Год', data?.year)}
      {data?.color && renderStack('Цвет', data?.color.name)}

      {data?.mileage && renderStack('Пробег', `${milesToKilometers(data?.mileage)} км`)}
      {data?.fuel && renderStack('Топлива', data?.fuel.name)}
      {data?.engine && renderStack('Двигатель', data?.engine)}
      {data?.engine_capacity && renderStack('Двигатель, см3', data?.engine_capacity)}
      {data?.transmission && renderStack('Трансмиссия', data?.transmission.name)}
      {data?.drive && renderStack('Привод', data?.drive.name)}
      {data?.body && renderStack('Класс автомобиля', data?.body.name)}
      {data?.location && renderStack('Город лота', data?.location)}

      {data?.price && (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          spacing={1}
          borderBottom={1}
          borderColor="primary.main"
        >
          <Left>Цена</Left>
          <Right>
            <NumericFormat
              allowLeadingZeros
              thousandSeparator=" "
              decimalScale={0}
              displayType="text"
              value={data?.price}
              suffix={'₽'}
            />
          </Right>
        </Stack>
      )}

      {data?.country && renderStack('Страна', data?.country.name)}
      {data?.city && renderStack('Город', data?.city.name)} */}
    </>
  );
});

export default DesktopRows;

import { FC, useEffect, useState } from 'react'

import moment from 'moment'
import 'moment/locale/ru'

import axios from 'axios'
import qs from 'qs'

import AnimateHeight from 'react-animate-height'

import Container from '@mui/material/Container'
import Box from '@mui/material/Box'

import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

import Grid from '@mui/material/Unstable_Grid2'
import StartIcon from '@mui/icons-material/Start'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined'

import AlbumIcon from '@mui/icons-material/Album';

import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined'

import { useRecoilState, useRecoilValue } from 'recoil'

import { companyStateAtom } from '@state/company'
import { subscriptionsStateAtom } from '@state/subscriptions'

import api from '@services/api'

const Subscriptions: FC = () => {
  
  const company = useRecoilValue<any>(companyStateAtom)

  const [subscriptions, setSubscriptions] = useRecoilState<any>(subscriptionsStateAtom)

  const [open, setOpen] = useState<number>(-1)

  const [trimSubscriptions, setTrimSubscriptions] = useState<number>(0)
  const [maxSubscriptions, setMaxSubscriptions] = useState<number>(0)

  const [loading, setLoading] = useState<boolean>(false)
  const [firstLoading, setFirstLoading] = useState<boolean>(false)

  const params:any = new URLSearchParams(window?.Telegram?.WebApp?.initData) || null;
  
  const queryId = params?.get('query_id') || null
  const user = JSON?.parse(decodeURIComponent(params?.get('user'))) || null;

  useEffect(() => {

    for(const item of company?.subscriptions){

      if(item?.__component === `term-subscriptions.term-subscriptions`){

        setTrimSubscriptions(item?.users)
      }else
      if(item?.__component === `max-subscriptions.max-subscriptions`){
        
        setMaxSubscriptions(item?.users)
      }
    }
  }, [])

  useEffect(() => {}, [subscriptions])

  const handleExtend = (id: number) => {

    const query = qs.stringify({
      id: id,
      user: user.id,
    }, {
      encodeValuesOnly: true,
    })

    api({
      method: 'PUT',
      collection: 'bot-subscriptions',
      query
    }).then((data) => {

      send()

    }).catch((error:any) => {

      setLoading(false)
    })
  }
  
  const handleDelete = (id: number) => {
      
    console.log(`Delete: ${id}`)

    if(!user) return;

    setLoading(true)

    const filterData = subscriptions.filter((subscription:any) => subscription.id !== id);
    setSubscriptions(filterData);

    const query = qs.stringify({
      id: id,
      user: user.id,
    }, {
      encodeValuesOnly: true,
    })

    api({
      method: 'DELETE',
      collection: 'bot-subscriptions',
      query
    }).then((data) => {

      send()

    }).catch((error:any) => {

      setLoading(false)
    })
  }

  const send = () => {

    if(!user) return;

    const query = qs.stringify({
      user: user?.id, // user: 1528418905,
    }, {
      encodeValuesOnly: true,
    })

    api({
      collection: 'bot-subscriptions', 
      query
    }).then((data) => {

      console.log(data)
      console.log(data?.data)

      setFirstLoading(true)

      setLoading(false)

      if(data?.data) setSubscriptions(data?.data);

    }).catch((error:any) => {

      console.log(error)
      setFirstLoading(true)

      if( error?.request?.status === 401){

        setSubscriptions([])
      }
      setLoading(false)
    })
  }

  useEffect(() => {

    send()
  }, [])

  const formattedDate = (date: string):any => {

    let r = moment(date);
    r.locale(`ru`);
    return r.format('L');
  }
  const formattedTime = (date: string):any => {

    let r = moment(date);
    r.locale(`ru`);
    return r.format('LT');
  }

  return (
    <>
    
      <Container sx={{pt: 0}}>
                  
      <Typography variant="h6" gutterBottom align="center"> Подписки </Typography>
  
      { subscriptions.length === 0 ?

        <Box
        display="flex"
        justifyContent="space-around"
        alignItems="center"
        flexDirection={"column"}
        height={'calc(100vh - 240px)'}
        >

          <Typography variant="body1" display="block" align="center" gutterBottom sx={{ mt: 5}}>

            После подписки вы будете моментально получать актуальные лоты в режиме реального времени.

          </Typography>
        </Box>

        :

        <Box height={'calc(100vh - 220px)'} >
            
          {subscriptions?.map((item:any) => (
          
            <Box key={`${item?.id}-${item?.brand?.name}-${item?.model?.name}`} boxShadow={2} borderRadius={2} marginTop={3} padding={1}>
              
              <Box onClick={() =>{

                if(open===item.id) return setOpen(-1);

                setOpen(item.id)

              }}
              style={{
                display: 'flex',
                flexFlow: 'row-reverse',
                alignContent: 'space-between',
                justifyContent: 'space-between',
                marginTop: 10,
                marginBottom: open === item.id ? 10 : 0,
              }}
              >
                
                <Box>

                  <KeyboardArrowDownOutlinedIcon fontSize="large" style={{transform: open === item.id ? 'rotate(-180deg)':'unset', transition: '550ms'}}/>
                
                </Box>

                <Box>

                  <Box display={'flex'} flexDirection={'row'}>

                    <Box sx={{ color: item?.active ? 'green' : 'unset', mr: 2, pt: 1,}}><AlbumIcon fontSize='small'/></Box>

                    <Box display={'flex'} flexDirection={'column'}>

                      <Box>{item?.brand?.name} {item?.model?.name}</Box>

                        <Typography variant="caption" display="block" gutterBottom sx={{ mt: -0.5, ml: -0.9, transform: 'scale(0.9)'}}>

                          (активна до {formattedDate(item?.end)}, {formattedTime(item?.end)})

                        </Typography>
                    </Box>
                  </Box>
                    

                  <AnimateHeight
                    duration={500}
                    height={open === item.id ? 'auto':0}
                  >
                    
                  <Box sx={{ flexGrow: 1, mt: 2 , mb: 2 }}>

                    <Grid container spacing={2}>
                      <Grid xs={12}>
                      
                        { item?.countries?.length > 1 ? <> Страны экспортеры </> : <> Страна экспортер </> }

                      </Grid>

                      <Grid xs={12} sx={{ mt: -2.5 }}>

                        {item?.countries?.map((item:any) => ( <> {item.name} </> ))}

                      </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                      <Grid xs={12}>
                        Цена от, до
                      </Grid>

                      <Grid xs={12} sx={{ mt: -2.5 }}>
                        { item?.price_min } - { item?.price_max } 
                      </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                      <Grid xs={12}>
                        Год от, до
                      </Grid>

                      <Grid xs={12} sx={{ mt: -2.5 }}>
                      { item?.year_min } - { item?.year_max } 
                      </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                      <Grid xs={12}>
                        пробег от, до
                      </Grid>

                      <Grid xs={12} sx={{ mt: -2.5 }}>
                        { item?.mileage_min } - { item?.mileage_max } 
                      </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                      <Grid xs={12}>
                      
                        { item?.colors?.length > 1 ? <> Цвета </> : <> Цвет </> }

                      </Grid>

                      <Grid xs={12} sx={{ mt: -2.5 }}>

                        {item?.colors?.map((item:any) => ( <> {item.name} </> ))}

                      </Grid>

                    </Grid>

                    <Grid container spacing={2}>

                      <Grid xs={6}>

                        <Button
                        variant="contained"
                        sx={{
                          mt: 1,
                          height: '100%',
                          width: 120,
                          p: 1,
                          fontSize: 10,
                          backdropFilter: "blur(2px)",
                          "& .MuiButton-startIcon": { marginRight: "2px" },
                          bgcolor: 'primary.company',
                          "&:hover": {
                            bgcolor: 'primary.company',
                            opacity: 0.9,
                          },
                          "&:active": {
                            bgcolor: 'primary.company',
                            opacity: 0.9,
                          },
                        }}
                        endIcon={<StartIcon />}
                        onClick={()=>{
                          
                          window?.navigator?.vibrate && window?.navigator?.vibrate(10);
                          handleExtend(item.id)
                          
                        }}>

                          Продлить

                        </Button>

                      </Grid>

                      <Grid xs={6}>
                    
                        <Button
                        variant="contained"
                        sx={{
                          mt: 1,
                          height: '100%',
                          width: 120,
                          p: 1,
                          fontSize: 10,
                          backdropFilter: "blur(2px)",
                          "& .MuiButton-startIcon": { marginRight: "2px" },
                          bgcolor: 'rgba(0,0,0, 0.5)',
                          "&:hover": {
                            bgcolor: 'rgba(0,0,0, 0.5)',
                            opacity: 0.9,
                          },
                          "&:active": {
                            bgcolor: 'rgba(0,0,0, 0.5)',
                            opacity: 1,
                          },
                        }}
                        startIcon={<DeleteForeverOutlinedIcon />}
                        onClick={()=>{window?.navigator?.vibrate && window?.navigator?.vibrate(10);handleDelete(item.id)}}>

                          Удалить

                        </Button>

                      </Grid>

                    </Grid>

                  </Box>

                </AnimateHeight>

              </Box>

            </Box>

          </Box>
          
        ))}

      </Box>
      
      }
      
      <Typography variant="caption" display="block" align="center" gutterBottom>

        Количество подписок ограничено!

      </Typography>

      <Typography variant="caption" display="block" align="center" gutterBottom sx={{ mt: 3, transform: 'scale(0.9)'}}>

        Максимум {maxSubscriptions} {maxSubscriptions > 1 ? <>подписки</>:<>подписка</>} на {trimSubscriptions} дней (Продлевается)

      </Typography>

      </Container>
    </>
  )
}


export default Subscriptions

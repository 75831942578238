import { atom, selector } from "recoil"

export const companyStateAtom = atom({
  key: "companyStateAtom",
  default: {},
})

export const companyStateSelector = selector({
  key: "companyStateSelector",
  get: ({ get }) => {
    const company = get(companyStateAtom)
    return company
  }
})


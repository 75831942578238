import { FC, useEffect } from 'react'

import Fade from '@mui/material/Fade'
import Box from '@mui/material/Box'

import Hamburger from 'hamburger-react'

import { useRecoilState } from 'recoil'
import { menuStateAtom } from '@state/menu'

import useTraining from '@hooks/useTraining'

const ButtonMenu: FC<any> = ({show=true}) => {

  const { training } = useTraining()

  const [menu, setMenu] = useRecoilState<boolean>(menuStateAtom) 

  useEffect(() => {
    window?.navigator?.vibrate && window?.navigator?.vibrate(10);
  }, [menu])
  
  return (
    <Fade in={show || false} timeout={700} unmountOnExit>

      <Box
      display={'flex'}
      justifyContent={'center'}
      justifyItems={'center'}
      alignItems={'center'}
      sx={{
        
        position: `relative`,
        top: 5,
        left: 0,

        borderRadius: 2.5,  // при training.active скругленым смотриться красивей

        height: '100%',
        width: '100%',
        cursor: 'pointer',

        ...( training && training.active && training.step === 7 && {
          ...training.trainingAnimation
        })
      }}>

        <Hamburger size={26} direction="right" toggled={menu} toggle={setMenu}  onToggle={toggled => {
          if( training &&  training.active && training.step === 7 ) training.next();

        }}/>

      </Box>

    </Fade>
  )
}

export default ButtonMenu

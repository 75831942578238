import { atom, selector } from "recoil"
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

export const subscriptionsStateAtom = atom({
  key: "subscriptionsStateAtom",
  default: [],
  effects_UNSTABLE: [persistAtom],
})

export const subscriptionsStateSelector = selector({
  key: "subscriptionsStateSelector",
  get: ({ get }) => {
    const subscriptions = get(subscriptionsStateAtom)
    return subscriptions
  }
})


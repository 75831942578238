import { FC, useMemo } from 'react'

import { useRecoilValue } from 'recoil'
import { companyStateAtom } from '@state/company'

import Grid from '@mui/material/Unstable_Grid2'

import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import useMediaQuery from '@mui/material/useMediaQuery';

import parseDynamicComponent from '@utils/parseDynamicComponent'

import { usePlatformDetect } from '@hooks/usePlatformDetect'
import { useBotDetect } from '@hooks/useBotDetect'
import { useMobileDetect } from '@hooks/useMobileDetect'
import { useDesktopDetect } from '@hooks/useDesktopDetect'

import { replaceImageFormat } from '@utils/replaceImageFormat'

const  { REACT_APP_MINIO_URL_FROM, REACT_APP_MINIO_URL_TO } = process.env

const Intro: FC<any> = () => {

  const company = useRecoilValue<any>(companyStateAtom)

  const platform = usePlatformDetect()
  
  const bot = useBotDetect()
  const mobile =   useMobileDetect()
  const desktop =   useDesktopDetect()

  const matches = useMediaQuery('(max-width:1200px)', { noSsr: true });

  let data: any = useMemo(() => {
    if (platform && company?.intro) {
      return parseDynamicComponent(company.intro[platform]);
    }
    return null;
  }, [company?.intro, platform]);

  const design:any = useMemo(() => company?.intro?.design,[company?.intro?.design])

  if(!platform) return <></>;

  return (
    <>
      
      {( ( platform === 'bot' ) &&
        <>
          <Container id={'intro'} maxWidth="sm">

            <Box sx={{ mt: 0, pt: 0, height: 100 }} dangerouslySetInnerHTML={{__html: data?.header?.title }}/>

          </Container>
        </>
      )}

      {( ( platform === 'mobile' || platform === 'desktop' ) &&
        <>
          <Container id={'intro'} maxWidth="lg" sx={{  height: mobile ? 350 : 400, pt: 0,  mt: { xs: bot ? 0 : 5, md: 5 }, ...(!mobile && !bot && { pl: 0, pr: 0, })  }}>
            
            <Grid
              container
              direction={ platform === 'desktop' ? "row" : "column"}
              justifyContent="center"
              alignItems="flex-end"
            >

              <Grid xs={ mobile ? 12 : 6}>
                <Box sx={{ height: mobile ? 185 : 400, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>

                  <Box
                  style={{
                    backgroundImage: `url(${ design?.image ? replaceImageFormat(design?.image?.url?.replace(REACT_APP_MINIO_URL_FROM, REACT_APP_MINIO_URL_TO)) : ''})`,
                    backgroundPosition: `center`,
                    backgroundSize: `cover`,
                    backgroundRepeat: `no-repeat`,
                    minHeight: platform === 'mobile' ? 165 : matches ? 275 : 375,
                    width: platform === 'mobile' ? 320 : matches ? 550 : 750,
                    maxWidth: 'calc(100vw - 0px)',
                    borderRadius: 15,
                    marginLeft:  platform === 'mobile' ? 0 : -50,
                    marginBottom: platform === 'mobile' ? 0 : 15,
                    position: 'absolute',
                    zIndex: 1
                  }}/>
                  
                </Box>
              </Grid>

              <Grid xs={mobile ? 12 : 6}>


                <Box sx={{  zIndex: 2,  height: mobile ? 185 :400,  display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                  
                  <Box sx={{ textTransform: 'uppercase',  fontWeight: 'bold', }}>

                    { data?.header?.title &&

                    <Typography sx={{ typography: { xs: 'h6', sm: 'h6',  md: 'h5',  lg: 'h4', xl: 'h4'} }} gutterBottom align="center" dangerouslySetInnerHTML={{__html: data?.header?.title}}/>
                    }

                    { data?.header?.caption &&

                    <Typography sx={{ typography: { xs: 'caption', sm: 'caption',  md: 'body2',  lg: 'body1', xl: 'body1'} }} gutterBottom display="block" align="center" dangerouslySetInnerHTML={{__html: data?.header?.caption}}/>
                    }

                    { data?.header?.body &&

                    <Typography variant="body2" dangerouslySetInnerHTML={{__html: data?.header?.body?.text}}/>
                    }

                  </Box>

                </Box>

              </Grid>

            </Grid>

            { data?.footer?.title &&
              
              <Typography variant="h6" gutterBottom align="center" dangerouslySetInnerHTML={{__html: data?.footer?.title}}/>
            }
            
            { data?.footer?.caption &&
            
              <Typography variant="caption" gutterBottom display="block" align="center" dangerouslySetInnerHTML={{__html: data?.footer?.caption}}/>
            }

            { data?.footer?.body &&
              
              <Typography variant="body2" dangerouslySetInnerHTML={{__html: data?.footer?.body?.text}}/>
            }
            
          </Container>
        </>
      )}

    </>
  )
}

export default Intro
import { FC, useRef, useEffect, useState } from 'react'

import axios from 'axios'
import qs from 'qs'

import { LoadingButton } from '@mui/lab'
import TextField from '@mui/material/TextField'

import InputAdornment from '@mui/material/InputAdornment'

import Box from '@mui/material/Box'

import Grid from '@mui/material/Unstable_Grid2'
import Typography from '@mui/material/Typography';

import Avatar from '@mui/material/Avatar'

import SendIcon from '@mui/icons-material/Send'
import PersonIcon from '@mui/icons-material/Person'

import { useRecoilState } from 'recoil'

import { chatBotStateAtom } from '@state/chatBot'
import { chatBotMessagesStateAtom } from '@state/chatBotMessages'

import DesktopOrMobileOrBot from '@components/DesktopOrMobileOrBot'

import usePlatformDetect from '@hooks/usePlatformDetect'

import api from '@services/api'


const params:any = new URLSearchParams(window?.Telegram?.WebApp?.initData) || null;
  
const user = JSON?.parse(decodeURIComponent(params?.get('user'))) || null;

const ChatBot: FC = () => {

  const platform = usePlatformDetect()

  const messagesEndRef = useRef<HTMLDivElement>(null);
  
  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const [loading, setLoading] = useState<boolean>(false)

  const [message, setMessage] = useState('')
  
  const [open, setOpen] = useRecoilState<any>(chatBotStateAtom)

  const [messages, setMessages] = useRecoilState<any>(chatBotMessagesStateAtom)

  useEffect(()=>{

    if(messages.length === 0) setMessages([{
      sender: 'assistant',
      message: 'Привет! Я твой персональный ИИ эксперт по автомобилям и если у тебя есть вопросы по автомобилям можешь задать их мне!',
    }/* ,{
      sender: 'user',
      message: 'Привет!',
    } */]);

    window?.navigator?.vibrate && window?.navigator?.vibrate(10);
  }, [open])

  const send = () => {

    setLoading(true)

    const query = qs.stringify({
      user: user?.id, // user: 1528418905,
    }, {
      encodeValuesOnly: true,
    })

    const sendMessage = message.slice(0, 100)

    
    api({ method: 'POST', collection: 'web-chat-bot', query, data: { user, message: message } }).then((data) => {

      if(data){
        
        setMessages([...messages, ...[{
          sender: 'user',
          message: sendMessage,  
        },{
          sender: 'assistant',
          message: encodeURI(data?.data),
        }]])

      }

      setLoading(false)

    }).catch((error:any) => {

      console.log(error)
      setLoading(false)
    })

    setMessages([...messages, ...[{
      sender: 'user',
      message: sendMessage,  
    }]])

    const lastFiveElements: any[] = messages.slice(-5);
    console.log(`lastFiveElements`);
    console.log(lastFiveElements);

    setMessage('')
  }

  useEffect(()=>{
    scrollToBottom();
  }, [messages, message, open])

  useEffect(()=>{
    setTimeout(() => {
      scrollToBottom();
    }, 1000)
  }, [open])

  return (

    <DesktopOrMobileOrBot open={open || false} onClose={()=>{setOpen(false)}} sx={{width: 760, maxWidth: '100vw' }}>

      <Box 
      display={'flex'} 
      alignItems={'center'} 
      flexDirection={'column'} 
      justifyContent={"space-between"} 
      sx={{  width: '100%', overflow: 'hidden',}}>

        <Typography variant="h6" display="block">

          ИИ Автобот
                  
        </Typography>

        <Typography variant="caption" display="block" gutterBottom>

          Онлайн

        </Typography>
        
        <Box sx={{ mb: 2, minHeight: 300, maxHeight: 500, overflow: 'auto', width: '100%',  }}>

          { messages.map((message:any, index:number)=>{

            return (
              <Box sx={{mt: 2, mb: 2}} ref={index === messages.length - 1 ? messagesEndRef : null}>
                <Grid
                container
                xs={12}
                direction="row"
                justifyContent={message.sender === 'assistant' ? 'flex-start' : "flex-end"}
                alignItems={"flex-start"}>
                  {message.sender === 'assistant' ?
                  <>
                    <Grid xs={platform === 'desktop' ? 1 : 2} >
                      <Box sx={{width: 60}} display={'flex'} alignItems={'center'} flexDirection={'column'} justifyContent={"center"}>

                        <Avatar src={'/images/AiAssistant.gif'}/>
                        </Box>
                      </Grid>
                      <Grid xs={platform === 'desktop' ? 11 : 10}>
                      <Typography variant="body1" display="block" gutterBottom sx={{width: '100%', p: 1, borderRadius: 1, bgcolor: 'background.paper', color: 'text.primary', }} dangerouslySetInnerHTML={{__html: decodeURI(message.message)}}/>
                    </Grid>
                  </>
                  :
                  <>
                    <Grid xs={platform === 'desktop' ? 11 : 10}>
                      <Typography variant="body1" display="block" gutterBottom sx={{ userSelect: 'all', p: 1, borderRadius: 1, bgcolor: 'background.paper', color: 'text.primary', textAlign: 'right'}} dangerouslySetInnerHTML={{__html: decodeURI(message.message)}}/>
                    </Grid>
                    <Grid xs={platform === 'desktop' ? 1 : 2} 
                      direction="row"

                      justifyContent={message.sender === 'assistant' ? 'flex-start' : "flex-end"}
                      alignItems={message.sender === 'assistant' ? 'flex-start' : "flex-end"}
                      >
                      <Box sx={{width: 50}} display={'flex'} alignItems={'center'} flexDirection={'column'} justifyContent={"center"}>
                        <Avatar>
                          <PersonIcon/>
                        </Avatar>
                      </Box>
                    </Grid>
                  </>
                  }
                </Grid>
              </Box>
            )
          })}
        </Box>

        <Grid container xs={12} spacing={2} 
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          sx={{ width: '100%'}}
          >

          <Grid xs={platform === 'desktop' ? 11 : 10}>

            <TextField
              onClick={()=>{window?.navigator?.vibrate && window?.navigator?.vibrate(10);}}
              autoFocus={false}
              placeholder="Введите сообщение"
              value={message}
              onBlur={()=>{}}
              onChange={(e:any)=>{
                console.log(e.target.value)
                setMessage(e.target.value)
              }}
              onKeyDown={(e:any) => {
                if (e.key === 'Enter') {
                  if(message === '') return;
                  send()
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" sx={{ marginRight: 1 }}>

                      {/* <IconButton onClick={()=>{
                        
                        if(modelsSearch === '') return setOpenSearchFormModel(!openSearchFormModel);

                        setValue('name', '')
                        setBrandsSearch('')
                      }} edge="end">
                        <ClearIcon />
                      </IconButton> */}
                    
                  </InputAdornment>
                ),
              }}
              sx={{
                width: '100%',
              }}
            />

          </Grid>
      
          <Grid xs={platform === 'desktop' ? 1 : 2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>

            <LoadingButton 
            loading={loading} 
            variant="outlined" 
            onClick={()=>{
              window?.navigator?.vibrate && window?.navigator?.vibrate(10);
              if(message === '') return;
              send()
            }}
            sx={{
              height: 45,
              width: 45,
              minWidth: 50,

              fontSize: 10,
              bgcolor: 'primary.company',
              "&:hover": {
                bgcolor: 'primary.company',
                opacity: 0.7,
              },
              "&:active": {
                bgcolor: 'primary.company',
                opacity: 0.7,
              },
            }}>

              <SendIcon />
            </LoadingButton>

          </Grid>

        </Grid>

      </Box>

    </DesktopOrMobileOrBot>
  )
}

export default ChatBot

import { FC, useEffect, useState } from 'react'

import { useQRCode } from 'next-qrcode'

import axios from 'axios'
import qs from 'qs'

import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import { LoadingButton } from '@mui/lab'
import Link from '@mui/material/Link'

import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive'

import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'

import { useForm, Controller } from 'react-hook-form'

import { useRecoilState, useRecoilValue } from 'recoil'
import { companyStateAtom } from '@state/company'
import { filtersStateAtom } from '@state/filters'
import { subscriptionsStateAtom } from '@state/subscriptions'
import { countriesStateAtom } from '@state/countries'
import { openSubscriptionsStateAtom } from '@state/openSubscriptions'

import { useCheckingForSubscriptionFilters } from '@hooks/useCheckingForSubscriptionFilters'
import { useCheckingBrandModelSubscription} from '@hooks/useCheckingBrandModelSubscription'

import SwipeableDrawer from '@components/SwipeableDrawer'
import NeedActivateBot from '@components/NeedActivateBot'

import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"

import DesktopOrMobileOrBot from '@components/DesktopOrMobileOrBot'

import useBotDetect from '@hooks/useBotDetect'

import api from '@services/api'

const Schema = yup.object().shape({
  name: yup.string().required("Необходимо ввести ключ"),
})

const SubscriptionsAddForm: FC = () => {

  const bot = useBotDetect()

  const [alert, setAlert] = useState<any>({
    in: false,
    status: 'success',
    text: '',
  })

  const [needActivateBot, setNeedActivateBot] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  
  const company = useRecoilValue<any>(companyStateAtom)

  const { SVG } = useQRCode()


  const [trimSubscriptions, setTrimSubscriptions] = useState<number>(0)
  const [maxSubscriptions, setMaxSubscriptions] = useState<number>(0)

  const [subscriptions, setSubscriptions] = useRecoilState<any>(subscriptionsStateAtom)

  const [openSubscriptions, setOpenSubscriptions] = useRecoilState<boolean>(openSubscriptionsStateAtom)

  const filters = useRecoilValue<any>(filtersStateAtom)

  const countries = useRecoilValue<any[]>(countriesStateAtom)

  const checkingForSubscriptionFilters = useCheckingForSubscriptionFilters()

  const checkingBrandModelSubscription = useCheckingBrandModelSubscription()

  const tg = window?.Telegram?.WebApp || null

  const params:any = new URLSearchParams(window?.Telegram?.WebApp?.initData) || null;

  const queryId = params?.get('query_id') || null
  const user = JSON?.parse(decodeURIComponent(params?.get('user'))) || null;

  /*
  const hash = params.get('hash');
  console.log(`Query ID: ${queryId}`);
  console.log(`User: `, user);
  console.log(`Auth Date: ${authDate}`);
  console.log(`Hash: ${hash}`);
  */

  const { handleSubmit, control, getValues, setValue, formState: { errors } } = useForm({
    resolver: yupResolver(Schema),
    defaultValues: {
      name: '',
    }
  })


  useEffect(() => {

    for(const item of company?.subscriptions){

      if(item?.__component === `term-subscriptions.term-subscriptions`){

        setTrimSubscriptions(item?.users)
      }else
      if(item?.__component === `max-subscriptions.max-subscriptions`){
        
        setMaxSubscriptions(item?.users)
      }
    }
  }, [])

  useEffect(() => {
    window?.navigator?.vibrate && window?.navigator?.vibrate(10);
  }, [openSubscriptions])

  const send = () => {
    if(!user) return;

    let data:any = {
      user:{
        id: user?.id,
      },
      color: {
        id: {
          $in: filters.color.map((item:any) => item.id),
        },
      },
      countries: {
        id: {
          $in: filters.countries.map((item:any) => item.id),
        },
      },
      ...(filters?.brand !== null && {
        brand: filters.brand,
      }),
      ...(filters?.model !== null && {
        model: filters.model
      }),
      ...(filters?.body !== null && {
          value: filters.body.value,
      }),
      ...(filters?.drive !== null && {
        value: filters.drive.value,
      }),
      ...(filters?.fuel !== null && {
        value: filters.fuel.value,
      }),
      ...(filters?.transmission !== null && {
        value: filters.transmission.value,
      }),

      ...(filters?.price !== null && {
        price: {
          ...((filters?.price?.min !== null || filters?.price?.max !== null) && {
            $notNull: true
          }),

          ...(filters?.price?.min && {
            $gte: filters?.price?.min.value
          }),
          ...(filters?.price?.max && {
            $lte: filters?.price?.max.value
          }),
        }
      }),

      ...(filters?.year !== null && {
        year: {
          ...((filters?.year?.min !== null || filters?.year?.max !== null) && {
            $notNull: true
          }),
          ...(filters?.year?.min && {
            $gte: filters?.year?.min.value
          }),
          ...(filters?.year?.max && {
            $lte: filters?.year?.max.value
          }),
        }
      }),

      ...(filters?.mileage !== null && {
        mileage: {
          ...((filters?.mileage?.min !== null || filters?.mileage?.max !== null) && {
            $notNull: true
          }),
          ...(filters?.mileage?.min && {
            $gte: filters?.mileage?.min.value
          }),
          ...(filters?.mileage?.max && {
            $lte: filters?.mileage?.max.value
          }),
        }
      }),

      ...(filters?.engine_capacity !== null && {
        engine_capacity: {
          ...((filters?.engine_capacity?.min !== null || filters?.engine_capacity?.max !== null) && {
            $notNull: true
          }),
          ...(filters?.engine_capacity?.min && {
            $gte: filters?.engine_capacity?.min.value
          }),
          ...(filters?.engine_capacity?.max && {
            $lte: filters?.engine_capacity?.max.value
          }),
        }
      }),
    }

    api({
      method: 'POST',
      collection: 'bot-subscriptions', 
      data
    }).then((data:any) => {

      setAlert({
        in: true,
        status: 'success',
        text: 'Подписка оформлена',
      })

      setOpenSubscriptions(!openSubscriptions)

      const query = qs.stringify({
        user: user?.id,
      }, {
        encodeValuesOnly: true,
      })

      api({
        collection: 'bot-subscriptions', 
        query
      }).then((data:any) => {
  
        if(data?.data) setSubscriptions(data?.data);

      }).catch((error:any) => {
  
        if( error?.request?.status === 401){
  
          setSubscriptions([])
        }
        setLoading(false)
      })
  
    }).catch((error:any) => {

      setAlert({
        in: true,
        status: 'error',
        text: 'Ошибка',
      })

      setLoading(false)
    })
  }

  const onClose = ()=>{setOpenSubscriptions(!openSubscriptions)}

  return (
    <>

    <DesktopOrMobileOrBot open={openSubscriptions || false} onClose={onClose}>

      <Box
        sx={{
          width: '100%',
        }}
      >
        <Container maxWidth="sm" sx={{minHeight: '20vh', pt: 0, }}>

          <Box
            component="form"
            sx={{
              padding: 1,
            }}
            noValidate
            autoComplete="off"
          >

            {/* {errors?.name && <Typography variant="overline" color="error">{errors.name.message}</Typography>} */}

            {/* { brandsSearch ?
              <LoadingButton loading={loading} variant="outlined" onClick={()=>{setSearchForm(!searchForm)}} endIcon={<SearchIcon />}
              sx={{
                ...(company?.background_color_for_light_theme !== null && {
                  opacity: 0.9,
                  backgroundColor: company?.background_color_for_light_theme,
                }),
              }}>

                Показать

              </LoadingButton>
              :
              <LoadingButton loading={loading} variant="outlined" onClick={()=>{setSearchForm(!searchForm)}}
              sx={{
                ...(company?.background_color_for_light_theme !== null && {
                  opacity: 0.9,
                  backgroundColor: company?.background_color_for_light_theme,
                }),
              }}
              >

                Показать

              </LoadingButton>
            } */}

            <Box>

              <Typography variant="h6" gutterBottom align="center" sx={{ mb: 2}}> Подписка </Typography>

              { filters?.brand && filters?.model &&

                <Typography variant="caption" display="block" align="center" gutterBottom sx={{ mb: 3}}>

                  На {filters?.brand?.name} {filters?.model?.name}
                  
                </Typography>
              }

              { !bot && 
                <>

                  <Typography variant="body1" display="block" align="center" gutterBottom sx={{ mb: 3}}>

                    После подписки вы будете получать новые лоты в реальном времени

                  </Typography>
                  <Typography variant="caption" display="block" align="center" gutterBottom sx={{ transform: 'scale(1)'}}>

                    С учетом заполненных фильтров

                  </Typography>
                  <Typography variant="caption" display="block" align="center" gutterBottom sx={{mb: 3, transform: 'scale(1)'}}>

                    (Бренд, Модель, Цена, Год, Пробег, Цвет)

                  </Typography>

                </>
              }

              { bot && 
                <>

                  <Typography variant="body1" display="block" align="center" gutterBottom sx={{ mb: 3}}>

                    После подписки вы будете получать новые лоты в реальном времени

                  </Typography>

                  <Typography variant="body2" display="block" align="center" gutterBottom sx={{mb: 1}}>

                    Для активации нужно заполнить фильтры:

                  </Typography>
                  
                  <Typography variant="caption" display="block" align="center" gutterBottom sx={{mb: 3, transform: 'scale(1)'}}>

                    { countries.length > 1 ?

                      <>Страны, Бренд, Модель, Цена от до, год от до, Пробег от до, Цвет</>

                      :

                      <>Бренд, Модель, Цена от до, год от до, Пробег от до, Цвет</>
                    }
                    
                  </Typography>
                  
                  <Typography variant="caption" display="block" align="center" gutterBottom sx={{ transform: 'scale(0.9)' }}>
                  
                    Эти фильтры обязательны для активации подписки!

                  </Typography>

                  <Typography variant="caption" display="block" align="center" gutterBottom sx={{mb: 1, transform: 'scale(0.9)'}}>

                    (Остальные фильтры не учитываются)

                  </Typography>
                </>
              }

              { !bot && 
                <>

                <Typography variant="body2" display="block" align="center" gutterBottom sx={{ mb: 1, transform: 'scale(0.9)' }}>

                  Функция доступна в нашем телеграм боте

                </Typography>

                <Typography
                  variant="body1"
                  sx={{ 
                    whiteSpace: "wrap", 
                    wordWrap: "break-word", 
                    textAlign: "center",
                    mb: 2,
                  }}
                  justifyContent="center" 
                  alignItems="center"
                >

                  <Link onClick={()=>{
                    window?.navigator?.vibrate && window?.navigator?.vibrate(10);
                    window?.Telegram?.WebApp?.openLink(`https://t.me/${company?.telegram_bot}`)
                  }} sx={{ width: `100%`, textAlign: `center`, mt: 5, color: 'text.primary',}}>

                    @{company?.telegram_bot}

                  </Link>

                </Typography>

                <Box
                  display={'flex'}
                  justifyContent={'center'}
                  justifyItems={'center'}
                  alignItems={'center'}>
                  
                  <SVG
                    text={`https://t.me/${company?.telegram_bot}`}
                    options={{
                      margin: 2,
                      width: 250,
                      color: {},
                    }}
                  />
                  
                </Box>

              </>
              }


              {/* <Typography variant="caption" display="block" gutterBottom sx={{mb: 1}}>

                checkingForSubscriptionFilters: { checkingForSubscriptionFilters ? <>allow</> : <>not allow</> }

              </Typography>
              <Typography variant="caption" display="block" gutterBottom sx={{mb: 1}}>

                checkingBrandModelSubscription: { checkingBrandModelSubscription ? <>allow</> : <>not allow</> }

              </Typography> */}
            </Box>

          </Box>

        </Container>

        { bot &&

        <ButtonGroup fullWidth variant="contained" aria-label="outlined primary button group" sx={{
          position: 'relative',
          height: 40,
          zIndex: 2,
          boxShadow: 0,
          m: 0,
          mb: 2,
          p: 0,

          ".MuiButtonGroup-grouped": {
            borderColor: "transparent",
            borderRadius: 6,
            borderWidth: 3,
            "&:hover": {
              borderColor: "transparent"
            }
          },
          ".MuiButtonGroup-grouped:not(:last-of-type)": {
            borderColor: "transparent",
            "&:hover": {
              borderColor: "transparent"
            }
          },
        }}>
          
          { filters.brand && filters.model && 
            
            <>
            {/* <Button
            disabled={(!filters.brand || !filters.model || !checkingForSubscriptionFilters || !checkingBrandModelSubscription ) || false}

            startIcon={ checkingBrandModelSubscription || false ? <NotificationsNoneIcon sx={{ transform: 'scale(0.8)' }}/> : <NotificationsActiveIcon sx={{ transform: 'scale(0.8)' }}/> }
            sx={{
              height: '100%',
              width: '100%',
              fontSize: 12,
              p: 0.5, 
              m: 1,
              mb: 2,
              backdropFilter: "blur(5px)",
              borderRadius: "15px !important",
              boxShadow: 0,
              "& .MuiButton-startIcon": { marginRight: "0px" }
              
            }}
            onClick={()=>{

              if((filters.brand || filters.model || checkingForSubscriptionFilters || checkingBrandModelSubscription )){

                if(!queryId) return setNeedActivateBot(!needActivateBot);

                if(!user?.allows_write_to_pm) {

                  tg.requestWriteAccess((result: boolean)=>{
                    alert(result)
                  })
                }
                
                setAlert({
        in: true,
        status: 'success',
        text: 'Подписка оформлена',
      })
                setOpen(!open)
              }else{
                
                setAlert({
        in: true,
        status: 'info',
        text: 'Настройти фильтры',
      })
              }
            }}
            >

            {checkingBrandModelSubscription || false ? <>Подписаться</> : <>Вы подписаны</>} 
            
            </Button> */}
          
              <LoadingButton
              loading={loading} 
              fullWidth
              variant="outlined"
              disabled={ (!filters.brand || !filters.model || !checkingForSubscriptionFilters || !checkingBrandModelSubscription ) || false}
              startIcon={ checkingBrandModelSubscription || false ? <NotificationsNoneIcon sx={{ transform: 'scale(0.8)' }}/> : <NotificationsActiveIcon sx={{ transform: 'scale(0.8)' }}/> }
              sx={{
                height: '100%',
                width: '100%',
                fontSize: 12,
                p: 0.5, 
                m: 1,
                mb: 2,
                backdropFilter: "blur(5px)",
                borderRadius: "15px !important",
                boxShadow: 0,
                "& .MuiButton-startIcon": { marginRight: "0px" },

                bgcolor: 'primary.company',
                "&:hover": {
                  bgcolor: 'primary.company',
                  opacity: 0.7,
                },
                "&:active": {
                  bgcolor: 'primary.company',
                  opacity: 0.7,
                },
              }}
              onClick={()=>{

                if( subscriptions.length >= maxSubscriptions ){

                  setAlert({
                    in: true,
                    status: 'warning',
                    text: `Превышин лимит ${maxSubscriptions} из ${maxSubscriptions}`
                  })
                  return
                }

                setLoading(true)
  
                if((filters.brand || filters.model || checkingForSubscriptionFilters || checkingBrandModelSubscription )){
  
                  /* if(!queryId) return setNeedActivateBot(!needActivateBot);
  
                  if(!user?.allows_write_to_pm) {
  
                    tg.requestWriteAccess((result: boolean)=>{
                      alert(result)
                    })
                  } */
                  
                  send()
                  
                }else{

                  setLoading(false)
                  
                  setAlert({
                    in: true,
                    status: 'warning',
                    text: 'Настройти фильтры'
                  })
                }
              }}
              >

              {checkingBrandModelSubscription ? <>Подписаться</> : <>Вы подписаны</>} 


              </LoadingButton>

            </>
            
          }
          
        </ButtonGroup>

        }

        </Box>

    </DesktopOrMobileOrBot>


      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={alert.in||false}
        autoHideDuration={2500}
        onClick={()=>{
          setAlert({
            ...alert,
            in: false,
          })
        }}
        onClose={()=>{
          setAlert({
            ...alert,
            in: false,
          })
        }}
        message={alert.text}
        sx={{
          '& .MuiPaper-root': {
            borderRadius: 2,
            zIndex: 10000,
          },
        }}
      >

        <Alert severity={alert.status}>

          {alert.text}

        </Alert>

      </Snackbar>
      
      <NeedActivateBot open={needActivateBot} setOpen={setNeedActivateBot}/>

    </>
  )
}

export default SubscriptionsAddForm

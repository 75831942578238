import { atom, selector } from "recoil"
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

export const pageStateAtom = atom({
  key: "pageStateAtom",
  default: 'start',
  effects_UNSTABLE: [persistAtom],
})

export const pageStateSelector = selector({
  key: "pageStateSelector",
  get: ({ get }) => {
    const page = get(pageStateAtom)
    return page
  }
})


import { atom, selector } from "recoil"
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

export const chatBotMessagesStateAtom = atom({
  key: "chatBotMessagesStateAtom",
  default: [],
  effects_UNSTABLE: [persistAtom],

})

export const chatBotMessagesStateSelector = selector({
  key: "chatBotMessagesStateSelector",
  get: ({ get }) => {
    const chatBotMessages = get(chatBotMessagesStateAtom)
    return chatBotMessages
  }
})


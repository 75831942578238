import { FC, useRef, useEffect, useState } from 'react'

import { keyframes } from '@mui/system'

import { useRecoilValue } from 'recoil'

import { menuStateAtom } from '@state/menu'
import { productStateAtom } from '@state/product'

import Box from '@mui/material/Box'
import Snackbar from '@mui/material/Snackbar'

import useThemeMode from '@hooks/useThemeMode'

import useTraining from '@hooks/useTraining'

const Training: FC<any> = () => {

  const themeMode = useThemeMode()

  const { training } = useTraining()

  const [show, setShow] = useState<boolean>(false)
  const [showTouchAnimation, setShowTouchAnimation] = useState<boolean>(true)

  const menu = useRecoilValue<boolean>(menuStateAtom) 

  const product = useRecoilValue<any>(productStateAtom)

  useEffect(()=>{

    if( training?.step === 4 && product === null ) training?.next();
    
    if( training?.step === 8 && menu === false) training?.next();
    
  }, [product, menu])

  useEffect(()=>{

    if(training && training?.active && training?.step !== training?.steps?.length - 1 ) setShow(true);
    if(training && training?.active && training?.step >= training?.steps?.length - 1 ) setTimeout(() => {
      setShow(false)
    }, 9000)
    ;
    
  }, [training])
  
  const onClose = () => {}

  const positionTouchStartRef = useRef<any>({ x: 0, y: 0 });
  const positionTouchMoveRef = useRef<any>({ x: 0, y: 0 });

  useEffect(() => {

    const handleTouchStart = (e:any) => {

      setShowTouchAnimation(false)

      positionTouchStartRef.current.x = e.touches[0].clientX;
      positionTouchStartRef.current.y = e.touches[0].clientY;
    };
  
    const handleTouchMove = (e:any) => {

      positionTouchMoveRef.current.x = e.touches[0].clientX;
      positionTouchMoveRef.current.y = e.touches[0].clientY;
    };
  
    const handleTouchEnd = (e:any) => {

      setShowTouchAnimation(true)

      const n = 50;

      if (
        training && training?.active && training?.steps[training?.step] &&  training?.steps[training?.step]?.swiper &&
        training.step !== 4 && /* 4 ЭТО ЗАКРЫТИЕ ЛОТА И ЕГО МЫ ОТСЛЕЖИВАЕМ ПО ДРУГОМУ ЧЕРЕЗ useEffect */
        training.step !== 8 /* 8 ЭТО ЗАКРЫТИЕ МЕНЮ И ЕГО МЫ ОТСЛЕЖИВАЕМ ПО ДРУГОМУ ЧЕРЕЗ useEffect */
        ) {

        const deltaX = Math.abs(positionTouchMoveRef.current.x - positionTouchStartRef.current.x);
        const deltaY = Math.abs(positionTouchMoveRef.current.y - positionTouchStartRef.current.y);
  
        if (deltaX > n || deltaY > n) training?.next();
      }
    };
  
    document.addEventListener('touchstart', handleTouchStart);
    document.addEventListener('touchmove', handleTouchMove);
    document.addEventListener('touchend', handleTouchEnd);

    return () => {
      document.removeEventListener('touchstart', handleTouchStart);
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchend', handleTouchEnd);

    };
  }, [training]);

  /* useEffect(() => {

    const onScroll = () => {
      if(training && training.active && training.swiper){

        training.swiper.onScroll()
      }
    };
    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll);
  }, []) */


  let animation:any = null

  animation = keyframes`
  0% {
    transform: translateX(0);
    opacity: 0.8;
  }
  100% {
    transform: translateX(100px);
    opacity: 0;

  }
  `;

  if( training && training?.active && training?.steps && training?.steps[training?.step] && training?.steps[training?.step]?.swiper){
    if(training?.steps[training?.step]?.swiper?.from === 'top'){ // в низ
      animation = keyframes`
      0% {
        transform: translateY(0);
        opacity: 0.8;
      }
      100% {
        transform: translateY(100px);
        opacity: 0;
    
      }
      `;
    }else
    if(training?.steps[training?.step]?.swiper?.from === 'bottom'){ // вверх
      animation = keyframes`
      0% {
        transform: translateY(0);
        opacity: 0.8;
      }
      100% {
        transform: translateY(-100px);
        opacity: 0;
    
      }
      `;
    }else
    if(training?.steps[training?.step]?.swiper?.from === 'left'){ // left to right
      animation = keyframes`
      0% {
        transform: translateX(75px) rotate(-90deg);
        opacity: 0.8;
      }
      100% {
        transform: translateX(250px) rotate(-90deg);
        opacity: 0;
      }
      `;
    }else
    if(training?.steps[training?.step]?.swiper?.from === 'right'){ // right to left
      animation = keyframes`
      0% {
        transform: translateX(75px) rotate(-90deg);
        opacity: 0.8;
      }
      100% {
        transform: translateX(-250px) rotate(-90deg);
        opacity: 0;
      }
      `;
    }     
  }

  const trainingSwiperAnimation = {
    animation: `${animation} 1s linear infinite`,
  };

  if(!training?.active) return <></>;

  return (
    <>
      
      <Box
      sx={{
        position: ' fixed',
        top: 0,
        left: 0,
        
        backgroundColor: themeMode === 'dark' ?  'rgba(0,0,0,0.5)' : 'rgba(0,0,0,0.75)',
        zIndex: 9000,
      }}>

        {( training && training?.active && training?.steps && training?.steps[training?.step]?.swiper) && 
        
          <Box sx={{

            visibility: showTouchAnimation ? 'visible' : 'hidden',

            pointerEvents: 'none',

            position: 'absolute',

            top: training?.steps[training?.step].swiper?.position?.start?.y ,
            left: training?.steps[training?.step]?.swiper?.position?.start?.x ,

            width: '25px',
            height: '100px',

            backgroundColor: themeMode === 'dark' ?  'rgb(255, 255, 255)' : 'rgb(0, 0, 0)',

            borderRadius: 5,

            zIndex: 9001,

            ...trainingSwiperAnimation,
          }}/>
          
        }

        <Snackbar
          anchorOrigin={{
            vertical: training?.steps[training?.step]?.description?.position || 'bottom',
            horizontal: 'center',
          }}
          open={show||false}

          onClose={()=>{ if(training?.step === training?.steps?.length) setShow(false); }}

          message={
          <Box>
            <Box dangerouslySetInnerHTML={{__html: training?.steps[training?.step]?.description?.text}}/>

            { training?.steps[training?.step]?.button && training?.steps[training?.step]?.button?.show && 
              
              <Box 
              sx={{
                position: 'absolute', 
                bottom: 10,
                right: 10
              }} 
              onClick={()=>training?.next( training?.steps[training?.step]?.button?.nextStep)}>

                {training?.steps[training?.step]?.button?.text ? training?.steps[training?.step]?.button?.text : training?.step }

              </Box>
            }
          </Box>
          }

          sx={{

            '& .MuiPaper-root': {
              borderRadius: 2,
              minHeight: 55,

              ...( training?.steps[training?.step]?.button && training?.steps[training?.step]?.button?.show && {
                paddingTop: 1.5,
                paddingBottom: 5,
              })
            },
            '& .MuiSnackbarContent-message ': {
              display: 'block',
              textAlign: "center",
              width: '100%',
            },
          }}
        />

      </Box>
    </>
  )
}

export default Training

export { Training }

import { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'

import { filtersStateAtom } from '@state/filters'

import { isArray, isObject, isString } from 'lodash'

function useCheckingForSubscriptionFilters() {

  const filters = useRecoilValue<any>(filtersStateAtom)

  const [ allow, setAllow ] = useState<boolean>(true)
  
  useEffect(() => {

    for (const filter in filters) {
    
      if (
        ( filter === `brand` && !filters[filter] ) || 
        ( filter === `model` && !filters[filter] )
      ) setAllow(false);

      if ( isArray(filters[filter]) && filter === `countries` && filters[filter].length === 0 ) setAllow(false);

      if (isObject(filters[filter])) {
        
        if (
          ( filter === `price` ) || 
          ( filter === `year` ) || 
          ( filter === `mileage` )
        ){
          if( !filters[filter]?.min ) setAllow(false);
  
          if( !filters[filter]?.max ) setAllow(false);
        }
      }

      if( isString(filters[filter]) ){}
    }
    return () => {
      setAllow(true)
    }

  }, [filters])

  return allow
}

export default useCheckingForSubscriptionFilters;

export { useCheckingForSubscriptionFilters };

/* 

{
  open: false,
  drive: null,
  body: null,
  brand: null,
  model: null,
  color: [],
  countries: [],
  year: {
    min: null,
    max: null,
  },
  mileage: {
    min: null,
    max: null,
  },
  engine_capacity: {
    min: null,
    max: null,
  },
  fuel: null,
  transmission: null,
  price: {
    min: null,
    max: null,
  },
}
  
*/
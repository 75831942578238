
import { FC } from 'react'

import { useRecoilState } from 'recoil'
import { filtersStateAtom } from '@state/filters'

import Grid from '@mui/material/Grid'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'

import { NumericFormat } from 'react-number-format';

const isMobileDevice = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)

const options = [
  { value: 10000, name: "10000" },
  { value: 25000, name: "25000" },
  { value: 50000, name: "50000" },
  { value: 75000, name: "75000" },
  { value: 100000, name: "100000" },
  { value: 150000, name: "150000" },
  { value: 200000, name: "200000" },
  { value: 250000, name: "250000" },
  { value: 300000, name: "300000" },
  { value: 350000, name: "350000" },
  { value: 400000, name: "400000" },
  { value: 450000, name: "450000" },
  { value: 500000, name: "500000" },
  { value: 550000, name: "550000" },
];

const Mileage: FC = () => {

  const [filters, setFilters] = useRecoilState<any>(filtersStateAtom)

  const renderOption = (props:any, option:any) => (
    <li {...props}>
      <NumericFormat allowLeadingZeros thousandSeparator=" " decimalScale={1} displayType="text" value={option.name} suffix={' км'} />
    </li>
  )

  const optionsReverse = [...options].reverse()

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item xs={6}>
        <Autocomplete
          value={filters.mileage.min}
          onChange={(_, newValue) => {
            window?.navigator?.vibrate && window?.navigator?.vibrate(10);
            setFilters((prevState:any) => ({ ...prevState, mileage: { min: newValue, max: prevState.mileage.max } }))
          }}
          options={options}
          renderOption={renderOption}
          getOptionLabel={(option) => option.name.toString()}
          noOptionsText={'Нет донных'}
          isOptionEqualToValue={(option, value) => option.name === value.name}
          renderInput={(params) => (
            <TextField
              onClick={()=>{window?.navigator?.vibrate && window?.navigator?.vibrate(10);}}
              sx={{
                justifyContent: `center`,
                alignItems: `center`,
              }}
              {...params}
              label="Пробег от, км"
              /* placeholder="Пробег от, км" */
              variant="outlined"
              fullWidth
              inputProps={{
                ...params.inputProps,
                inputMode: isMobileDevice() ? 'numeric' : 'text', // Установка числового режима для клавиатуры на мобильных устройствах
              }}
              InputLabelProps={{sx: {  marginTop: -0.25 } }}

            /> 
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          value={filters.mileage.max}
          onChange={(_, newValue) => {
            window?.navigator?.vibrate && window?.navigator?.vibrate(10);
            setFilters((prevState:any) => ({ ...prevState, mileage: { min: prevState.mileage.min, max:  newValue} }))
          }}
          options={optionsReverse}
          renderOption={renderOption}

          getOptionLabel={(option) => option.name.toString()}
          noOptionsText={'Нет донных'}
          isOptionEqualToValue={(option, value) => option.name === value.name}
          renderInput={(params:any) => (
            <TextField
              onClick={()=>{window?.navigator?.vibrate && window?.navigator?.vibrate(10);}}
              sx={{
                justifyContent: `center`,
                alignItems: `center`,
              }}
              {...params}
              label="Пробег до, км"
              /* placeholder="Пробег до, км" */
              variant="outlined"  
              fullWidth
              inputProps={{
                ...params.inputProps,
                inputMode: isMobileDevice() ? 'numeric' : 'text',
              }}
              InputLabelProps={{sx: {  marginTop: -0.25 } }}

            />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default Mileage;

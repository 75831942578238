import { FC, useEffect } from 'react'

import { useRecoilValue } from 'recoil'
import { effectsStateAtom } from '@state/effects'

import Container from '@mui/material/Container'
import Box from '@mui/material/Box'

import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

import Modal from '@mui/material/Modal'

import SwipeableDrawer from '@components/SwipeableDrawer'

import usePlatformDetect from '@hooks/usePlatformDetect'

interface I {
  open?: boolean
  setOpen?: any
  onOpen?: any
  onClose?: any
  children?: any
  sx?: any

}
const DesktopOrMobileOrBot: FC<I> = ({open=false, setOpen=()=>{}, onOpen=()=>{}, onClose=()=>{}, children, sx}) => {

  const platform = usePlatformDetect()

  const effects = useRecoilValue<boolean|null>(effectsStateAtom)

  useEffect(() => {
    if(!open) return;
    const random = Math.random().toString(36)

    window.history.pushState({ popup: random }, "title 2", "?popup="+random);
    onOpen()
  }, [open])

  useEffect(() => {
    const html = document.querySelector("html");
    if (html) {
      html.style.overflow = open ? "hidden" : "auto";
    }
  }, [open]);

  return (
    <>
      
      { platform === 'desktop' &&

        <Modal
        disableAutoFocus
        open={open || false}

        onClose={onClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        sx={{
          "&" : {
            backdropFilter: "blur(6px)",
          }
        }}
        >

          <Box
          sx={{
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 450,
            bgcolor: 'background.paper',
            border: 0,
            borderRadius: 2.5,
            boxShadow: 24,
            pt: 4,
            px: 4,
            pb: 4,
            ...sx,
          }}>
            
            <IconButton
            aria-label="close"
            size="large"
            sx={{
              position: 'absolute',
              padding:0.25,
              top: 2.5,
              right: -45,

              height: 'unset',

              bgcolor: 'background.paper',
              color: 'text.primary',

              boxShadow: 4,

              transform: 'scale(1.25)',
              transition: 'transform 0.5s',
              '&:hover': {
                bgcolor: 'background.paper',
                color: 'text.primary',
                transform: 'scale(1.3) translate(0, 0)',
              },
            }}
            onClick={()=>{

              setOpen(false)
              onClose()
            }}>
              
              <CloseIcon/>

            </IconButton>

            <Box>

              {children}
                  
            </Box>

          </Box>

       </Modal> 
      
      }

      { ( platform === 'mobile' || platform === 'bot' ) &&

        <SwipeableDrawer
          anchor={'bottom'}
          open={open||false}
          onClose={onClose}
          onOpen={()=>{}}
          maxWidth
          sx={{
            ...sx,
            justifyContent: 'center','display': 'flex', 'alignitems': 'center',

            '& .MuiPaper-root': {
              minHeight: 195,
              "&" : {
                backdropFilter: "blur(6px)",
                borderTopLeftRadius: 15,
                borderTopRightRadius: 15,
              },
            },
          }}
        >

          <Container maxWidth="sm" sx={{ overflowY: 'hidden' }}>
            
            {children}
            
          </Container>
        
        </SwipeableDrawer>
      }

    </>
  )
}

export default DesktopOrMobileOrBot

import { FC, useEffect } from 'react'

import { size } from 'lodash'

import { styled } from '@mui/material/styles'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Unstable_Grid2'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'

import Logo from '@components/Logo'
import Back from '@components/Buttons/Back'
import Menu from '@components/Buttons/Menu'
//import QRcode from '@components/Buttons/QRcode'

import useMediaQuery from '@mui/material/useMediaQuery'

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'

import { pageStateAtom } from '@state/page'
import { pageNumberStateAtom } from '@state/pageNumber'
import { productsStateAtom } from '@state/products'
import { productsScrollStateAtom } from '@state/productsScroll'
import { modelsStateAtom } from '@state/models'
//import { brandStateAtom } from '@state/brand'
import { modelStateAtom } from '@state/model'
import { filtersStateAtom } from '@state/filters'
import { modelsScrollStateAtom } from '@state/modelsScroll'
import { modelsSearchStateAtom } from '@state/modelsSearch'
import { companyStateAtom } from '@state/company'
import { menuStateAtom } from '@state/menu'
import { menuPageStateAtom } from '@state/menuPage'
import { productStateAtom } from '@state/product'
import { callBackButtonStateAtom } from '@state/callBackButton'
import { callBackStateAtom } from '@state/callBack'
import { callBackCalculatorPriceStateAtom } from '@state/callBackCalculatorPrice'
import { modalProductsStateAtom } from '@state/modalProducts'

import { openSearchFormBrandStateAtom } from '@state/openSearchFormBrand'
import { openSearchFormModelStateAtom } from '@state/openSearchFormModel'
import { openSubscriptionsStateAtom } from '@state/openSubscriptions'
/* 
import * as CryptoJS from 'crypto-js'

// Encrypt
const plaintext = 'my message';
const secretKey = 'secret key 123';
const ciphertext = CryptoJS.AES.encrypt(plaintext, secretKey).toString();
console.log(ciphertext); // 'my message'
console.log(encodeURIComponent(ciphertext)); // 'my message'
console.log(encodeURI(ciphertext)); // 'my message'

// Decrypt
const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
const originalText = bytes.toString(CryptoJS.enc.Utf8);

console.log(originalText); // 'my message'
 */
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: 'rgba(0,0,0, 0)',
  textAlign: 'center',
  height: '100%',
  width: '100%',
}))

const Header: FC<any> = () => {

  const [page, setPage] = useRecoilState<any>(pageStateAtom)

  const miniPhoneDetect = useMediaQuery('(max-width:340px)')

  const [openSearchFormBrand, setOpenSearchFormBrand] = useRecoilState<any>(openSearchFormBrandStateAtom)
  const [openSearchFormModel, setOpenSearchFormModel] = useRecoilState<any>(openSearchFormModelStateAtom)
  const [openSubscriptions, setOpenSubscriptions] = useRecoilState<any>(openSubscriptionsStateAtom)

  const setPageNumber = useSetRecoilState<number>(pageNumberStateAtom)
  const setModel = useSetRecoilState<any>(modelStateAtom)
  //const setBrand = useSetRecoilState<any>(brandStateAtom)
  const setModels = useSetRecoilState<any>(modelsStateAtom)
  const setProducts = useSetRecoilState<any>(productsStateAtom)
  const setProductsScroll = useSetRecoilState<any>(productsScrollStateAtom)

  const setModelsScroll = useSetRecoilState<any>(modelsScrollStateAtom)
  const setModelsSearch = useSetRecoilState<any>(modelsSearchStateAtom)

  const [modalProducts, setModalProducts] = useRecoilState<boolean>(modalProductsStateAtom)
  
  const [product, setProduct] = useRecoilState<any>(productStateAtom)

  const [filters, setFilters] = useRecoilState<any>(filtersStateAtom)

  const setCallBackButton = useSetRecoilState<boolean>(callBackButtonStateAtom)

  const company = useRecoilValue<any>(companyStateAtom)

  const [ callBack, setCallBack ] = useRecoilState<boolean>(callBackStateAtom)
  const [ callBackCalculatorPrice, setCallBackCalculatorPrice ] = useRecoilState<boolean>(callBackCalculatorPriceStateAtom)
  
  const [menu, setMenu] = useRecoilState<boolean>(menuStateAtom) 

  const [menuPage, setMenuPage] = useRecoilState<string>(menuPageStateAtom)

  //decodeURIComponent(encodedURI)
  //encodeURIComponent()

  const handle = () => {

    if(size(product)>0){

      setProduct(null)
      setCallBackButton(false)
      return 
    }else
    if(filters.open){

      return setFilters((prevState:any) => ({ ...prevState, open: false }));
      
    }else
    if(modalProducts){

      return setModalProducts(false);
      
    }else
    if(callBack){

      return setCallBack(false);
      
    }else
    if(callBackCalculatorPrice){

      return setCallBackCalculatorPrice(false);
      
    }else
    if(openSearchFormBrand){

      return setOpenSearchFormBrand(false);
      
    }else
    if(openSearchFormModel){

      return setOpenSearchFormModel(false);
      
    }else
    if(openSubscriptions){

      return setOpenSubscriptions(false);
      
    }else

    if(menu){

      if(menuPage !== 'menu') return setMenuPage('menu');

      return setMenu(false);
      
    }else

    if(page==='models'){

      /* setBrand(null) */
      setFilters((prevState:any) => ({ ...prevState, brand: null }))

      setPage('brands')
      setPageNumber(1)

      setModelsScroll(0)
      
      setModels([])
      setModelsSearch('')
    }else
    if(page==='products'){

      setModel(null)
      setFilters((prevState:any) => ({ ...prevState, model: null }))

      setPage('models')

      setPageNumber(1)

      setProductsScroll(0)

      setProducts([])
    }
  }

  useEffect(() => {

    if (window?.Telegram?.WebApp?.SettingsButton) {

      window?.Telegram.WebApp.SettingsButton.show()
    }

    if (!window?.Telegram?.WebApp?.SettingsButton) {
      console.warn('Telegram.WebApp.SettingsButton is not available');
      return;
    }
  
    const handleClick = () => {
      
      setMenuPage('settings');
      
      setMenu(true);
    }
  
    if (window?.Telegram?.WebApp?.SettingsButton) {

      window.Telegram.WebApp.SettingsButton.onClick(handleClick);
    }

    return () => {
      if (window?.Telegram?.WebApp?.SettingsButton) {

        window.Telegram.WebApp.SettingsButton.offClick(handleClick);
      }
    };
  }, [ menuPage] );
  
  useEffect(() => {

    if (window?.Telegram?.WebApp?.BackButton) {

      window.Telegram.WebApp.SettingsButton.show()
    }

    if (window?.Telegram?.WebApp?.BackButton) {

      if (!window.Telegram?.WebApp?.BackButton) {
        return;
      }
    }
    if (window?.Telegram?.WebApp?.BackButton) {

      if (page !== 'brands' || menu) {
        
        window.Telegram.WebApp.BackButton.isVisible = true;
      } else {
        window.Telegram.WebApp.BackButton.isVisible = false;
      }
    }
  
    const handleClick = () => {

      handle();
    };

    const onPop = () => {
      handle();
    }
    
    if (window?.Telegram?.WebApp?.BackButton) {

      window.Telegram.WebApp.BackButton.onClick(handleClick);
    }
    
    window.addEventListener('popstate', onPop);

    return () => {
      if (window?.Telegram?.WebApp?.BackButton) {

        window.Telegram.WebApp.BackButton.offClick(handleClick);
      }

      window.removeEventListener("popstate", onPop);
    };
  }, [page, menuPage, menu, callBack, callBackCalculatorPrice, openSearchFormBrand, openSearchFormModel, openSubscriptions, filters.open, product]);
  
  return (
    <>
      <Box sx={{ height: 80, background: 'primary.main', }}/>

      <Box sx={{ position: 'fixed', top: 0, height: 80, left: 0, right: 0, zIndex: 100,   backdropFilter: "blur(4px)", }}>
        
        <Grid container spacing={0}>

          <Grid xs={3}>
            <Item sx={{transform: 'scale(0.7)', mt: 1}}>

              <Logo show={page !== 'start' && Object.keys(company).length > 0 }/>

            </Item>
          </Grid>

          <Grid xs={ miniPhoneDetect ? 6.75 : 7} sx={{mt: 1.5}}>
            <Item>
            <Button
              variant="contained"
              sx={{
                transform: 'scale(0.7)',
                width: miniPhoneDetect ? 180 : 210,
                p: 1,

                left: 25,
                backdropFilter: "blur(2px)",

                whiteSpace: "nowrap",

                "& .MuiButton-startIcon": { marginRight: "2px" },
                
                bgcolor: 'primary.company',
                color: 'primary.main',

                "&:hover": {
                  bgcolor: 'primary.company',
                  opacity: 0.9,
                },
                "&:active": {
                  bgcolor: 'primary.company',
                  opacity: 0.9,
                },
              }}
              onClick={()=>{
                window?.navigator?.vibrate && window?.navigator?.vibrate(10);
                setCallBackCalculatorPrice(true);
                }}>
                
                { miniPhoneDetect ? 'Рассчитать авто' : 'Рассчитать стоимость' }

              </Button>
            </Item>
          </Grid>

          <Grid  xs={2}>
            <Item>
              <Menu show={page !== 'start' && Object.keys(company).length > 0 }/>
            </Item>
          </Grid>
          
        </Grid>

      </Box>
    </>
  )
}

export default Header

import { FC } from 'react'

import Fade from '@mui/material/Fade'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';

import { useRecoilValue } from 'recoil'
import { filtersStateAtom } from '@state/filters'
import { companyStateAtom } from '@state/company'

const NeedActivateBot: FC<any> = ({open, setOpen}) => {

  const filters = useRecoilValue<any>(filtersStateAtom)
  const company = useRecoilValue<any>(companyStateAtom)
  
  const tg = window?.Telegram?.WebApp || null

  return (
      <Modal
        open={open || false}
        onClose={()=>{}}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 340,
            bgcolor: 'background.paper',
            border: 0,
            borderRadius: 2.5,
            boxShadow: 24,
            pt: 2,
            px: 4,
            pb: 3,
          }}>
          
          <Box>

            <Typography variant="body2" display="block" align="center" gutterBottom>

              Не можем получить ваш Telegram id

            </Typography>

            <Typography variant="body1" display="block" align="center" gutterBottom sx={{mt: 3}}>

              Это легко исправить!

            </Typography>
            <Typography variant="body2" display="block" align="center" gutterBottom sx={{ transform: 'scale(0.95)'}}>

              следуйте инструкции:

            </Typography>

            <Typography variant="caption" display="block" align="center" gutterBottom sx={{mt: 3, transform: 'scale(1)'}}>

            {/* После того как нажмете кнопку "Приступить" откроется наш бот, нажимаете "старт" появиться кнопка "Открыть каталог" нажимаем, далее "подписаться" Готово! */}
            После нажатие "Приступить" откроется наш бот, нажимаете "старт" появиться кнопка "Открыть каталог" нажимаем, далее "подписаться" Готово!

            </Typography>

          </Box>
          <Button
          sx={{
            height:  40,
            width: '100%',
            mt: 1.5,

            bgcolor: 'primary.company',
            "&:hover": {
              bgcolor: 'primary.company',
              opacity: 0.7,
            },
            "&:active": {
              bgcolor: 'primary.company',
              opacity: 0.7,
            },
          }}
           onClick={()=>{

            setOpen(false)

            if( window?.Telegram?.WebApp ) window.Telegram.WebApp.isClosingConfirmationEnabled = false;
            
            tg.openLink(`https://t.me/${company?.telegram_bot}`)

            tg.close()
          }}>
            
            Приступить
            
          </Button>
        </Box>
      </Modal>
  )
}

export default NeedActivateBot

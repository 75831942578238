
import { FC, useEffect } from 'react'

import { useRecoilState, useRecoilValue } from 'recoil'
import { filtersStateAtom } from '@state/filters'
import { filtersDataStateAtom } from '@state/filtersData'

import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'

import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  customCursor: {
    cursor: 'pointer',
  },
})

interface Option {
  id: number;
  value: string;
  name: string;
}

const options: Option[] = [
  { id: 4, value: 'Hatchback', name: 'Хэтчбек' },
  { id: 5, value: 'SUV', name: 'Внедорожник' },
]

const Body: FC = () => {

  const classes = useStyles()

  const [filters, setFilters] = useRecoilState<any>(filtersStateAtom)

  const filtersData = useRecoilValue<any>(filtersDataStateAtom)

  useEffect(()=>{}, [filters, filtersData])

  return (
    <Autocomplete
    value={filters.body}
    onChange={(event, newValue) => {
      window?.navigator?.vibrate && window?.navigator?.vibrate(10);
      setFilters((prevState:any) => ({ ...prevState, body: newValue }));
    }}
    options={options}
    getOptionLabel={(option) => option.name}
    noOptionsText={'Нету донных'}
    renderInput={(params) => (
      <TextField
        onClick={()=>{window?.navigator?.vibrate && window?.navigator?.vibrate(10);}}
        sx={{
          justifyContent: `center`,
          alignItems: `center`,
          '& .MuiInputBase-root': {
            cursor: 'pointer', 
          },
          '& .MuiInputBase-input': {
            cursor: 'pointer', 
          },
        }}
        {...params}
        label="Кузов"
        /* placeholder={filters.body ? '' : 'Кузов'} */
        variant="outlined"
        fullWidth
        inputProps={{
          ...params.inputProps,
          readOnly: true,
        }}
        InputLabelProps={{sx: {  marginTop: -0.25 } }}

      />
    )}
    />
  )
}

export default Body;

import { FC, useEffect } from 'react'

import { useSetRecoilState } from 'recoil'
import { currenciesStateAtom } from '@state/currencies'

import api from '@services/api'

const Currencies: FC = () => {

  const setCurrencies = useSetRecoilState<any>(currenciesStateAtom)

  const send = () => {

    api({ collection: 'currencies' }).then((data) => {

      console.log(`currencies`)
      console.log(data)

      if(data) setCurrencies(data?.data);
      
    }).catch((error:any) => {

      console.log(error)
    })
  }

  useEffect(() => {
    
    send()

    const interval = setInterval(() => { send() }, 60000)

    return () => clearInterval(interval)
  }, [])

  return (<></>)
}

export default Currencies
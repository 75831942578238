interface Filter {
  value: string;
}

type FilterObject = {
  [value: string]: Filter | any;
};

const filterObject = (obj: FilterObject): FilterObject => {

  const result: any = {
    [`$and`]: {},
    [`$or`]: [],
  }

  let index = 0

  for (const key in obj) {

    index++

    if (key === `countries` && obj[key].length > 0 ) {

      let data:any = []

      for (const item of obj[key]) {
        
        data.push({
          "$and": [
            { "country":  item.id  },
          ]
        })
      }

      result[`$or`] =  data
    }

    if (key === `color` && obj[key].length > 0 ) {

      let data:any = []

      for (const item of obj[key]) {
        
        data.push({
          "$and": [
            { "color":  item.id  },
          ]
        })
      }

      result[`$or`] =  data
    }
    
    if (key !== `open` && obj[key]) {
      
      if (typeof obj[key] === `object` && obj[key].value) {

        result[`$and`][index] = {}

        result[`$and`][index][key] = {
          value: obj[key].value,
        } as Filter;

      } else if (typeof obj[key] === `object`) {

        if(obj[key].min||obj[key].max){

          result[`$and`][index] = {
            [key]: {}
          }

          result[`$and`][index][key][`$notNull`] = true

          if(obj[key].min){
            result[`$and`][index][key][`$gte`] = obj[key].min.value
          }
          if(obj[key].max){
            result[`$and`][index][key][`$lte`] = obj[key].max.value
          }
        }
      }
    }
  }

  return result;
}

export default filterObject

export type { FilterObject, Filter }
import { FC, useState, useEffect } from 'react'

import axios from 'axios'
import qs from 'qs'

import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil'
import { productStateAtom } from '@state/product'

import { callBackButtonStateAtom } from '@state/callBackButton'

import { companyStateAtom } from '@state/company'
import { aiStateAtom } from '@state/ai'

import { modalProductsStateAtom } from '@state/modalProducts'

import { bookmarksStateAtom } from '@state/bookmarks'
import { globalProgressBackdropStateAtom } from '@state/globalProgressBackdrop'

import {Mobile} from '@components/Products/RowsMobile'
import {Desktop} from '@components/Products/RowsDesktop'

import { useBotDetect } from '@hooks/useBotDetect'
import { useMobileDetect } from '@hooks/useMobileDetect'

import useMeasure from '@hooks/useMeasure'

import useThemeMode from '@hooks/useThemeMode'

import api from '@services/api'

declare var window: Window & typeof globalThis;

declare global {
  interface Window {
    SpeechRecognition: any;
  }
}


const MainLots: FC = () => {

  const { windowSize } = useMeasure()
  const themeMode = useThemeMode()

  const mobile = useMobileDetect()

  const [heightModal, setHeightModal] = useState<number>(380)

  const [product, setProduct] = useRecoilState<any>(productStateAtom)

  const [loading, setLoading] = useState<boolean>(false)

  const [morePhotos, setMorePhotos] = useState<boolean>(false)
  
  const [bookmarks, setBookmarks] = useRecoilState<any>(bookmarksStateAtom)


  
  const ai = useRecoilValue<any>(aiStateAtom)
  const company = useRecoilValue<any>(companyStateAtom)

  const setGlobalProgressBackdrop = useSetRecoilState<boolean>(globalProgressBackdropStateAtom)
  
  const setCallBackButton = useSetRecoilState<boolean>(callBackButtonStateAtom)
  const setModalProducts = useSetRecoilState<boolean>(modalProductsStateAtom)

  const [data, setData] = useState<any>(null)

  useEffect(() => {}, [bookmarks])
  
  const onClose = () => {

    setHeightModal(380)

    setLoading(false)
    setMorePhotos(false)
    setProduct(null)
    setCallBackButton(false)
  }

  useEffect(() => {

    setGlobalProgressBackdrop(true)

    setLoading(true)

    const query = qs.stringify({
      ai,
      filter: {
        company: {
          id: {
            $eq: company.id
          }
        }
      },
      randomSort: false,
      populate: {
        lots: {
          populate: {
            photo: `*`,
            country: `*`,
          },
        },
      },
      locale: ['ru'],
    }, {
      encodeValuesOnly: true,
    })

    api({ collection: 'companies-main-lots', query }).then((data) => {
      setGlobalProgressBackdrop(false)

      if(data){
        if(data.data?.[0]?.lots && data.data?.[0]?.lots?.length > 0) setData(data.data?.[0]?.lots);

      }

    }).catch((error:any) => {

      console.log(error)
    })


  }, [])

  const onClick = (product: any) => setProduct(product);

  const margin = 15

  const width =  windowSize.width >= 400 ? 380 : ( windowSize.width - ( margin*4 ) )

  return (
    <Container id={'catalog'} maxWidth="lg"
    sx={{
      ...(mobile && {
        pl: 0, pr: 0,
      }),
    }}>

      <Typography variant="h6" gutterBottom align="center"> Найдите ваш автомобиль </Typography>

      {/* <Box 
      display={'flex'} justifyContent={mobile ? 'flex-start' : 'center'} flexWrap={ mobile ? 'nowrap' : 'wrap'}
      sx={{
        mt: 2.5,
        overflowX: 'auto', scrollSnapType: 'x mandatory', '& > *': { scrollSnapAlign: 'center', }, 
        '& > *:first-of-type': {
          ...(mobile && {
            marginLeft: `${margin}px !important`,
          }),
        },
        touchAction: 'manipulation',
      }}>

        {data?.map((item:any, index:number) => (

          <Box key={index}>
              
            {mobile ? 
              
              <Mobile index={index} style={{ minWidth: width, width: width, marginLeft: margin*0.25, marginRight: margin*0.25, }} data={{data: item, filters: {}, onClick }} memoizedData={item} />
              
              :

              <Desktop index={index} style={{ minWidth: width, width: width, marginLeft: margin*0.25, marginRight: margin*0.25, }} data={{data: item, filters: {}, onClick }} memoizedData={item} />
              
            }
          </Box>

        ))}
    
      </Box> */}

      {/* { !mobile && */}
      <Container  maxWidth="lg">

      <Box display={'flex'} justifyContent={ 'center'} flexWrap={ mobile ? 'nowrap' : 'wrap'} sx={{ /* mt: 2,  */mb: 5, max: '100%', }}
      onClick={() => {
        /* setGlobalProgressBackdrop(true) */
        setModalProducts(true)
      }}>

        <Button fullWidth sx={{
          px: 5,
          backgroundColor: themeMode === 'dark' ? 'rgba(255, 255, 255, 0.35)': 'rgba(0, 0, 0, 0.35)', 
          ':hover': {
            backgroundColor: themeMode === 'dark' ? 'rgba(255, 255, 255, 0.25)': 'rgba(0, 0, 0, 0.25)'
          }
        }}>

          <Typography variant="h6" gutterBottom align="center" sx={{pt: 1,}}> 
          
            Каталог

          </Typography>

        </Button>

      </Box>
      </Container>

      {/* } */}
    </Container>
  )
}

export default MainLots

import { atom, selector } from "recoil"
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

export const chatBotStateAtom = atom({
  key: "chatBotStateAtom",
  default: false,
  effects_UNSTABLE: [persistAtom],

})

export const chatBotStateSelector = selector({
  key: "chatBotStateSelector",
  get: ({ get }) => {
    const chatBot = get(chatBotStateAtom)
    return chatBot
  }
})

